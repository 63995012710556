<u-group
  class="u-group u-group_app-emails-shuttle-company-details"
  [name]="config.dictionary.to | translate"
  [valid]="newEmail.valid && !emailItems.length"
  [message]="config.dictionary.required | translate"
>
  <div
    class="emails-shuttle-company-details__input"
    [ngClass]="{'emails-shuttle-company-details__input_invalid': !emailItems.length}"
  >
    <div class="emails-shuttle-company-details__list">
      <div
        *ngFor="let email of emailItems; let i = index"
        class="emails-shuttle-company-details__list-item"
      >
        <span class="emails-shuttle-company-details__list-item-name">{{email}}</span>
        <button
          class="emails-shuttle-company-details__list-item-remove-btn"
          (click)="removeEmail(i)"
        ></button>
      </div>
    </div>
    <input
      uInput
      class="u-input u-input_border-none u-input_app-emails-shuttle-company-details emails-shuttle-company-details__input-add-emails"
      type="text"
      [formControl]="newEmail"
      [placeholder]="config.dictionary.addEmail | translate"
      (blur)="selectItem()"
    >
  </div>
</u-group>
<u-group
  class="u-group u-group_app-emails-shuttle-company-details"
  [name]="config.dictionary.topic | translate"
  [valid]="emailContentForm.controls['subject'].valid"
  [message]="config.dictionary.required | translate"
>
  <div
    class="emails-shuttle-company-details__input"
    [ngClass]="{'emails-shuttle-company-details__input_invalid': !emailContentForm.controls['subject'].valid}"
  >
    <input
      uInput
      type="text"
      class="u-input u-input_border-none u-input_app-emails-shuttle-company-details"
      [formControl]="emailContentForm.controls['subject']"
      [valid]="emailContentForm.controls['subject'].valid"
      [placeholder]="config.dictionary.topic | translate"
    >
  </div>
</u-group>

<u-group
  class="u-group u-group_app-emails-shuttle-company-details"
  [name]="config.dictionary.attachedFiles | translate"
>
  <div class="emails-shuttle-company-details__input">
    <input
      uInput
      readonly
      type="text"
      class="u-input u-input_border-none u-input_app-emails-shuttle-company-details"
      [placeholder]="config.dictionary.attachedFiles | translate"
      [formControl]="emailContentForm.controls['attachment']"
    >
  </div>
</u-group>
<div class="emails-shuttle-company-details__content">
  <u-group
    class="u-group u-group_app-emails-shuttle-company-details"
    [name]="config.dictionary.content | translate"
    [valid]="emailContentForm.controls['content'].valid"
    [message]="config.dictionary.required | translate"
  >
    <u-ngx-editor
      [config]="configEditor"
      [placeholder]="config.dictionary.enterText"
      [spellcheck]="true"
      [formControl]="emailContentForm.controls['content']"
    ></u-ngx-editor>
  </u-group>
</div>
