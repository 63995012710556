import { DestroyRef, inject, Injectable } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import * as moment from 'moment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { USelectSItem } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import { TrackingService } from '@app/shared/services';
import { AccompanyCostType, AccompanyPriceType } from '@app/accompanies/models';
import { RoutesPeriodsDefaultData } from '@app/routes/models';
import { ShuttleCompanyCostCalculationMode } from '@app/shuttle-companies/models';
import {
  BulkChangeChangeType,
  BulkChangeCustomerDataType,
  BulkChangeDataServiceConfig,
  BulkChangeDates,
  BulkChangeDriversResponse,
  BulkChangeGeneral,
  BulkChangeParams,
  BulkChangeResponse,
  BulkChangeSubject,
  BulkChangeType
} from '@app/bulk-change/models';
import { bulkChangeConfig } from '@app/bulk-change/configs';
import { BulkChangeService } from './bulk-change.service';

@Injectable()
export class BulkChangeDataService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly formBuilder = inject(FormBuilder);
  private readonly trackingService = inject(TrackingService);
  private readonly bulkChangeService = inject(BulkChangeService);

  form: UntypedFormGroup = this.formBuilder.group({});

  config: BulkChangeDataServiceConfig = new BulkChangeDataServiceConfig();
  bulkChangeSelectItems: USelectSItem[] = [];
  routesPeriodsDefaultData: RoutesPeriodsDefaultData;
  preventDate: {
    preset: string;
    dates: string[];
  } = {
      preset: '',
      dates: []
    };
  disableDateUpdate: boolean = false;

  constructor() {
    this.setGeneral();
  }

  get generalForm(): UntypedFormGroup {
    return this.form.get('general') as UntypedFormGroup;
  }

  get generalDetailsForm(): UntypedFormGroup {
    return this.form.get('general.details') as UntypedFormGroup;
  }

  get datesChangesForm(): UntypedFormGroup {
    return this.generalForm.get('datesChange') as UntypedFormGroup;
  }

  get periodsForm(): UntypedFormControl {
    return this.generalForm.get('periods') as UntypedFormControl;
  }

  get dates(): UntypedFormControl {
    return this.datesChangesForm.get('dates') as UntypedFormControl;
  }

  get accompanyCostForm() {
    return this.generalForm.get('accompanyCost') as UntypedFormGroup;
  }

  private onDetailsTimeTypeChange(details: UntypedFormGroup) {
    details.get('timeType')
      .valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => details.get('time').patchValue(null));
  }

  private onDatesChange() {
    this.generalForm.get('datesChange')
      .valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: any) => {
        if (this.disableDateUpdate) {
          this.setDisableDateUpdate(false);

          return;
        } else {
          if (this.preventDate.preset !== value.type) {
            this.trackingService.track(`[${bulkChangeConfig.trackingId}] - set date preset`);
            this.preventDate.preset = value.type;
          }

          if (!isEqual(value.dates, this.preventDate.dates)) {
            this.trackingService.track(`[${bulkChangeConfig.trackingId}] - set date range`);
            this.preventDate.dates = value.dates;
          }
        }
      });
  }

  private onSubjectChange(generalBulkChangeForm: UntypedFormGroup) {
    generalBulkChangeForm.get('subject').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value: BulkChangeSubject) => {
        this.generalForm.removeControl('details');
        this.generalForm.removeControl('accompanyCost');
        this.generalForm.removeControl('ridePrice');
        this.generalForm.removeControl('executionPrice');

        if (value === BulkChangeSubject.Cancel || value === BulkChangeSubject.Restore) {
          const { apiBasePath, multiplePeriods } = value === BulkChangeSubject.Cancel ? bulkChangeConfig.cancel : bulkChangeConfig.restore;
          const type = this.generalForm.get('type');

          if (type.value === BulkChangeType.Planned) {
            type.patchValue(BulkChangeType.Unplanned);
            type.updateValueAndValidity();
          }

          this.setConfig(apiBasePath, null, null, multiplePeriods);
          type.disable({ emitEvent: false });

          if (this.periodsForm) {
            this.periodsForm.patchValue([]);
          } else {
            this.setPeriods();
            this.removeDatesChange();
          }
        } else {
          const type = this.generalForm.get('type');
          const changeTypeForm = this.generalForm.get('changeType');
          let changeType = changeTypeForm.value;

          changeTypeForm.setValidators(Validators.required);

          if (type.disabled) {
            type.enable({ emitEvent: false });
          }

          if (value === BulkChangeSubject.SendToSC) {
            changeTypeForm.clearValidators();

            this.generalForm.get('datesRange').setValidators(Validators.required);
            this.generalForm.get('datesRange').updateValueAndValidity();
          } else {
            this.generalForm.get('datesRange').clearValidators();
          }

          if (value === BulkChangeSubject.Hour) {
            changeType = BulkChangeChangeType.Hour;

            changeTypeForm.clearValidators();
          }

          if (value === BulkChangeSubject.AccompanyCost) {
            this.setAccompanyCost();

            changeTypeForm.clearValidators();

            this.setConfig(bulkChangeConfig.accompanyCost.apiBasePath);
          }

          if (value === BulkChangeSubject.RidePrice) {
            this.setRidePrice();

            changeTypeForm.clearValidators();

            this.setConfig(bulkChangeConfig.ridePrice.apiBasePath);
          }

          if (value === BulkChangeSubject.ExecutionPrice) {
            this.generalForm.setControl('executionPrice', this.formBuilder.control(null, Validators.required));

            changeTypeForm.clearValidators();

            this.setConfig(bulkChangeConfig.executionPrice.apiBasePath);
          }

          changeTypeForm.updateValueAndValidity();

          if (this.datesChangesForm) {
            this.datesChangesForm.patchValue(this.generateDates(new BulkChangeDates(this.routesPeriodsDefaultData)));
          } else {
            this.removePeriods();
            this.setDatesChange();
          }

          if (changeType) {
            this.updateChangesType(changeType, value);
          }
        }
      });
  }

  private onChangeTypeChange(generalBulkChangeForm: UntypedFormGroup) {
    generalBulkChangeForm.get('changeType').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value: BulkChangeChangeType) => {
        if (!value) { return; }

        const changeSubject = this.generalForm.get('subject').value;

        this.updateChangesType(value, changeSubject);
      });
  }

  private onChangeAccompanyCostType() {
    this.accompanyCostForm.get('costType').valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged()
      )
      .subscribe(value => {
        const hours = this.accompanyCostForm.get('hours');

        if (value === AccompanyCostType.ByManualHour) {
          hours.setValidators(Validators.required);
        } else {
          hours.reset();
          hours.clearValidators();
        }

        hours.updateValueAndValidity();
      });
  }

  private onChangeAccompanyPriceType() {
    this.accompanyCostForm.get('priceType').valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged()
      )
      .subscribe(value => {
        const hours = this.accompanyCostForm.get('hours');

        if (value === AccompanyPriceType.Manual) {
          hours.setValidators(Validators.required);
        } else {
          hours.reset();
          hours.clearValidators();
        }

        hours.updateValueAndValidity();
      });
  }

  private refreshBulkChangeSelectItems() {
    if (this.generalForm.get('changeType').value !== BulkChangeChangeType.Assign) {
      this.getBulkChangeSelectItems();
    }
  }

  private updateBulkChangeSelectItems(data) {
    this.bulkChangeSelectItems = data.map(({ id, name }) => ({
      value: id,
      name
    }));
  }

  private setBulkChangeSelectItems(res: BulkChangeResponse | BulkChangeDriversResponse) {
    if (res.success) {
      this.updateBulkChangeSelectItems(res.value);
    }
  }

  setGeneral() {
    this.form.setControl('general', this.generateGeneral(new BulkChangeGeneral()));
  }

  setAccompanyCost = () => {
    this.generalForm.setControl('accompanyCost', this.formBuilder.group({
      costType: AccompanyCostType.ByManualHour,
      priceType: AccompanyPriceType.Manual,
      costPerHour: [ null, Validators.required ],
      hours: [ null, Validators.required ]
    }));

    this.onChangeAccompanyPriceType();
    this.onChangeAccompanyCostType();
  };

  setRidePrice() {
    this.generalForm.setControl('ridePrice', this.formBuilder.group({
      costCalculationMode: [ ShuttleCompanyCostCalculationMode.Manual, Validators.required ],
      cost: [ null, Validators.required ]
    }));

    this.onRidePriceTypeChange();
  }

  onRidePriceTypeChange() {
    const ridePriceForm = this.generalForm.get('ridePrice') as UntypedFormGroup;

    ridePriceForm.get('costCalculationMode').valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (value === ShuttleCompanyCostCalculationMode.Manual) {
          ridePriceForm.setControl('cost', this.formBuilder.control(null, Validators.required));
          ridePriceForm.removeControl('overridePricelistToDefault');
        } else {
          ridePriceForm.removeControl('cost');
          ridePriceForm.setControl('overridePricelistToDefault', this.formBuilder.control(false, Validators.required));
        }
      });
  }

  setAccompanyId = () => {
    this.generalForm.setControl('details', this.formBuilder.group({
      accompanyId: [ null, Validators.required ]
    }));
  };

  setSwapAccompany = () => {
    this.generalForm.setControl('details', this.formBuilder.group({
      firstAccompanyId: [ null, Validators.required ],
      secondAccompanyId: [ null, Validators.required ]
    }));
  };

  setDriverId = () => {
    this.generalForm.setControl('details', this.formBuilder.group({
      driverId: [ null, Validators.required ]
    }));
  };

  setShuttleCompanyId = () => {
    this.generalForm.setControl('details', this.formBuilder.group({
      shuttleCompanyId: [ null, Validators.required ]
    }));
  };

  setCarId = () => {
    this.generalForm.setControl('details', this.formBuilder.group({
      carId: [ null, Validators.required ]
    }));
  };

  setSwapDriver = () => {
    this.generalForm.setControl('details', this.formBuilder.group({
      firstDriverId: [ null, Validators.required ],
      secondDriverId: [ null, Validators.required ]
    }));
  };

  setConfig(apiBasePath: string, requestType?: string, fieldName?: string, multiplePeriods?: boolean) {
    this.config = {
      ...this.config,
      ...new BulkChangeDataServiceConfig({ fieldName, apiBasePath, requestType, multiplePeriods })
    };
  }

  setHours = () => {
    const details = this.formBuilder.group({
      timeType: [ 1, Validators.required ],
      time: [ null, Validators.required ]
    });

    this.generalForm.setControl('details', details);

    this.onDetailsTimeTypeChange(details);
  };

  setRoutes(data: any[]) {
    this.generalForm.addControl('routeIds', new UntypedFormControl(data));
  }

  setDatesChange(dates: BulkChangeDates = new BulkChangeDates(this.routesPeriodsDefaultData)) {
    this.generalForm.setControl('datesChange', this.generateDates(dates));

    this.onDatesChange();
  }

  removeDatesChange() {
    this.generalForm.removeControl('datesChange');
  }

  setChanges(changeType: BulkChangeChangeType, params: BulkChangeParams) {
    const { dictionary, apiBasePath } = params.config;

    switch (changeType) {
      case BulkChangeChangeType.Swap: {
        this.getBulkChangeSelectItems();

        params.setSwapChange();

        this.setConfig(apiBasePath, bulkChangeConfig.changesApiBasePaths.swap, dictionary.fieldName);

        break;
      }

      case BulkChangeChangeType.Remove: {
        this.getBulkChangeSelectItems();

        params.setSubjectId();

        this.setConfig(apiBasePath, bulkChangeConfig.changesApiBasePaths.remove, dictionary.fieldName);

        break;
      }

      case BulkChangeChangeType.Assign: {
        this.getBulkChangeSelectItems();

        params.setSubjectId();

        this.setConfig(apiBasePath, bulkChangeConfig.changesApiBasePaths.assign, dictionary.fieldName);

        break;
      }

      case BulkChangeChangeType.Hour: {
        this.bulkChangeSelectItems = bulkChangeConfig.hour.hourTypes;

        params.setHours();

        this.setConfig(apiBasePath, '', dictionary.fieldName);

        break;
      }
    }
  }

  generateGeneral(data: BulkChangeGeneral): UntypedFormGroup {
    const generalBulkChangeForm = this.formBuilder.group({
      type: [ data.type ],
      subject: [ data.subject, Validators.required ],
      changeType: [ data.changeType ],
      comment: [ data.comment ],
      datesRange: [ data.datesRange ]
    });

    this.onSubjectChange(generalBulkChangeForm);
    this.onChangeTypeChange(generalBulkChangeForm);

    return generalBulkChangeForm;
  }

  generateDates(data: BulkChangeDates): UntypedFormGroup {
    return this.formBuilder.group({
      dates: [ data.dates ],
      dateFrom: [ data.dateFrom ],
      dateTo: [ data.dateTo ],
      type: [ data.type ],
      enabledTypes: [ data.availablePresets ],
      checkDaysActive: [ data.checkDaysActive ],
      checkDaysAvailable: [ data.checkDaysAvailable ]
    });
  }

  updateChangesType(value: BulkChangeChangeType, subject: BulkChangeSubject) {
    const paramsBySubject: { [key: string]: BulkChangeParams; } = {
      [BulkChangeSubject.Accompany]: {
        subjectType: BulkChangeSubject.Accompany,
        config: bulkChangeConfig.accompany,
        setSwapChange: this.setSwapAccompany,
        setSubjectId: this.setAccompanyId
      },
      [BulkChangeSubject.Vehicle]: {
        config: bulkChangeConfig.vehicle,
        subjectType: BulkChangeSubject.Vehicle,
        setSubjectId: this.setCarId
      },
      [BulkChangeSubject.Driver]: {
        config: bulkChangeConfig.driver,
        subjectType: BulkChangeSubject.Driver,
        setSwapChange: this.setSwapDriver,
        setSubjectId: this.setDriverId
      },
      [BulkChangeSubject.ShuttleCompany]: {
        config: bulkChangeConfig.shuttleCompany,
        subjectType: BulkChangeSubject.ShuttleCompany,
        setSubjectId: this.setShuttleCompanyId
      },
      [BulkChangeSubject.Hour]: {
        config: bulkChangeConfig.hour,
        subjectType: BulkChangeSubject.Hour,
        setHours: this.setHours
      }
    };

    if (paramsBySubject[subject]) {
      this.setChanges(value, paramsBySubject[subject]);
    }
  }

  updatePeriod(data: any) {
    this.datesChangesForm.patchValue({
      dates: data.dates,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });

    this.refreshBulkChangeSelectItems();
  }

  updateDates({ dates, checkDaysActive }) {
    const datesStore: string[] = this.datesChangesForm.get('dates').value;

    if (dates && dates.length && !isEqual(dates, datesStore)) {
      this.datesChangesForm.patchValue({
        dates: dates.map((item: string) => moment(item).startOf('day').format(AppConstants.DATE_FORMAT_ISO)),
        checkDaysActive
      });

      this.refreshBulkChangeSelectItems();
    }
  }

  getBulkChangeSelectItems() {
    let params = null;

    if (this.generalForm.get('changeType').value !== BulkChangeChangeType.Assign) {
      const { dates, checkDaysActive } = this.datesChangesForm.value;

      params = {
        from: moment(dates[0]).format(AppConstants.DATE_FORMAT_BASE_LINE),
        to: moment(dates[dates.length - 1]).format(AppConstants.DATE_FORMAT_BASE_LINE)
      };

      if ([ BulkChangeSubject.ShuttleCompany, BulkChangeSubject.Vehicle ].includes(this.generalForm.get('subject').value)) {
        params = {
          routeIds: this.generalForm.get('routeIds').value,
          dateFrom: dates[0],
          dateTo: dates[dates.length - 1],
          days: checkDaysActive
        };
      }
    }

    switch (this.generalForm.get('subject').value) {
      case BulkChangeSubject.Accompany: {
        if (params) {
          this.bulkChangeService.getAccompaniesInPeriod(params)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(res => this.setBulkChangeSelectItems(res));
        } else {
          this.bulkChangeService.getCustomerData([ BulkChangeCustomerDataType.Accompanies ])
            .pipe(
              map(data => data.accompanies),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(data => this.updateBulkChangeSelectItems(data));
        }

        break;
      }

      case BulkChangeSubject.Driver: {
        if (params) {
          this.bulkChangeService.getDriversInPeriod(params)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(res => this.setBulkChangeSelectItems(res));
        } else {
          this.bulkChangeService.getCustomerData([ BulkChangeCustomerDataType.Drivers ])
            .pipe(
              map(data => data.drivers),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(data => this.updateBulkChangeSelectItems(data));
        }

        break;
      }

      case BulkChangeSubject.ShuttleCompany: {
        if (params) {
          this.bulkChangeService.getShuttleCompaniesForRides(params)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(data => this.updateBulkChangeSelectItems(data));
        } else {
          this.bulkChangeService.getCustomerData([ BulkChangeCustomerDataType.ShuttleCompanies ])
            .pipe(
              map(data => data.shuttleCompanies),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(data => this.updateBulkChangeSelectItems(data));
        }

        break;
      }

      case BulkChangeSubject.Vehicle: {
        if (params) {
          this.bulkChangeService.getVehiclesForRides(params)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(data => this.updateBulkChangeSelectItems(data));
        } else {
          this.bulkChangeService.getCustomerData([ BulkChangeCustomerDataType.Vehicles ])
            .pipe(
              map(data => data.vehicles),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(data => this.updateBulkChangeSelectItems(data));
        }

        break;
      }
    }
  }

  setPeriods() {
    this.generalForm.addControl('periods', this.formBuilder.control([]));
  }

  removePeriods() {
    this.generalForm.removeControl('periods');
  }

  setRoutesPeriodsDefaultData(data: RoutesPeriodsDefaultData) {
    this.routesPeriodsDefaultData = data;
  }

  setDisableDateUpdate(data: boolean) {
    this.disableDateUpdate = data;
  }
}
