import { Injectable, inject } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { BuilderRoutesPassengersInfoState } from '@app/builder/models';
import { RoutePlannerService } from '@app/route-planner/services';
import { RoutePlannerPassenger } from '@app/route-planner/models';

@Injectable()
export class BuilderRoutesPassengersInfoStore extends ComponentStore<BuilderRoutesPassengersInfoState> {
  private readonly routePlannerService = inject(RoutePlannerService);

  private cancelGetInfoRequest = new Subject<void>();

  constructor() {
    super({ info: {} });
  }

  readonly info = (routeId: number) => this.selectSignal(({ info }) => info[routeId]);

  readonly getInfo = this.effect((params$: Observable<{
    routeId: number;
  }>) => params$.pipe(
    filter(({ routeId }) => this.info(routeId)() === undefined),
    switchMap(({ routeId }) =>
      this.routePlannerService.getPassengers(routeId)
        .pipe(
          takeUntil(this.cancelGetInfoRequest),
          tapResponse(
            info => this.updateInfo({ routeId, info }),
            () => null
          )
        )
    )
  ));

  readonly updateInfo = this.updater((state, data: { routeId: number; info: RoutePlannerPassenger[]; }) => ({
    info: {
      ...state.info,
      [data.routeId]: data.info || null
    }
  }));

  readonly clearInfo = this.updater((state, routeId: number) => ({
    info: {
      ...state.info,
      [routeId]: undefined
    }
  }));

  readonly reset = this.updater(() => ({ info: {} }));

  readonly cancelGetInfo = () => {
    this.cancelGetInfoRequest.next();
  };
}
