import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import { LocalizationService, AddEditModalService } from '@app/shared/services';
import { AddEditModalField, AddEditModalInputType } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { dynamicFieldComponentConfig } from './dynamic-field.component.config';

@Component({
  selector: 'app-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: [ './dynamic-field.component.scss', 'dynamic-field.component.rtl.scss' ]
})
export class DynamicFieldComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data;
  @Input() field: AddEditModalField;
  @Input() form: UntypedFormControl | UntypedFormArray | UntypedFormGroup;
  @Input() addEditModalService: AddEditModalService;

  @ViewChild('dynamicComponentContainer', { static: false, read: ViewContainerRef }) dynamicComponentContainer: ViewContainerRef;

  private cmpRef: any;
  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(dynamicFieldComponentConfig);
  isRtl: boolean;
  inputType = AddEditModalInputType;
  disableDaysAfterToday: string = moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO);

  constructor(
    private cdRef: ChangeDetectorRef,
    private localizationService: LocalizationService,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.isRtl = this.localizationService.isRtl();
  }

  ngAfterViewInit(): void {
    if (this.field.loadComponent) {
      this.loadComponent();
    }
  }

  ngOnDestroy(): void {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  loadComponent(): void {
    const resolvedComponent = this.resolver.resolveComponentFactory(this.field.loadComponent);
    this.cmpRef = this.dynamicComponentContainer.createComponent(resolvedComponent);
    this.cmpRef.instance.form = this.form;
    this.cmpRef.instance.field = this.field;
    this.cmpRef.instance.addEditModalService = this.addEditModalService;

    this.cdRef.detectChanges();

    if (this.field.subscribeForChanges) {
      this.cmpRef.instance.changeEvent
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(event => this.addEditModalService.fieldChange.next(event));
    }

    if (this.field.subscribeForEventData) {
      this.cmpRef.instance.eventData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(event => this.addEditModalService.eventData.next(event));
    }
  }

  onInputBlur(): void {
    if (
      this.field.subscribeForBlur ||
      (this.field.subscribeForValidAndDirtyBlur && this.form.dirty && this.form.valid)
    ) {
      this.addEditModalService.blurEvent.next({
        value: this.form.value,
        type: this.field.inputType,
        fieldName: this.field.dataField.name
      });
    }
  }
}
