import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { URangePreset } from '@shift/ulib';

import { ApiBaseResponse, DaysOfWeek } from '@app/shared/models';
import { bulkChangeConfig } from '@app/bulk-change/configs';

export enum BulkChangeType {
  Planned = 1,
  Unplanned = 2
}

export enum BulkChangeAction {
  Close = 'close',
  CloseStart = 'close-start',
  FinishChange = 'finish-change'
}

export enum BulkChangeSubject {
  Accompany = 'Accompany',
  AccompanyCost = 'AccompanyCost',
  Driver = 'Driver',
  Hour = 'Hour',
  Cancel = 'Cancel',
  Restore = 'Restore',
  SendToSC = 'SendToSC',
  ShuttleCompany = 'shuttleCompany',
  Vehicle = 'vehicle'
}

export enum BulkChangeChangeType {
  Assign = 1,
  Remove = 2,
  Swap = 3,
  Hour = 4
}

export enum BulkChangeCustomerDataType {
  Accompanies = 'accompanies',
  Drivers = 'drivers',
  Vehicles = 'vehicles',
  ShuttleCompanies = 'shuttleCompanies'
}

export class BulkChangeGeneral {
  type: number = BulkChangeType.Unplanned;
  subject: number = null;
  changeType: number = null;
  comment: string = '';
  datesRange?: string[];
}

export class BulkChangeDates {
  dates: string[] = [ new Date(moment().format(bulkChangeConfig.dateFormat)).toISOString() ];
  dateFrom: string = null;
  dateTo: string = null;
  type: string = 'today';
  availablePresets: URangePreset[] = bulkChangeConfig.availablePresetsInit;
  checkDaysActive: number[] = [ moment( new Date(moment().format(bulkChangeConfig.dateFormat)).toISOString()).get('day') ];
  checkDaysAvailable: number[] = bulkChangeConfig.checkDaysAvailable;
  headerDate?: string = '';

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class BulkChangeDataServiceConfig {
  apiBasePath: string = '';
  fieldName: string = '';
  requestType: string = '';
  multiplePeriods: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface BulkChangeParams {
  subjectType: BulkChangeSubject;
  config: {
    dictionary: {
      fieldName?: string;
    };
    apiBasePath: string;
  };
  setSwapChange?: Function;
  setSubjectId?: Function;
  setHours?: Function;
}

export interface BulkChangeDetails {
  key: string;
  value: UntypedFormControl;
}

export interface BulkChangeRoute {
  routeId: number;
  routeStartDate: string;
  routeEndDate: string;
}

export interface BulkChangeResponseValue {
  id: number;
  name: string;
}

export interface BulkChangeDriversResponseValue extends BulkChangeResponseValue {
  mobile: string;
}

export interface BulkChangeResponse extends ApiBaseResponse {
  value: BulkChangeResponseValue[];
}

export interface BulkChangeDriversResponse extends ApiBaseResponse {
  value: BulkChangeDriversResponseValue[];
}

export interface BulkChangeCustomerDataItem {
  id: number;
  name: string;
}

export interface BulkChangeCustomerDataItemDriver extends BulkChangeCustomerDataItem {
  mobile: string;
}

export interface BulkChangeCustomerDataItemVehicle extends BulkChangeCustomerDataItem {
  carTypeName: string;
}

export interface BulkChangeCustomerData {
  [BulkChangeCustomerDataType.Drivers]?: BulkChangeCustomerDataItemDriver[];
  [BulkChangeCustomerDataType.Accompanies]?: BulkChangeCustomerDataItem[];
  [BulkChangeCustomerDataType.ShuttleCompanies]?: BulkChangeCustomerDataItem[];
  [BulkChangeCustomerDataType.Vehicles]?: BulkChangeCustomerDataItemVehicle[];
}

export interface BulkChangeGetDataForRidesBody {
  routeIds: number[];
  dateFrom: string;
  dateTo: string;
  days: DaysOfWeek[];
}
