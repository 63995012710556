import { HeaderMenuIconsTemplate, GridHeaderTemplate } from '@app/shared/models';
import { AuthCustomerType } from '@app/auth/models';
import { FeedFilterPage, FeedStatus } from '@app/feed/models';
import { RoutesDailyFilterType, RoutesWeeklyFilterType, RoutesViewType } from '@app/routes/models';

const dailyFilters: { value: RoutesDailyFilterType; name: string; }[] = [
  { value: RoutesDailyFilterType.DailyRoutes, name: 'routes.daily.tableFilters.dailyRoutes' },
  { value: RoutesDailyFilterType.ShowAllRoutes, name: 'routes.daily.tableFilters.showAllRoutes' },
  { value: RoutesDailyFilterType.RoutesWithChanges, name: 'routes.daily.tableFilters.routesWithChanges' },
  { value: RoutesDailyFilterType.OpenRoutes, name: 'routes.daily.tableFilters.openRoutes' },
  { value: RoutesDailyFilterType.RoutesWithAlerts, name: 'routes.daily.tableFilters.routesWithAlerts' }
];

const weeklyFilters: { value: RoutesWeeklyFilterType; name: string; }[] = [
  { value: RoutesWeeklyFilterType.Driver, name: 'routes.viewTypes.driver' }
];

const viewTypeBySelectedFilterPage = {
  [FeedFilterPage.RoutesDaily]: RoutesViewType.Daily,
  [FeedFilterPage.RoutesWeekly]: RoutesViewType.Weekly
};

const routesComponent = {
  dailyFilters,
  weeklyFilters,
  viewTypeBySelectedFilterPage
};

export const routesComponentConfig = {
  default: {
    ...routesComponent,
    daily: {
      gridHeaderTemplate: {
        default: GridHeaderTemplate.RoutesDaily,
        [FeedStatus.RidesToReOptimize]: GridHeaderTemplate.RoutesDailyReOptimization,
        [FeedStatus.RidesWithEmptyStations]: GridHeaderTemplate.RoutesDailyReOptimizationEmptyStations
      },
      headerMenuIconsTemplate: HeaderMenuIconsTemplate.RoutesDaily
    },
    weekly: {
      gridHeaderTemplate: GridHeaderTemplate.RoutesWeekly,
      headerMenuIconsTemplate: HeaderMenuIconsTemplate.RoutesWeekly
    }
  },
  [AuthCustomerType.ShuttleCompany]: {
    ...routesComponent,
    daily: {
      gridHeaderTemplate: {
        default: GridHeaderTemplate.RoutesDailySC,
        [FeedStatus.RidesToReOptimize]: GridHeaderTemplate.RoutesDailyReOptimization,
        [FeedStatus.RidesWithEmptyStations]: GridHeaderTemplate.RoutesDailyReOptimizationEmptyStations
      },
      headerMenuIconsTemplate: HeaderMenuIconsTemplate.RoutesSC
    },
    weekly: {
      gridHeaderTemplate: GridHeaderTemplate.RoutesWeeklySC,
      headerMenuIconsTemplate: HeaderMenuIconsTemplate.RoutesSC
    }
  },
  [AuthCustomerType.Municipality]: {
    ...routesComponent,
    dailyFilters: [
      ...dailyFilters,
      { value: RoutesDailyFilterType.RegularEducationDepartmentRoutes, name: 'routes.daily.tableFilters.regularEducationDepartmentRoutes' },
      { value: RoutesDailyFilterType.SpecialEducationDepartmentRoutes, name: 'routes.daily.tableFilters.specialEducationDepartmentRoutes' }
    ],
    weeklyFilters: [
      { value: RoutesWeeklyFilterType.AccompanyName, name: 'routes.viewTypes.accompanies' },
      ...weeklyFilters
    ],
    daily: {
      gridHeaderTemplate: {
        default: GridHeaderTemplate.RoutesDaily,
        [FeedStatus.RidesToReOptimize]: GridHeaderTemplate.RoutesDailyReOptimization,
        [FeedStatus.RidesWithEmptyStations]: GridHeaderTemplate.RoutesDailyReOptimizationEmptyStations
      },
      headerMenuIconsTemplate: HeaderMenuIconsTemplate.RoutesDaily
    },
    weekly: {
      gridHeaderTemplate: GridHeaderTemplate.RoutesWeekly,
      headerMenuIconsTemplate: HeaderMenuIconsTemplate.RoutesWeekly
    }
  }
};
