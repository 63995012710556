import { Component, OnInit, Input, ViewChild, ViewContainerRef, HostBinding } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { NgClass, NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { UCommonModule, UNgxEditorModule } from '@shift/ulib';

import { LocalizationService, ValidationService } from '@app/shared/services';
import { emailsShuttleCompanyDetailsComponentConfig } from './emails-shuttle-company-details.component.config';

@Component({
  selector: 'app-emails-shuttle-company-details',
  templateUrl: './emails-shuttle-company-details.component.html',
  styleUrls: [ './emails-shuttle-company-details.component.scss', './emails-shuttle-company-details.component.rtl.scss' ],
  standalone: true,
  imports: [
    UCommonModule,
    UNgxEditorModule,
    TranslateModule,
    NgClass,
    ReactiveFormsModule,
    NgForOf
  ]
})
export class EmailsShuttleCompanyDetailsComponent implements OnInit {
  @Input() emailContentForm: any;
  @Input() emails: any;
  @Input() customerSuppliers: any;

  @ViewChild('contentTemplate', { static: false, read: ViewContainerRef }) contentTemplate: ViewContainerRef;

  @HostBinding('class') hostClasses: string = 'emails-shuttle-company-details';

  config = cloneDeep(emailsShuttleCompanyDetailsComponentConfig);
  isRtl: boolean;
  lang: any;
  messages: any;
  configEditor: any;
  newEmail: UntypedFormControl;
  emailItems: any[] = [];

  constructor(
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.configEditor = {
      editable: true,
      spellcheck: true,
      height: '247px',
      minHeight: '247px',
      borderRadius: '9px',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: false,
      showToolbar: false,
      placeholder: 'Enter text here...',
      imageEndPoint: ''
    };

    this.isRtl = this.localizationService.isRtl();
    this.lang = this.localizationService.getLanguage();
    this.initInputEmails();
  }

  get receiversForm(): UntypedFormControl {
    return this.emailContentForm.get('receivers') as UntypedFormControl;
  }

  initInputEmails() {
    this.newEmail = new UntypedFormControl('');

    this.newEmail.setValidators(
      ValidationService.email.bind(this)
    );

    this.emailItems.push( ...this.receiversForm.value);
  }

  selectItem() {
    if (!this.newEmail.value || !this.newEmail.valid) { return; }

    const emailExist = this.emailItems.some((el: any) => el.includes(this.newEmail.value));

    if (emailExist) {
      this.newEmail.patchValue('');

      return;
    } else {
      this.emailItems.push(this.newEmail.value);
      this.newEmail.patchValue('');

      this.receiversForm.patchValue([ ...this.emailItems ], { emitEvent: false });
    }
  }

  removeEmail(index: number) {
    this.emailItems.splice(index, 1);
    this.receiversForm.patchValue([ ...this.emailItems ], { emitEvent: false });
  }
}


