import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '@app/shared/services';
import { FileSaverService } from '@app/files/services';
import { RouteSplitPassenger, RouteSplitPassengers } from '@app/route-split/models';
import {
  RoutePlannerNewBody,
  RoutePlannerMovePassengersBody,
  RoutePlannerMovePassengersGetParams,
  RoutePlannerMovePassengersBase,
  RoutePlannerMovePassengersActionBase,
  RoutePlannerGetRoutesMapViewBody,
  RoutePlannerGetRoutesMapView,
  RoutePlannerPassenger,
  RoutePlanner,
  RoutePlannerStartBody,
  RoutePlannerSummary,
  RoutePlannerSummaryRoute,
  RoutePlannerDuplicateBody,
  RoutePlannerLoadMapBody,
  RoutePlannerLoadMap,
  RoutePlannerRoutesGeneration
} from '@app/route-planner/models';

@Injectable({
  providedIn: 'root'
})
export class RoutePlannerService {
  private readonly apiService = inject(ApiService);
  private readonly fileSaverService = inject(FileSaverService);

  private readonly BASE_PATH: string = 'RoutePlanner';

  getCustomers(): Observable<{ id: number; name: string; }[]> {
    return this.apiService.get(`${this.BASE_PATH}/Customers`);
  }

  get(customerId?: number): Observable<RoutePlanner> {
    return this.apiService.get(this.BASE_PATH, customerId && { customerId });
  }

  save(): Observable<void> {
    return this.apiService.post(this.BASE_PATH);
  }

  start(body: RoutePlannerStartBody): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Start`, body);
  }

  getSummary(): Observable<RoutePlannerSummary> {
    return this.apiService.get(`${this.BASE_PATH}/Summary`);
  }

  new(body: RoutePlannerNewBody): Observable<RoutePlannerSummaryRoute> {
    return this.apiService.post(`${this.BASE_PATH}/New`, body);
  }

  deleteRoute(routeId: number): Observable<void> {
    return this.apiService.delete(`${this.BASE_PATH}/Route`, { routeId });
  }

  loadMap(body: RoutePlannerLoadMapBody): Observable<RoutePlannerLoadMap> {
    return this.apiService.post(`${this.BASE_PATH}/LoadMap`, body);
  }

  duplicate(body: RoutePlannerDuplicateBody): Observable<RoutePlannerSummary> {
    return this.apiService.post(`${this.BASE_PATH}/Duplicate`, body);
  }

  getMovePassengers(params: RoutePlannerMovePassengersGetParams): Observable<RoutePlannerMovePassengersBase> {
    return this.apiService.get(`${this.BASE_PATH}/MovePassengers`, params);
  }

  movePassengers(body: RoutePlannerMovePassengersBody, skipErrorCodesMessage?: (number | string)[]): Observable<RoutePlannerMovePassengersActionBase> {
    return this.apiService.put(`${this.BASE_PATH}/MovePassengers`, body, false, skipErrorCodesMessage);
  }

  algorithmSession(): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/AlgorithmSession`);
  }

  getRoutesMapView(body: RoutePlannerGetRoutesMapViewBody): Observable<RoutePlannerGetRoutesMapView[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetRoutesMapView`, body);
  }

  downloadSkippedPassengersExcel(fileName: string): Observable<void> {
    return this.apiService.getBlob(`${this.BASE_PATH}/SkippedPassengers/Excel`, 'blob')
      .pipe(tap(file => this.fileSaverService.downloadBlobFile(file, fileName)));
  }

  getPassengers(routeId: number): Observable<RoutePlannerPassenger[]> {
    return this.apiService.get(`${this.BASE_PATH}/Passengers`, { routeId });
  }

  getSplitPassengers(routeId: number): Observable<RouteSplitPassenger[]> {
    return this.apiService.get(`${this.BASE_PATH}/SplitPassengers`, { routeId });
  }

  split(params: RouteSplitPassengers): Observable<RoutePlannerSummaryRoute[]> {
    return this.apiService.post(`${this.BASE_PATH}/Split`, params);
  }

  updateRoutesGeneration(generation: RoutePlannerRoutesGeneration): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/RoutesGeneration?generation=${generation}`);
  }
}
