import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { exhaustMap, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UInputCitiesCity } from '@shift/ulib';

import { CitiesService } from '@app/shared/services';
import { transformToInputCities } from '@app/shared/utils';
import { BuilderFilterState } from '@app/builder/models';

@Injectable()
export class BuilderFilterStore extends ComponentStore<BuilderFilterState> {
  constructor(
    private readonly citiesService: CitiesService
  ) {
    super({ cities: null });
  }

  readonly cities = this.selectSignal(state => state.cities);
  readonly citiesBySelectedBranchIds = (branchIds: number[]) => this.selectSignal(({ cities }) => (cities || [])
    .reduce((acc, city) => {
      const branches = branchIds.length ? city.branches.filter(branch => branchIds.includes(branch.branchId)) : city.branches;

      if (branches?.length) {
        return [ ...acc, { ...city, branches } ];
      }

      return acc;
    }, []));

  readonly getCities = this.effect((params$: Observable<void>) => params$.pipe(
    filter(() => !this.cities()),
    exhaustMap(() =>
      this.citiesService.getFilter()
        .pipe(
          tapResponse(
            data => this.updateCities(transformToInputCities(data)),
            () => null
          )
        )
    )
  ));

  readonly updateCities = this.updater((state, cities: UInputCitiesCity[]) => ({
    cities
  }));

  readonly reset = this.updater(() => ({ cities: null }));
}
