import { DestroyRef, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { HeaderSearchFiltersService } from '@app/shared/services';
import { NavigationPaths } from '@app/shared/models';
import { AuthDataService } from '@app/auth/services';
import { AuthModuleName } from '@app/auth/models';
import { DemandsState, DemandsView } from '@app/demands/models';
import { DemandsStorageService } from '@app/demands/services';

@Injectable({
  providedIn: 'root'
})
export class DemandsStore extends ComponentStore<DemandsState> {
  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly authDataService: AuthDataService,
    private readonly headerSearchFiltersService: HeaderSearchFiltersService,
    private readonly demandsStorageService: DemandsStorageService
  ) {
    super({
      showViewToggle: false,
      view: null
    });

    this.initView();
    this.resetGlobalFiltersOnViewChange();
  }

  showViewToggle = this.selectSignal(({ showViewToggle }) => showViewToggle);
  view = this.selectSignal(({ view }) => view);
  view$ = this.select(({ view }) => view);

  private initView() {
    const availableViews = Object
      .entries({
        [DemandsView.Passengers]: {
          module: AuthModuleName.DemandsPassengersView
        },
        [DemandsView.Shifts]: {
          module: AuthModuleName.DemandsShiftsView
        }
      })
      .filter(([ key, value ]) => this.authDataService.checkFeature(value))
      .map(([ key ]) => key);

    let view = this.demandsStorageService.getView() || DemandsView.Passengers;

    if (!availableViews.includes(view)) {
      view = <DemandsView>availableViews?.[0];
    }

    this.updateView(view);
  }

  private resetGlobalFiltersOnViewChange() {
    this.view$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.router.url.replace('/', '') === NavigationPaths.Demands) {
          this.headerSearchFiltersService.reset();
        } else {
          this.headerSearchFiltersService.resetSearchConfigByUrl(NavigationPaths.Demands);
        }
      });
  }

  updateShowViewToggle(showViewToggle: boolean) {
    this.patchState({ showViewToggle });
  }

  updateView(view: DemandsView) {
    this.patchState({ view });

    this.demandsStorageService.updateView(view);
  }
}
