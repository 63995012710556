<ng-template #warningTime>
  <div class="builder-tuning__warning-wrapper">
    <div class="builder-tuning__warning-info">
      <div class="builder-tuning__warning-info-item builder-tuning__warning-info-item_bold">{{config.dictionary.warning.original | translate}}</div>
      <div class="builder-tuning__warning-info-item" *ngIf="activeRide.plannedStartDateTime">{{config.dictionary.warning.startTime | translate}}: {{activeRide.plannedStartDateTime}}</div>
      <div class="builder-tuning__warning-info-item" *ngIf="activeRide.plannedEndDateTime">{{config.dictionary.warning.endTime | translate}}: {{activeRide.plannedEndDateTime}}</div>
    </div>
  </div>
</ng-template>

<ng-template #carCapacity>
  <div class="builder-tuning__car-capacity-container">
    <span class="builder-tuning__car-capacity-title">{{config.dictionary.maxCapacity | translate}}</span>
    <span class="builder-tuning__car-capacity-description">{{config.dictionary.maxCapacityDesc | translate}}</span>
  </div>
</ng-template>

<ng-container *ngIf="mode === builderTuningMode.Default">
  <div
    class="builder-tuning__item"
    *ngIf="
      routeBuilderFeatureTypeShuttleCompany() &&
      (
        builderDataConfig.buildMode === builderBuildMode.Routes ||
        builderDataConfig.buildMode === builderBuildMode.RouteTemplates ||
        builderDataConfig.buildMode === builderBuildMode.RouteSuggestions
      )
    "
  >
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name">{{config.dictionary.customer | translate}}</div>
      <div class="builder-tuning__group-field">
        <u-select-s
          *ngIf="addEditForm.get('details.customerId') as formControl"
          [uId]="'builder-tuning-customer'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [placeholder]="builderDataService.customer()?.name || (config.dictionary.select | translate)"
          [items]="options.subCustomersSC"
          [formControl]="formControl"
          [empty]="false"
          [messages]="config.dictionary.uSelectS | translate"
          [valid]="formControl.disabled || formControl.valid"
          (click)="formControlClick.emit()"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div
    class="builder-tuning__item"
    *ngIf="
      (builderDataConfig.mode === builderMode.Add && builderDataConfig.buildMode === builderBuildMode.Routes) ||
      builderDataConfig.buildMode === builderBuildMode.RouteTemplates || builderDataConfig.buildMode === builderBuildMode.RouteSuggestions
    "
  >
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name">{{config.dictionary.direction | translate}}</div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [uId]="'builder-tuning-direction'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [placeholder]="config.dictionary.search | translate"
          [items]="options.directions"
          [formControl]="addEditForm.get('details.direction')"
          [empty]="false"
          [messages]="config.dictionary.uSelectS | translate"
          (click)="directionClick(); formControlClick.emit()"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div
    class="builder-tuning__item"
    *ngIf="builderDataConfig.mode === builderMode.Add && (builderDataConfig.buildMode === builderBuildMode.Routes || builderDataConfig.buildMode === builderBuildMode.RouteSuggestions)"
  >
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name">{{config.dictionary.date | translate}}</div>
      <div class="builder-tuning__group-field">
        <u-input-date-range
          [class]="'u-input-date-range_blue'"
          [container]="'body'"
          [placement]="isRtl ? [ 'bottom-right' ] : [ 'bottom-left' ]"
          [(ngModel)]="builderDataService.datesRange.dates"
          [sundayFirstDay]="true"
          [defaultDate]="true"
          [checkDays]="true"
          [showIcon]="false"
          [checkDaysActive]="builderDataService.datesRange.checkDaysActive"
          [checkDaysAvailable]="builderDataService.datesRange.checkDaysAvailable"
          [messages]="config.dictionary.uInputDateRange | translate"
          [idPrefix]="'builder-tuning'"
          [disabled]="!builderDataService.isDetailsCustomerIdValid || !builderDataService.createOrEditRoutesAllowed()"
          (saveAction)="builderDataService.datesRangeChange($event, true)"
          (click)="periodClick(); formControlClick.emit()"
        ></u-input-date-range>
      </div>
    </div>
  </div>
  <div
    class="builder-tuning__item"
    *ngIf="builderDataConfig.buildMode === builderBuildMode.RouteTemplates"
  >
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name">{{config.dictionary.day | translate}}</div>
      <div class="builder-tuning__group-field">
        <u-select-days
          class="u-select-days_app-builder"
          [container]="'body'"
          [messages]="config.dictionary.uSelectDays | translate"
          [(ngModel)]="builderDataService.datesDays"
          [sundayFirstDay]="true"
          [rtl]="isRtl"
          [lang]="lang"
          [availableDays]="builderDataService.datesDaysAvailable"
          [showInfo]="false"
          [showAllBtn]="false"
          [minDays]="builderDataService.minDays"
          [disabled]="!builderDataService.isDetailsCustomerIdValid || !builderDataService.createOrEditRoutesAllowed()"
          (ngModelChange)="builderDataService.datesDaysChange()"
          (click)="formControlClick.emit()"
        ></u-select-days>
      </div>
    </div>
  </div>
  <div
    class="builder-tuning__item"
    *ngIf="builderDataConfig.mode === builderMode.Edit && (builderDataConfig.buildMode === builderBuildMode.Routes || builderDataConfig.buildMode === builderBuildMode.RouteSuggestions)"
  >
    <div class="builder-tuning__group-double">
      <div class="builder-tuning__group-double-name">{{config.dictionary.change | translate}}</div>
      <div class="builder-tuning__group-double-fields">
        <div class="builder-tuning__group-double-field">
          <u-select-s
            [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
            [viewportElement]="viewportDropdown"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [container]="'body'"
            [placeholder]="config.dictionary.search | translate"
            [items]="builderDataConfig.mode === builderMode.Edit ? (builderDataService.customerIsOwnedBySc() ? options.changeTypesEdit : options.changeTypesShuttleCompany) : options.changeTypes"
            [(ngModel)]="builderDataService.datesChangeType"
            [empty]="false"
            [disabled]="!builderDataService.createOrEditRoutesAllowed()"
            [messages]="config.dictionary.uSelectS | translate"
            (ngModelChange)="builderDataService.changePreset(builderDataService.datesChangeType)"
            (click)="formControlClick.emit()"
          ></u-select-s>
        </div>
        <div class="builder-tuning__group-double-field">
          <u-range
            ngModel
            [messages]="config.dictionary.uRange | translate"
            [classUInput]="'u-input_cursor-pointer u-input_arrow'"
            [classUDropdownContent]="'u-dropdown-content_app-builder-changes'"
            [sundayFirstDay]="true"
            [autoClose]="false"
            [container]="'body'"
            [dateRangeContainer]="'body'"
            [daysOnly]="builderDataConfig.mode === builderMode.Add"
            [dateFrom]="builderDataService.datesChanges.dateFrom"
            [dateTo]="builderDataService.datesChanges.dateTo"
            [type]="builderDataService.datesChanges.type"
            [availablePresets]="
              !builderDataService.customerIsOwnedBySc() ? availablePresets[authModuleRouteBuilderFeatureType.ShuttleCompany] :
              (availablePresets[routeChangesType.Planned === builderDataService.datesChangeType ? 'planned' : 'notPlanned'])
            "
            [checkDaysActive]="builderDataService.datesChanges.checkDaysActive"
            [checkDaysAvailable]="builderDataService.datesChanges.checkDaysAvailable"
            [dates]="builderDataService.datesChanges.dates"
            [isRtl]="isRtl"
            [disabledDays]="builderDataService.disabledDays"
            [lang]="lang"
            [placement]="isRtl ? [ 'bottom-left' ] : [ 'bottom-right' ]"
            [placementCalendar]="isRtl ? ['left-top', 'right-top'] : ['right-top', 'left-top']"
            [checkDays]="true"
            [showIcon]="false"
            [required]="true"
            [disabled]="!builderDataService.createOrEditRoutesAllowed()"
            [displayedDay]="builderDataService.activeRide.date"
            [singleDateSelection]="!builderDataService.customerIsOwnedBySc()"
            [showDefaultMonth]="!builderDataService.customerIsOwnedBySc()"
            (saveAction)="builderDataService.saveDatesChangesChange($event)"
            (datesChange)="!builderDataService.customerIsOwnedBySc() ? builderDataService.datesChangesChange($event) : null"
            (click)="formControlClick.emit()"
          ></u-range>
        </div>
      </div>
    </div>
  </div>
  <div class="builder-tuning__item">
    <button
      *ngIf="
        builderDataConfig.mode === builderMode.Edit &&
        ((activeRide.plannedTimeType === 1 && activeRide.startDateTime !== activeRide.plannedStartDateTime) ||
        (activeRide.plannedTimeType === 2 && activeRide.endDateTime !== activeRide.plannedEndDateTime))
      "
      class="builder-tuning__warning"
      [uPopover]="warningTime"
      [container]="'body'"
      [placement]="isRtl ? [ 'left-top', 'left-bottom' ] : [ 'right-top', 'right-bottom' ]"
      [viewportElement]="viewportPopover"
      [viewportAutoClose]="true"
      [triggers]="'click'"
      [popoverClass]="
        isRtl ?
        'u-popover_app-builder u-popover_app-builder-left-top' :
        'u-popover_app-builder u-popover_app-builder-right-top'
      "
      [autoClose]="'outside'"
    >
      !
    </button>
    <div class="builder-tuning__group-double">
      <div class="builder-tuning__group-double-name">{{config.dictionary.hour | translate}}</div>
      <div class="builder-tuning__group-double-fields">
        <div class="builder-tuning__group-double-field">
          <u-input-time
            uId="builder-tuning-start-time"
            [disabled]="!builderDataService.isDetailsCustomerIdValid || activeRide.rideCalculationMode === builderCalculationMode.Manual || !builderDataService.createOrEditRoutesAllowed()"
            [(ngModel)]="activeRide.startDateTime"
            (ngModelChange)="builderDataService.editStartTime()"
            (click)="editStartTimeClick(); formControlClick.emit()"
          ></u-input-time>
        </div>
        <div class="builder-tuning__group-double-field">
          <u-input-time
            uId="builder-tuning-end-time"
            [disabled]="!builderDataService.isDetailsCustomerIdValid || activeRide.rideCalculationMode === builderCalculationMode.Manual || !builderDataService.createOrEditRoutesAllowed()"
            [(ngModel)]="activeRide.endDateTime"
            (ngModelChange)="builderDataService.editEndTime()"
            (click)="editEndTimeClick(); formControlClick.emit()"
          ></u-input-time>
        </div>
      </div>
    </div>
  </div>
  <div
    class="builder-tuning__item"
    *ngIf="builderDataService.showEditMethod()"
  >
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name">{{config.dictionary.editMethod.title | translate}}</div>
      <div class="builder-tuning__group-field">
        <u-toggle
          uId="builder-tuning-edit-method"
          class="u-toggle_app-builder-tuning"
          [(ngModel)]="builderDataService.isEditableActiveDays"
          [messages]="config.dictionary.editMethod.toggle | translate"
          [disabled]="!builderDataService.isDetailsCustomerIdValid || !builderDataService.createOrEditRoutesAllowed()"
          (toggleChanged)="trackEvent('click on Edit method - ' + (builderDataService.isEditableActiveDays ? 'inclusive' : 'daily'))"
          (click)="formControlClick.emit()"
        ></u-toggle>
      </div>
    </div>
  </div>
  <div
    *ngIf="routeBuilderFeatureMasterCustomer()"
    class="builder-tuning__item"
  >
    <div class="builder-tuning__divider"></div>
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name builder-tuning__group-name_next-line">
        {{config.dictionary.subCustomers | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-multiselect
          uId="builder-tuning-master-sub-customers"
          [alphabeticalSort]="true"
          [items]="options.masterSubCustomers"
          [messages]="config.dictionary.uMultiselect | translate"
          [placeholder]="config.dictionary.select | translate"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [disabled]="!builderDataService.createOrEditRoutesAllowed()"
          [(ngModel)]="builderDataService.masterSubCustomerIds"
          (click)="formControlClick.emit()"
        ></u-multiselect>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="mode === builderTuningMode.SC">
  <div class="builder-tuning__item">
    <div class="builder-tuning__group">
      <div
        class="builder-tuning__group-name"
        [uTooltip]="(routeBuilderFeatureTypeShuttleCompany() ? config.dictionary.executingSc : config.dictionary.sc) | translate"
      >
        {{(routeBuilderFeatureTypeShuttleCompany() ? config.dictionary.executingSc : config.dictionary.sc) | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [uId]="'builder-tuning-sc'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [placeholder]="config.dictionary.search | translate"
          [items]="options.shuttleCompanies"
          [formControl]="addEditForm.get('activeRide.shuttleCompanyId')"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [emptyValue]="null"
          [messages]="config.dictionary.uSelectS | translate"
          [valid]="addEditForm.get('activeRide.shuttleCompanyId').disabled || addEditForm.get('activeRide.shuttleCompanyId').valid"
          (click)="formControlClick.emit()"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      builderDataConfig.buildMode !== builderBuildMode.RouteTemplates &&
      (!routeBuilderFeatureTypeShuttleCompany() || addEditForm.get('activeRide.isMainScSession').value)
    "
    class="builder-tuning__item"
  >
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name">
        {{config.dictionary.contract | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [uId]="'builder-tuning-contract-id'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [formControl]="addEditForm.get('activeRide.contractId')"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [items]="builderDataService.options.contracts"
          [empty]="true"
          [emptyValue]="null"
          [messages]="config.dictionary.uSelectS | translate"
          [placeholder]="config.dictionary.select | translate"
          (click)="formControlClick.emit()"
        ></u-select-s>
      </div>
    </div>
  </div>

  <div class="builder-tuning__item">
    <div class="builder-tuning__group">
      <div
        class="builder-tuning__group-name"
        uTooltip="{{config.dictionary.carType | translate}}"
        [placement]="[ 'top', 'bottom' ]"
        [container]="'body'"
      >
        {{config.dictionary.carType | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [uId]="'builder-tuning-planned-car-type'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [placeholder]="config.dictionary.search | translate"
          [items]="addEditForm.get('activeRide.shuttleCompanyId').value ? builderTuningStoreService.carTypes() : options.carTypes"
          [formControl]="addEditForm.get('activeRide.carTypeId')"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [emptyValue]="null"
          [messages]="config.dictionary.uSelectS | translate"
          [valid]="addEditForm.get('activeRide.carTypeId').disabled || addEditForm.get('activeRide.carTypeId').valid"
          (click)="carTypeIdClick(); formControlClick.emit()"
        ></u-select-s>
      </div>
      <div class="builder-tuning__group-metadata">
        <span
          class="builder-tuning__group-metadata-car-capacity"
          [uPopover]="carCapacity"
          [container]="'body'"
          [placement]="isRtl ? [ 'left-top', 'left-bottom' ] : [ 'right-top', 'right-bottom' ]"
          [viewportElement]="viewportPopover"
          [viewportAutoClose]="true"
          [triggers]="'hover'"
          [popoverClass]="'u-popover_app-builder-tuning-car-capacity'"
          [autoClose]="true"
        >
          {{activeRide.seatsCount ? activeRide.seatsCount : null}}
        </span>
      </div>
    </div>
  </div>
  <app-builder-tuning-cost
    *ngIf="!routeBuilderFeatureTypeShuttleCompany() || addEditForm.get('activeRide.isMainScSession').value"
    (formControlClick)="formControlClick.emit()"
  ></app-builder-tuning-cost>
  <div *ngIf="routeBuilderFeatureTypeShuttleCompany() && addEditForm.get('activeRide.shuttleCompanyId').value" class="builder-tuning__item">
    <div class="builder-tuning__group">
      <div
        class="builder-tuning__group-name"
        [uTooltip]="(routeBuilderFeatureTypeShuttleCompany() ? config.dictionary.subContractorDriverCost : config.dictionary.executionCost) | translate"
      >
        {{(routeBuilderFeatureTypeShuttleCompany() ? config.dictionary.subContractorDriverCost : config.dictionary.executionCost) | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-input-number
          [uId]="'builder-tuning-execution-cost'"
          [decimal]="true"
          [min]="0"
          [maxDecimalDigits]="2"
          [formControl]="addEditForm.get('activeRide.executionCost')"
          (click)="formControlClick.emit()"
        ></u-input-number>
      </div>
    </div>
  </div>
  <div
    *ngIf="builderTuningStoreService.hashcalRideTypesItems()?.[addEditForm.get('activeRide.costType').value]?.length"
    class="builder-tuning__item"
  >
    <div class="builder-tuning__group">
      <div
        class="builder-tuning__group-name"
        uTooltip="{{config.dictionary.hashcalRideType | translate}}"
        [placement]="[ 'top', 'bottom' ]"
        [container]="'body'"
      >
        {{config.dictionary.hashcalRideType | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [uId]="'builder-tuning-hashcal-ride-type'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [formControl]="addEditForm.get('activeRide.hashcalRideType')"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [emptyName]="config.dictionary.select | translate"
          [emptyValue]="null"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [items]="builderTuningStoreService.hashcalRideTypesItems()?.[addEditForm.get('activeRide.costType').value]"
          [valid]="addEditForm.get('activeRide.hashcalRideType').valid"
          [messages]="config.dictionary.uSelectS | translate"
          [placeholder]="config.dictionary.search | translate"
          (click)="formControlClick.emit()"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div class="builder-tuning__item" *ngIf="builderDataService.hasPurchaseOrderFeature">
    <div class="builder-tuning__group">
      <div
        class="builder-tuning__group-name"
        uTooltip="{{config.dictionary.purchaseOrder | translate}}"
      >
        {{config.dictionary.purchaseOrder | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [uId]="'builder-tuning-purchase-order'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [placeholder]="config.dictionary.search | translate"
          [items]="options.purchaseOrders"
          [formControl]="addEditForm.get('activeRide.purchaseOrder')"
          [valid]="addEditForm.get('activeRide.purchaseOrder').valid || addEditForm.get('activeRide.purchaseOrder').disabled"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [emptyValue]="null"
          [messages]="config.dictionary.uSelectS | translate"
          (click)="formControlClick.emit()"
          (selectItem)="selectPurchaseOrder($event.value)"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div class="builder-tuning__item" *ngIf="builderDataService.hasPurchaseOrderFeature">
    <div class="builder-tuning__group">
      <div
        class="builder-tuning__group-name"
        uTooltip="{{config.dictionary.purchaseOrderRow | translate}}">
        {{config.dictionary.purchaseOrderRow | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [uId]="'builder-tuning-purchase-row'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [placeholder]="config.dictionary.search | translate"
          [items]="options.purchaseOrderRows"
          [formControl]="addEditForm.get('activeRide.purchaseOrderRow')"
          [valid]="addEditForm.get('activeRide.purchaseOrderRow').valid || addEditForm.get('activeRide.purchaseOrderRow').disabled"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [emptyValue]="null"
          [messages]="config.dictionary.uSelectS | translate"
          (click)="formControlClick.emit()"
          (selectItem)="selectPurchaseOrderRow()"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div class="builder-tuning__item">
    <div class="builder-tuning__group">
      <div
        class="builder-tuning__group-name"
        uTooltip="{{config.dictionary.carNum | translate}}"
        [placement]="[ 'top', 'bottom' ]"
        [container]="'body'"
      >
        {{config.dictionary.carNum | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [uId]="'builder-tuning-executing-car'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [placeholder]="(activeRide.vehicle | builderVehicleNumber) || (config.dictionary.search | translate)"
          [items]="carNumbers()"
          [(ngModel)]="activeRide.carId"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [emptyValue]="null"
          [disabled]="
            !builderDataService.isDetailsCustomerIdValid ||
            !addEditForm.get('activeRide.shuttleCompanyId').value ||
            !builderDataService.isSelectedRouteBuilderAuthShuttleCompanyId ||
            !builderDataService.createOrEditRoutesAllowed()
          "
          [messages]="config.dictionary.uSelectS | translate"
          (ngModelChange)="builderDataService.editCar()"
          (click)="formControlClick.emit()"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div class="builder-tuning__item">
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name">{{config.dictionary.driver | translate}}</div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [uId]="'builder-tuning-driver'"
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [placeholder]="(activeRide.driver | builderDriverName) || (config.dictionary.search | translate)"
          [items]="builderTuningStoreService.drivers()"
          [(ngModel)]="activeRide.driverId"
          [emptyName]="config.dictionary.select | translate"
          [emptyRemove]="config.dictionary.removeSelect | translate"
          [emptyValue]="null"
          [messages]="config.dictionary.uSelectS | translate"
          [disabled]="
            !builderDataService.isDetailsCustomerIdValid ||
            !addEditForm.get('activeRide.shuttleCompanyId').value ||
            !builderDataService.isSelectedRouteBuilderAuthShuttleCompanyId ||
            !builderDataService.createOrEditRoutesAllowed()
          "
          (ngModelChange)="driverIdChange(); builderDataService.editDriver()"
          (click)="formControlClick.emit()"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div *ngIf="routeBuilderFeatureTransportAdditionalFieldsDriverHours() && builderDataService.isSelectedRouteBuilderAuthShuttleCompanyId" class="builder-tuning__item">
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name" uTooltip="{{config.dictionary.driverHours | translate}}">
        {{config.dictionary.driverHours | translate}}
      </div>
      <div class="builder-tuning__group-field">
        <u-input-time
          uId="builder-tuning-driver-hours"
          [formControl]="addEditForm.get('activeRide.driverHours')"
          [min]="'00:01'"
          (click)="formControlClick.emit()"
        ></u-input-time>
      </div>
    </div>
  </div>
  <div *ngIf="!routeBuilderFeatureTypeShuttleCompany()" class="builder-tuning__item">
    <div class="builder-tuning__group">
      <div class="builder-tuning__group-name">{{config.dictionary.yit | translate}}</div>
      <div class="builder-tuning__group-field">
        <u-select-s
          [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
          [viewportElement]="viewportDropdown"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [container]="'body'"
          [placeholder]="config.dictionary.search | translate"
          [items]="options.yit"
          [(ngModel)]="activeRide.isYitActive"
          [empty]="false"
          [disabled]="!addEditForm.get('activeRide.shuttleCompanyId').value || !builderDataService.createOrEditRoutesAllowed()"
          [messages]="config.dictionary.uSelectS | translate"
          (ngModelChange)="builderDataService.editYit()"
          (click)="formControlClick.emit()"
        ></u-select-s>
      </div>
    </div>
  </div>
  <div *ngIf="!routeBuilderFeatureTypeShuttleCompany()" class="builder-tuning__item">
    <div class="builder-tuning__group-double">
      <div class="builder-tuning__group-double-name">{{config.dictionary.yitSyncBy | translate}}</div>
      <div class="builder-tuning__group-double-fields">
        <div class="builder-tuning__group-double-field">
          <u-select-s
            [classUDropdownContent]="'u-dropdown-content_app-builder-tuning'"
            [viewportElement]="viewportDropdown"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [container]="'body'"
            [placeholder]="config.dictionary.search | translate"
            [items]="options.yitTypes"
            [(ngModel)]="activeRide.yitSyncMode"
            [empty]="false"
            [disabled]="!addEditForm.get('activeRide.shuttleCompanyId').value || !builderDataService.createOrEditRoutesAllowed()"
            [messages]="config.dictionary.uSelectS | translate"
            (ngModelChange)="builderDataService.editYit()"
            (click)="formControlClick.emit()"
          ></u-select-s>
        </div>
        <div class="builder-tuning__group-double-field">
          <input
            uInput
            [(ngModel)]="activeRide.yitSyncValue"
            [disabled]="!builderDataService.createOrEditRoutesAllowed()"
            (ngModelChange)="builderDataService.editYit()"
            [ngModelOptions]="{ updateOn: 'blur' }"
            (click)="formControlClick.emit()"
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
