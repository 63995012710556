import { Injectable, OnDestroy, signal, WritableSignal } from '@angular/core';
import { finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, throwError, Subscriber, Subject } from 'rxjs';
import { cloneDeep, isEqual, escape } from 'lodash';
import * as moment from 'moment';
import {
  UHereMapMarkerConfig,
  UHereMapPathConfig,
  UHereMapPathType,
  UHereMapPathWaypoint,
  UPopupService
} from '@shift/ulib';

import { environment } from '@environments/environment';
import { Errors, MovePassengersError, MovePassengersInfoRoute, MovePassengersMapData, MovePassengersRouteColor, MovePassengersRouteColorItem, MovePassengersRouteMapViewStationType } from '@app/shared/models';
import { movePassengersConfig } from '@app/shared/configs';
import { AppConstants } from '@app/shared/constants';
import {
  RoutePlannerMovePassengersItem,
  RoutePlannerMovePassengersRoute,
  RoutePlannerMovePassengersBase,
  RoutePlannerMovePassengersBody,
  RoutePlannerGetRoutesMapViewBody,
  RoutePlannerMovePassengersSourceRouteInitedData,
  RoutePlannerGetRoutesMapView,
  RoutePlannerGetRoutesMapViewStation,
  RoutePlannerMovePassengersActionBase
} from '@app/route-planner/models';
import { BuilderRoutesStore } from '@app/builder/stores';
import { AuthDataService } from '@app/auth/services';
import { RoutePlannerService } from '@app/route-planner/services';

@Injectable()
export class BuilderFullMovePassengersDataService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  private dataStore: {
    passengers: RoutePlannerMovePassengersItem[];
    passengersChecked: RoutePlannerMovePassengersItem[];
  } = {
      passengers: [],
      passengersChecked: []
    };

  #isLoading: WritableSignal<boolean> = signal(false);

  isLoading = this.#isLoading.asReadonly();
  passengers: RoutePlannerMovePassengersItem[] = [];
  routes: RoutePlannerMovePassengersRoute[] = [];
  routesColors: MovePassengersRouteColorItem[] = cloneDeep(movePassengersConfig.routesColors);
  sourceRouteId: number;
  infoSourceRoute: MovePassengersInfoRoute;
  infoRoutes: MovePassengersInfoRoute[] = [];
  mapData: MovePassengersMapData = new MovePassengersMapData();

  get passengersSelected(): RoutePlannerMovePassengersItem[] {
    return this.passengers.filter(passenger => passenger.check);
  }

  constructor(
    private uPopupService: UPopupService,
    private routePlannerService: RoutePlannerService,
    private builderRoutesStore: BuilderRoutesStore,
    private authDataService: AuthDataService
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private toggleRouteColor(currentColor: MovePassengersRouteColor, routeId: number, available: boolean) {
    this.routesColors = this.routesColors.map(color => currentColor && color.name === currentColor ? {
      ...color,
      available
    } : color);

    this.routes = this.routes.map(route => route.routeId === routeId ? {
      ...route,
      color: available ? null : currentColor
    } : route);
  }

  private getMapViewRouteBody(route: { routeId: number; rideId: number; }): RoutePlannerGetRoutesMapViewBody {
    return {
      routes: [ route ]
    };
  }

  private getMapViewRoutesBody(routes: { routeId: number; rideId: number; }[]): RoutePlannerGetRoutesMapViewBody {
    return {
      routes
    };
  }

  private updateInfoSourceRoute(routeMapViewData: RoutePlannerGetRoutesMapView) {
    this.infoSourceRoute = {
      ...this.infoSourceRoute,
      distance: routeMapViewData.distance,
      duration: moment.duration(routeMapViewData.duration).format(AppConstants.TIME_FORMAT_FULL)
    };
  }

  private updateInfoRoutes(mapViewData: RoutePlannerGetRoutesMapView, routeId: number) {
    const route = this.routes.find(obj => obj.routeId === routeId);
    const isInfoRouteExist = this.infoRoutes.some(obj => obj.routeId === route.routeId);
    const areInfoRoutesFull = this.infoRoutes.length === this.routesColors.length;

    if (!isInfoRouteExist && areInfoRoutesFull) { return; }

    const infoRoute = {
      routeId: route.routeId,
      name: route.name,
      code: route.number,
      distance: mapViewData.distance,
      duration: moment.duration(mapViewData.duration).format(AppConstants.TIME_FORMAT_FULL),
      color: route.color
    };

    this.infoRoutes = isInfoRouteExist ?
      this.infoRoutes.map(obj => obj.routeId === route.routeId ? infoRoute : obj) :
      [ ...this.infoRoutes, infoRoute ];
  }

  private getMarkersStations(stations: RoutePlannerGetRoutesMapViewStation[], routeId: number, routeColor?: MovePassengersRouteColor): UHereMapMarkerConfig[] {
    if (!stations || !stations.length) { return []; }

    let markerStationNumber = 0;
    let markerDestinationNumber = 0;

    const destinations = stations.filter(station => station.type === MovePassengersRouteMapViewStationType.Target);

    let labelContent: number = null;

    return stations.map(station => {
      switch (station.type) {
        case MovePassengersRouteMapViewStationType.Station: {
          markerStationNumber += 1;
          labelContent = markerStationNumber;

          break;
        }

        case MovePassengersRouteMapViewStationType.Target: {
          markerDestinationNumber += 1;
          labelContent = markerDestinationNumber;

          break;
        }
      }

      const userInfo = this.authDataService.userInfo();
      const map = movePassengersConfig.map;
      const currentMarker = map.markers[station.type];
      const currentMarkerDefaultByCustomerType = currentMarker.default[userInfo.customer.type] || currentMarker.default.default;
      const currentMarkerDefaultByEnvType = currentMarkerDefaultByCustomerType[environment.config.environmentType] || currentMarkerDefaultByCustomerType.base;
      const currentMarkerIcon = currentMarker?.[routeColor] || currentMarkerDefaultByEnvType;

      return {
        id: `route-${routeId}-station-${station.rideStationId}`,
        label: (station.type === MovePassengersRouteMapViewStationType.Target && destinations.length <= 1) ||
          station.type === MovePassengersRouteMapViewStationType.Accompany ? null :
          {
            content: station.type === MovePassengersRouteMapViewStationType.Target ? '' : labelContent,
            style: currentMarkerIcon.label.style
          },
        content: escape(station.address),
        position: {
          lat: station.latitude,
          lng: station.longitude
        },
        icon: currentMarkerIcon.icon,
        draggable: false,
        zIndex: routeColor ? map.zIndex.commonRoute : map.zIndex.sourceRoute
      };
    });
  }
  private updateMapDataMarkerCenterConfigAndBounds() {
    this.mapData.markerCenter.config = {
      ...this.mapData.markerCenter.config,
      bounds: {
        points: this.mapData.markers.stations.map(station => station.position)
      }
    };

    this.mapData.bounds = {
      points: this.mapData.markers.stations.map(station => station.position)
    };
  }

  private setMarkersStations(markersStations: UHereMapMarkerConfig[]) {
    if (isEqual(this.mapData.markers.stations, markersStations)) { return; }

    this.mapData.markers.stations = [ ...this.mapData.markers.stations, ...markersStations ];

    this.updateMapDataMarkerCenterConfigAndBounds();
  }

  private setMapDataPosition(markersStations: UHereMapMarkerConfig[]) {
    this.mapData.fitCenter.position = markersStations.length ? null : {
      lat: this.mapData.coords.lat,
      lng: this.mapData.coords.lng
    };
  }

  private getFirstAccompanyWaypoints(
    stations: RoutePlannerGetRoutesMapViewStation[],
    firstStation: RoutePlannerGetRoutesMapViewStation,
    secondStation: RoutePlannerGetRoutesMapViewStation
  ): UHereMapPathWaypoint[] {
    if (
      stations && stations.length > 1 && firstStation && firstStation.type === MovePassengersRouteMapViewStationType.Accompany &&
      firstStation.latitude && firstStation.longitude && secondStation
    ) {
      return [
        {
          lat: firstStation.latitude,
          lng: firstStation.longitude,
          stopover: false
        },
        {
          lat: secondStation.latitude,
          lng: secondStation.longitude,
          stopover: false
        }
      ];
    }
  }

  private getLastAccompanyWaypoints(
    stations: RoutePlannerGetRoutesMapViewStation[],
    penultStation: RoutePlannerGetRoutesMapViewStation,
    lastStation: RoutePlannerGetRoutesMapViewStation
  ): UHereMapPathWaypoint[] {
    if (
      stations && stations.length > 1 && penultStation && lastStation && lastStation.type === MovePassengersRouteMapViewStationType.Accompany &&
      lastStation.latitude && lastStation.longitude
    ) {
      return [
        {
          lat: penultStation.latitude,
          lng: penultStation.longitude,
          stopover: false
        },
        {
          lat: lastStation.latitude,
          lng: lastStation.longitude,
          stopover: false
        }
      ];
    }
  }

  private generateMainPathConfig(mapView: RoutePlannerGetRoutesMapView, routeColor?: string): UHereMapPathConfig[] {
    const mainWaypoints = mapView.waypoints
      .map(waypoint => ({
        lat: waypoint.latitude,
        lng: waypoint.longitude,
        stopover: waypoint.stopover
      }));

    if (!mainWaypoints.length) { return []; }

    const { encodedPolylineJson } = mapView;

    const map = movePassengersConfig.map;
    const zIndex = routeColor ? map.zIndex.commonRoute : map.zIndex.sourceRoute;
    const color = routeColor || map.path.colors.main;
    const lineWidth = 3;

    if (encodedPolylineJson) {
      return [
        {
          id: `${mapView.routeId}`,
          type: UHereMapPathType.Polylines,
          color,
          waypoints: mainWaypoints,
          polylines: JSON.parse(encodedPolylineJson),
          zIndex,
          lineWidth
        }
      ];
    }

    return [
      {
        id: `${mapView.routeId}`,
        type: UHereMapPathType.Waypoints,
        color,
        waypoints: mainWaypoints,
        zIndex,
        lineWidth
      }
    ];
  }

  private generateAccompanyPathConfig(
    mapView: RoutePlannerGetRoutesMapView,
    stations: RoutePlannerGetRoutesMapViewStation[]
  ): UHereMapPathConfig[] {
    const firstAccompanyWaypoints = this.getFirstAccompanyWaypoints(stations, stations[0], stations[1]);
    const lastAccompanyWaypoints = this.getLastAccompanyWaypoints(stations, stations[stations.length - 2], stations[stations.length - 1]);

    if ((!firstAccompanyWaypoints || !firstAccompanyWaypoints.length) && (!lastAccompanyWaypoints || !lastAccompanyWaypoints.length)) {
      return [];
    }

    const map = movePassengersConfig.map;

    const firstAccompanyPathConfig = {
      id: `route-${mapView.routeId}-firstAccompany`,
      type: UHereMapPathType.Waypoints,
      color: map.path.colors.accompany,
      waypoints: firstAccompanyWaypoints
    };

    const lastAccompanyPathConfig = {
      id: `route-${mapView.routeId}-lastAccompany`,
      type: UHereMapPathType.Waypoints,
      color: map.path.colors.accompany,
      waypoints: lastAccompanyWaypoints
    };

    if (firstAccompanyWaypoints && firstAccompanyWaypoints.length && lastAccompanyWaypoints && lastAccompanyWaypoints.length) {
      return [ firstAccompanyPathConfig, lastAccompanyPathConfig ];
    }

    if (firstAccompanyWaypoints && firstAccompanyWaypoints.length) {
      return [ firstAccompanyPathConfig ];
    }

    return [ lastAccompanyPathConfig ];
  }

  private setWaypointsAndPaths(data: RoutePlannerGetRoutesMapView, stations: RoutePlannerGetRoutesMapViewStation[], routeColor?: string) {
    const paths: UHereMapPathConfig[] = [
      ...this.generateMainPathConfig(data, routeColor),
      ...this.generateAccompanyPathConfig(data, stations)
    ];

    if (!isEqual(this.mapData.paths, paths)) {
      this.mapData.paths = [ ...this.mapData.paths, ...paths ];
    }
  }

  private setMapData(data: RoutePlannerGetRoutesMapView) {
    const route = this.routes.find(obj => obj.routeId === data.routeId);
    const routeColor = route && this.routesColors.find(obj => obj.name === route.color);
    const stations = data.stations.filter(station => station.latitude && station.longitude);

    const markersStations: UHereMapMarkerConfig[] = this.getMarkersStations(stations, data.routeId, routeColor && route.color);

    this.setMarkersStations(markersStations);
    this.setMapDataPosition(markersStations);
    this.setWaypointsAndPaths(data, stations, routeColor && routeColor.value);
  }

  private removeRouteFromMap(routeId: number) {
    this.mapData.paths = this.mapData.paths.filter(path => +path.id !== routeId && ![ `route-${routeId}-firstAccompany`, `route-${routeId}-lastAccompany` ].includes(path.id));
    this.mapData.markers.stations = this.mapData.markers.stations.filter(station => !(station.id as string).startsWith(`route-${routeId}`));
  }

  private removeRouteFromInfoRoutes(routeId: number) {
    this.infoRoutes = this.infoRoutes.filter(route => route.routeId !== routeId);
  }

  private getRouteColorByValue(value: string) {
    return this.routesColors.find(color => color.value === value);
  }

  private getPathById(routeId: number) {
    return this.mapData.paths.find(path => +path.id === routeId);
  }

  private updateMapDataMarkersStations(mapViewData: RoutePlannerGetRoutesMapView, routeColorName: MovePassengersRouteColor) {
    const markersStations = this.getMarkersStations(mapViewData.stations, mapViewData.routeId, routeColorName);

    this.mapData.markers.stations = this.mapData.markers.stations.filter(station => {
      if ((station.id as string).startsWith(`route-${mapViewData.routeId}`)) {
        return markersStations.some(markerStation => markerStation.id === station.id);
      }

      return !!station;
    });

    this.mapData.markers.stations = this.mapData.markers.stations.map(station =>
      markersStations.find(currentStation => currentStation.id === station.id) || station
    );

    this.mapData.markers.stations = [
      ...this.mapData.markers.stations,
      ...markersStations.filter(markerStation => !this.mapData.markers.stations.some(station => markerStation.id === station.id))
    ];

    this.updateMapDataMarkerCenterConfigAndBounds();
    this.setMapDataPosition(markersStations);
  }

  private updateMapDataPath(mapViewData: RoutePlannerGetRoutesMapView, currentPath: UHereMapPathConfig) {
    this.mapData.paths = this.mapData.paths.map(path => path.id === currentPath.id ? {
      ...path,
      waypoints: mapViewData.stations.map(station => ({
        lat: station.latitude,
        lng: station.longitude,
        stopover: false
      })),
      polylines: JSON.parse(mapViewData.encodedPolylineJson)
    } : path);
  }

  private updateMapData(mapViewData: RoutePlannerGetRoutesMapView, currentPath: UHereMapPathConfig, routeColor: MovePassengersRouteColor) {
    this.updateMapDataMarkersStations(mapViewData, routeColor);
    this.updateMapDataPath(mapViewData, currentPath);
  }

  private updateIsLoading(value: boolean) {
    this.#isLoading.set(value);
  }

  init(route: RoutePlannerMovePassengersSourceRouteInitedData) {
    this.routePlannerService
      .getMovePassengers({
        routeId: route.id
      })
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe((data: RoutePlannerMovePassengersBase) => {
        this.passengers = data.passengers.map(obj => new RoutePlannerMovePassengersItem(obj));
        this.routes = data.routes.map(obj => new RoutePlannerMovePassengersRoute(obj));
        this.dataStore.passengers = this.passengers;
        this.sourceRouteId = route.id;
        this.infoSourceRoute = {
          routeId: route.id,
          name: route.name,
          code: route.number,
          distance: null,
          duration: null
        };

        const [ passenger ] = this.passengers;

        if (passenger && passenger.rideId) {
          this.loadMap(this.getMapViewRouteBody({ routeId: route.id, rideId: passenger.rideId }));
        }
      });
  }

  loadMap(body: RoutePlannerGetRoutesMapViewBody, routeId?: number, update?: boolean) {
    this.routePlannerService.getRoutesMapView(body)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        data.forEach(mapViewData => {
          const isSourceRoute = mapViewData.routeId === this.sourceRouteId;

          if (isSourceRoute) {
            this.updateInfoSourceRoute(mapViewData);
          }

          if (routeId && !isSourceRoute) {
            this.updateInfoRoutes(mapViewData, routeId);
          }

          if (update) {
            const currentPath = this.getPathById(mapViewData.routeId);

            if (currentPath) {
              const routeColor = this.getRouteColorByValue(currentPath.color);

              this.updateMapData(
                mapViewData,
                currentPath,
                routeColor && routeColor.name
              );
            }
          } else {
            this.setMapData(mapViewData);
          }
        });
      });
  }

  removePassengersByIds(ids: number[]) {
    this.dataStore.passengers = this.passengers;
    this.passengers = this.passengers.filter(passenger => !ids.includes(passenger.id));
  }

  revertAllPassengers() {
    this.passengers = this.dataStore.passengers;
  }

  updateCheckForPassengers(passengers: RoutePlannerMovePassengersItem[], check: boolean) {
    this.passengers = this.passengers.map(passenger => {
      const updatePassenger = passengers.some(obj => obj.id === passenger.id);

      return updatePassenger ? { ...passenger, check } : passenger;
    });
  }

  refreshPassengers() {
    this.passengers = [ ...this.passengers ];
  }

  updateCheckForPassenger(passengerId: number, check: boolean) {
    this.passengers = this.passengers.map(passenger => passenger.id === passengerId ? { ...passenger, check } : passenger);
  }

  updateCheckedPassengers() {
    if (this.dataStore.passengersChecked.length) {
      this.passengers = this.passengers.map(passenger => {
        const passengerChecked = this.dataStore.passengersChecked.some(ob => ob.id === passenger.id);

        return {
          ...passenger,
          check: passengerChecked
        };
      });
    }

    this.dataStore.passengersChecked = [];
  }

  updateRouteById = (routeId: number, route: object) => {
    this.routes = this.routes.map(ob => ob.routeId === routeId ? {
      ...ob,
      ...route
    } : ob);
  };

  updateRouteColor(routeId: number) {
    const route = this.routes.find(obj => obj.routeId === routeId);

    if (route.color) {
      this.removeRouteFromMap(routeId);
      this.removeRouteFromInfoRoutes(routeId);
      this.toggleRouteColor(route.color, route.routeId, true);
    } else {
      const availableColor = this.routesColors.find(color => color.available);

      this.toggleRouteColor(availableColor && availableColor.name || null, route.routeId, false);

      this.loadMap(this.getMapViewRouteBody({ routeId: route.routeId, rideId: route.rideId }), route.routeId);
    }
  }

  updateMovePassengersData(data: RoutePlannerMovePassengersActionBase, routeId: number) {
    const { updatedRoute, updatedSummaries } = data;

    this.updateRouteById(updatedRoute.routeId, updatedRoute);

    this.builderRoutesStore.updateRoutes(updatedSummaries.map(obj => ({
      routeId: obj.routeId,
      code: obj.number,
      name: obj.name,
      direction: obj.direction,
      days: obj.activeDays,
      startDate: obj.startDate,
      endDate: obj.endDate,
      rideStartDateTime: obj.startTime,
      rideEndDateTime: obj.endTime,
      totalPassengers: obj.totalPassengers,
      carTypeName: obj.carType ? obj.carType.name : null,
      carTypeCapacity: obj.carType ? obj.carType.value : null,
      shuttleCompany: obj.shuttleCompany ? obj.shuttleCompany.name : null,
      timeType: obj.timeType,
      locked: false,
      branchNames: obj.branchNames
    })));

    const [ passenger ] = this.passengers;
    const sourceRoute = { routeId: updatedRoute.routeId, rideId: updatedRoute.rideId };

    if (!passenger) {
      this.removeRouteFromMap(this.sourceRouteId);
      this.removeRouteFromInfoRoutes(this.sourceRouteId);

      const targetRoute = this.routes.find(route => route.routeId === routeId);

      if (targetRoute && !targetRoute.color) { return; }
    }

    this.loadMap(this.getMapViewRoutesBody(passenger ? [ sourceRoute, { routeId: this.sourceRouteId, rideId: passenger.rideId } ] : [ sourceRoute ]), routeId, true);
  }

  movePassengersToRoute(passengerIds: number[], routeId: number, originalRouteId: number) {
    this.updateIsLoading(true);

    const params: RoutePlannerMovePassengersBody = {
      passengerIds,
      originalRouteId,
      routeId,
      allowCapacityChange: false
    };

    this.routePlannerService
      .movePassengers(params, [ Errors.OverloadedCarType ])
      .pipe(
        take(1),
        finalize(() => this.updateIsLoading(false))
      )
      .subscribe(
        data => this.updateMovePassengersData(data, routeId),
        err => {
          if (err.code === Errors.OverloadedCarType) {
            this.moveToRouteConfirmCarChange(
              {
                ...params,
                allowCapacityChange: true
              },
              routeId
            );
          } else {
            this.revertAllPassengers();
          }
        }
      );
  }

  moveToRouteConfirmCarChange(params: RoutePlannerMovePassengersBody, routeId: number) {
    this.confirmCarChange()
      .pipe(
        take(1),
        switchMap((valid: boolean) => {
          if (valid) {
            this.updateIsLoading(true);

            return this.routePlannerService.movePassengers(params)
              .pipe(finalize(() => this.updateIsLoading(false)));
          }

          return throwError(new Error(MovePassengersError.CancelMovePassengers));
        }),
        takeUntil(this.unsubscribe),
        finalize(() => this.updateCheckedPassengers())
      )
      .subscribe(
        data => this.updateMovePassengersData(data, routeId),
        error => {
          this.revertAllPassengers();

          if (error.message !== MovePassengersError.CancelMovePassengers) {
            this.movePassengersError();
          }
        }
      );
  }

  confirmCarChange(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      this.uPopupService.showMessage({
        message: 'builder.movePassengers.confirmCarChange',
        yes: 'general.yes',
        no: 'general.no'
      },
      () => {
        subscriber.next(true);
        subscriber.complete();
      },
      () => {
        subscriber.next(false);
        subscriber.complete();
      },
      () => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  movePassengersError() {
    this.uPopupService.showMessage({
      message: 'builder.movePassengers.movePassengersError',
      yes: 'general.confirm'
    }, null);
  }
}
