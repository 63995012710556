<app-general-card
  [title]="config.dictionary.title"
  [buttons]="config.buttons"
  (buttonClick)="checkApplicableTo()"
  (closeAction)="closeConfirmComment()"
>
  <div class="routes-temp-comment__body">
    <span class="routes-temp-comment__body-title">{{route.code}} {{route.name}}</span>
    <div class="routes-temp-comment__body-form">
      <u-group
        class="u-group_app-routes-temp-comment"
        [name]="config.dictionary.period | translate"
      >
        <u-range
          [messages]="'uRange' | translate"
          [classUInput]="'u-input_cursor-pointer u-input_arrow'"
          [classUDropdownContent]="'u-dropdown-content_app-routes-temp-comment'"
          [sundayFirstDay]="true"
          [viewportElement]="viewportElement"
          [autoClose]="false"
          [container]="'body'"
          [dateRangeContainer]="'body'"
          [availablePresets]="form.get('datesChange').get('availablePresets').value"
          [type]="form.get('datesChange.type').value"
          [dates]="form.get('datesChange').get('dates').value"
          [dateFrom]="form.get('datesChange').get('dateFrom').value"
          [dateTo]="form.get('datesChange').get('dateTo').value"
          [checkDaysActive]="form.get('datesChange').get('checkDaysActive').value"
          [checkDaysAvailable]="form.get('datesChange').get('checkDaysAvailable').value"
          [displayedDay]="form.get('datesChange').get('displayedDay').value"
          [disableDaysBefore]="config.minDate"
          [disableDaysAfter]="config.maxDate"
          [showDaysPicker]="false"
          [isRtl]="isRtl"
          [lang]="lang"
          [inputValid]="form.get('datesChange').valid"
          [placement]="isRtl ? [ 'bottom-left' ] : [ 'bottom-right' ]"
          [placementCalendar]="isRtl ? ['left-top', 'right-top'] : ['right-top', 'left-top']"
          [checkDays]="true"
          [showIcon]="false"
          [required]="true"
          (saveAction)="onDatesSave($event)"
        ></u-range>
      </u-group>
      <u-group
        class="u-group_app-routes-temp-comment routes-temp-comment__body-form-comment-for"
        [name]="config.dictionary.commentFor | translate"
        [ngClass]="{
          'routes-temp-comment__body-form-comment-for_municipality': isMunicipalityCustomer
        }"
      >
        <u-multiselect
          [showSearch]="false"
          [showSelectAll]="false"
          [items]="config.commentApplicableToItems[authCustomerType] || config.commentApplicableToItems.default"
          [messages]="'uMultiselect' | translate"
          [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
          [placeholder]="'general.select' | translate"
          [valid]="form.get('applicableTo').valid"
          [formControl]="form.get('applicableTo')"
        ></u-multiselect>
        <label
          *ngIf="isMunicipalityCustomer"
          class="routes-temp-comment__body-form-label"
        >
          {{config.dictionary.commentWillDisplayedForAll | translate}}
        </label>
      </u-group>
      <u-group
        class="u-group_app-routes-temp-comment"
        [name]="config.dictionary.commentContent | translate"
      >
        <textarea
          uTextarea
          class="routes-temp-comment__body-form-comment"
          [maxLength]="250"
          [formControl]="form.get('commentText')"
          [valid]="!form.get('commentText').invalid"
        ></textarea>
        <label
          class="routes-temp-comment__body-form-label"
          [ngClass]="{ 'routes-temp-comment__body-form-label_red': form.get('commentText').value.length > 249 }"
        >
          {{config.dictionary.max250Chars | translate}}
        </label>
      </u-group>
    </div>
  </div>
</app-general-card>
