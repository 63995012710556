import {
  OnInit,
  EventEmitter,
  inject,
  Output,
  Component,
  DestroyRef,
  HostBinding,
  ChangeDetectionStrategy,
  signal,
  computed
} from '@angular/core';
import { DatePipe, KeyValuePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { take } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { NgxMaskModule } from 'ngx-mask';
import { UButtonModule, UCommonModule, UPopupService, URangePreset } from '@shift/ulib';

import {
  LocalizationService,
  TrackingService,
  LocalizedToastrService,
  HeaderDataService
} from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { IncludesPipe } from '@app/shared/pipes';
import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';
import { AuthCustomerType } from '@app/auth/models';
import { AccompanyCostType, AccompanyPriceType } from '@app/accompanies/models';
import { RoutesPeriodComponent, RoutesPeriodsComponent } from '@app/routes/components';
import { RouteSyncService } from '@app/route-sync/services';
import {
  BulkChangeAction,
  BulkChangeSubject,
  BulkChangeType,
  BulkChangeDetails,
  BulkChangeRoute
} from '@app/bulk-change/models';
import { BulkChangeDataService, BulkChangeService } from '@app/bulk-change/services';
import { bulkChangeConfig } from '@app/bulk-change/configs';
import { bulkChangeComponentConfig } from './bulk-change.component.config';

@Component({
  selector: 'app-bulk-change',
  templateUrl: './bulk-change.component.html',
  styleUrls: [ './bulk-change.component.scss', './bulk-change.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [ BulkChangeDataService, AuthDataSnapshotService ],
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgClass,
    NgForOf,
    TranslateModule,
    DatePipe,
    KeyValuePipe,
    UCommonModule,
    UButtonModule,
    RoutesPeriodComponent,
    RoutesPeriodsComponent,
    IncludesPipe,
    NgxMaskModule
  ]
})
export class BulkChangeComponent implements OnInit {
  @Output() action: EventEmitter<{ action: string; data?: number[]; }> = new EventEmitter();

  @HostBinding('class') hostClasses: string = 'bulk-change';

  private readonly destroyRef = inject(DestroyRef);
  private readonly uPopupService = inject(UPopupService);
  private readonly authDataService = inject(AuthDataService);
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly localizedToastrService = inject(LocalizedToastrService);
  private readonly localizationService = inject(LocalizationService);
  private readonly trackingService = inject(TrackingService);
  private readonly bulkChangeService = inject(BulkChangeService);
  private readonly routeSyncService = inject(RouteSyncService);
  public readonly bulkChangeDataService = inject(BulkChangeDataService);

  private detailsValue: number;

  readonly #config = signal(cloneDeep(bulkChangeComponentConfig));

  readonly config = this.#config.asReadonly();
  readonly maxDaysRange = toSignal(this.routeSyncService.getMaxDaysRange());
  readonly authUserInfo = toSignal(this.authDataService.userInfo$.pipe(take(1)));
  readonly isSCCustomer = computed(() => this.authUserInfo().customer.type === AuthCustomerType.ShuttleCompany);

  isRtl: boolean = this.localizationService.isRtl();
  bulkChangeSubject = BulkChangeSubject;
  routes: BulkChangeRoute[];
  routeIds: number[];
  accompanyCostType = AccompanyCostType;
  accompanyPriceType = AccompanyPriceType;

  get generalForm(): UntypedFormGroup {
    return this.bulkChangeDataService.generalForm as UntypedFormGroup;
  }

  get typeBulkChange(): UntypedFormControl {
    return this.generalForm.get('type') as UntypedFormControl;
  }

  get datesChangesForm(): UntypedFormGroup {
    return this.bulkChangeDataService.datesChangesForm as UntypedFormGroup;
  }

  get datesRange(): UntypedFormControl {
    return this.generalForm.get('datesRange') as UntypedFormControl;
  }

  get periodsForm(): UntypedFormControl {
    return this.bulkChangeDataService.periodsForm as UntypedFormControl;
  }

  get subjectBulkChange(): UntypedFormControl {
    return this.generalForm.get('subject') as UntypedFormControl;
  }

  get actionBulkChange(): UntypedFormControl {
    return this.generalForm.get('changeType') as UntypedFormControl;
  }

  get accompanyCostForm() {
    return this.bulkChangeDataService.accompanyCostForm;
  }

  ngOnInit() {
    this.routeIds = this.routes.map(route => route.routeId);

    if (this.routes.length) {
      this.bulkChangeDataService.setRoutes(this.routeIds);
    }

    this.updateConfig();
    this.setDates();
    this.onTypeBulkChange();
    this.onSubjectBulkChange();
    this.onActionBulkChange();
  }

  private updateConfig() {
    this.#config.update(state => ({
      ...state,
      actions: state.actions.filter(action =>
        (action.feature ? this.authDataService.checkFeature(action.feature) : true) && (action.permission ? this.authDataSnapshotService.checkPermission(action.permission) : true)
      )
    }));
  }

  private onTypeBulkChange() {
    this.typeBulkChange
      .valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.track('click on select change type');

        this.bulkChangeDataService.setDisableDateUpdate(true);
      });
  }

  private onSubjectBulkChange() {
    this.subjectBulkChange
      .valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.config().messageSuffixes.changesSubject[value] &&
        this.track(`select subject ${this.config().messageSuffixes.changesSubject[value]}`);

        const actionTypes = this.config().actionTypes[value] ?? this.config().actionTypes.default;

        if (!actionTypes.some(actionType => actionType.value === this.actionBulkChange.value)) {
          this.actionBulkChange.patchValue(null);
        }
      });
  }

  private onActionBulkChange() {
    this.actionBulkChange
      .valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value =>
        this.config().messageSuffixes.changeType[value] &&
        this.track(`select subject ${this.config().messageSuffixes.changeType[value]}`)
      );
  }

  private setDates() {
    const activeDate = this.headerDataService.getActiveDate();
    const minStartDate = moment.min(this.routes.map(route => moment(route.routeStartDate).startOf('day'))).format(AppConstants.DATE_FORMAT_ISO);
    const maxEndDate = moment.max(this.routes.map(route => moment(route.routeEndDate).startOf('day'))).format(AppConstants.DATE_FORMAT_ISO);

    this.bulkChangeDataService.setRoutesPeriodsDefaultData({
      dates: [ activeDate ],
      dateFrom: minStartDate,
      dateTo: maxEndDate,
      type: this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay,
      availablePresets: bulkChangeConfig.availablePresetsInit,
      checkDaysActive: [ moment(activeDate).get('day') ],
      checkDaysAvailable: bulkChangeConfig.checkDaysAvailable,
      comment: ''
    });

    this.bulkChangeDataService.setDatesChange();
  }

  private getPeriod() {
    return this.datesChangesForm && {
      dateFrom: moment(this.datesChangesForm.value.dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO),
      dateTo: moment(this.datesChangesForm.value.dates[this.datesChangesForm.value.dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO),
      days: this.datesChangesForm.value.checkDaysActive
    };
  }

  private getPeriods() {
    return this.periodsForm && this.periodsForm.value.map(obj => {
      const dates = obj.dates;
      const days = obj.checkDaysActive;
      const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
      const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
      const comment = obj.comment;

      return { days, dateFrom, dateTo, comment };
    });
  }

  private track(message: string) {
    this.trackingService.track(`[${this.config().trackingId}] - ${message}`);
  }

  closeClick() {
    if (this.generalForm.pristine) {
      this.action.emit({ action: BulkChangeAction.Close, data: this.routeIds });

      return;
    }

    this.uPopupService.showMessage(
      {
        showXIcon: true,
        message: this.config().dictionary.closeConfirm,
        yes: this.config().dictionary.yes,
        no: this.config().dictionary.no
      },
      () => (this.generalForm.valid || this.typeBulkChange.disabled) && this.save(),
      () => this.action.emit({ action: BulkChangeAction.Close, data: this.routeIds })
    );
  }

  save() {
    if (this.generalForm.invalid) { return; }

    this.track('click on save');

    const { type, subject } = this.generalForm.value;

    if (subject === this.bulkChangeSubject.SendToSC) {
      this.routeSyncService.saveRouteSync({
        routeIds: this.routeIds,
        dateFrom: this.datesRange.value[0],
        dateTo: this.datesRange.value[1] || this.datesRange.value[0]
      })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.localizedToastrService.success(this.config().dictionary.savedMessage);
          this.action.emit({ action: BulkChangeAction.CloseStart, data: this.routeIds });
        });
    } else {
      const { config } = this.bulkChangeDataService;
      let value = {
        ...(config.multiplePeriods ? { periods: this.getPeriods() } : this.getPeriod()),
        type: !type && this.typeBulkChange.disabled ? BulkChangeType.Unplanned : type,
        ...(this.generalForm.get('details')?.value ? this.generalForm.get('details').value : {})
      };

      if (![ BulkChangeSubject.Cancel, BulkChangeSubject.Restore ].includes(this.subjectBulkChange.value)) {
        value.comment = this.generalForm.value.comment;
      }

      if ([ BulkChangeSubject.AccompanyCost ].includes(this.subjectBulkChange.value)) {
        const { costType, costPerHour, hours } = this.accompanyCostForm.getRawValue();

        value = {
          ...value,
          hours,
          costType,
          costPerHour: costType === AccompanyCostType.ByManualHour ? costPerHour : null,
          totalCost: costType === AccompanyCostType.Fixed ? costPerHour : null
        };
      }

      this.bulkChangeService.saveGenericBulkChange(config.apiBasePath, config.requestType, {
        routeIds: this.generalForm.value.routeIds,
        value
      })
        .subscribe(() => {
          this.localizedToastrService.success(this.config().dictionary.savedMessage);
          this.action.emit({ action: BulkChangeAction.CloseStart, data: this.routeIds });
        });
    }
  }

  bulkChangeDetails(data: BulkChangeDetails) {
    if (data && data.key && data.value.valid && data.value.value !== this.detailsValue) {
      const key = data.key.toLowerCase();

      if (key.includes('accompany')) {
        this.track('select acc');
      }

      if (key.includes('driver')) {
        this.track('select driver');
      }

      if (key.includes('hour')) {
        this.track('select hour');
      }

      this.detailsValue = data.value.value;
    }

    if (data && !data.value.valid) {
      this.detailsValue = null;
    }
  }

  clickOnPeriodPlusIcon() {
    this.track(`${this.subjectBulkChange.value === BulkChangeSubject.Cancel ? 'Cancel' : 'Restore'} - Period - click on plus`);
  }
}
