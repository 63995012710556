import { Injectable, inject } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { RoutesCancelRideComponent } from '@app/routes/components';
import { RouteDailyRow, RouteWeeklyRow, RoutesViewTypeMode } from '@app/routes/models';

@Injectable()
export class RoutesCancelRideModalService {
  private readonly bsModalService = inject(BsModalService);

  openModal(activeRoute: RouteDailyRow | RouteWeeklyRow, viewTypeMode?: RoutesViewTypeMode) {
    return this.bsModalService.show(
      RoutesCancelRideComponent,
      {
        class: 'u-modal u-modal_app-general-card',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          activeRoute,
          viewTypeMode
        }
      }
    );
  }
}
