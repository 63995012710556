import { USelectSItem, USideModalConfig } from '@shift/ulib';

import { CityCombinationsButton, CityCombinationsButtonId, CityCombinationsRuleType, EnvType } from '@app/shared/models';
import { AuthCustomerType } from '@app/auth/models';

const DICTIONARY_PATH: string = 'shared.citiesCombinations';

const dictionary = {
  active: `${DICTIONARY_PATH}.active`,
  inactive: `${DICTIONARY_PATH}.inactive`,
  ruleName: `${DICTIONARY_PATH}.ruleName`,
  with: `${DICTIONARY_PATH}.with`,
  of: `${DICTIONARY_PATH}.of`,
  allowTollRoads: `${DICTIONARY_PATH}.allowTollRoads`,
  addRule: `${DICTIONARY_PATH}.addRule`,
  subtitle: `${DICTIONARY_PATH}.subtitle`,
  deleteRuleConfirmation: `${DICTIONARY_PATH}.deleteRuleConfirmation`,
  explanationText: [
    `${DICTIONARY_PATH}.explanation.text.1`,
    `${DICTIONARY_PATH}.explanation.text.2`,
    `${DICTIONARY_PATH}.explanation.text.3`,
    `${DICTIONARY_PATH}.explanation.text.4`,
    `${DICTIONARY_PATH}.explanation.text.5`,
    `${DICTIONARY_PATH}.explanation.text.6`,
    `${DICTIONARY_PATH}.explanation.text.otherQuestions`
  ],
  noRulesDefined: `${DICTIONARY_PATH}.noRulesDefined`,
  goToSettings: `${DICTIONARY_PATH}.goToSettings`,
  activateAll: `${DICTIONARY_PATH}.activateAll`,
  inactivateAll: `${DICTIONARY_PATH}.inactivateAll`,
  select: 'general.select'
};

const ruleTypes: USelectSItem[] = [
  { value: CityCombinationsRuleType.FixedGroup, name: `${DICTIONARY_PATH}.ruleType.fixedGroup` },
  { value: CityCombinationsRuleType.DontCombine, name: `${DICTIONARY_PATH}.ruleType.dontCombine` },
  { value: CityCombinationsRuleType.Combine, name: `${DICTIONARY_PATH}.ruleType.combine` }
];

const explanationModal: USideModalConfig = {
  button: {
    text: `${DICTIONARY_PATH}.explanation.button`,
    icon: '/assets/images/icons/explanation.svg'
  },
  modal: {
    title: `${DICTIONARY_PATH}.explanation.title`,
    image: {
      path: '/assets/images/cities-combinations/explanation.svg'
    },
    buttons: [
      {
        id: 'Academy',
        text: `${DICTIONARY_PATH}.explanation.academy`,
        icon: '/assets/images/icons/academy.svg',
        url: 'https://intercom.help/ShiftLive/he/articles/6169468-%D7%A9%D7%99%D7%9C%D7%95%D7%91%D7%99-%D7%A2%D7%A8%D7%99%D7%9D'
      },
      {
        id: 'T.V',
        text: `${DICTIONARY_PATH}.explanation.tv`,
        icon: '/assets/images/icons/tv.svg',
        url: 'https://intercom.help/Traffical/he/articles/6166838-%D7%A1%D7%A8%D7%98%D7%95%D7%9F-%D7%98%D7%A1%D7%98'
      }
    ]
  }
};

const explanationModalOrange: USideModalConfig = {
  ...explanationModal,
  modal: {
    ...explanationModal.modal,
    image: {
      path: '/assets/images/themes/orange/cities-combinations/explanation.svg'
    }
  }
};

const explanationModalGreen: USideModalConfig = {
  ...explanationModal,
  modal: {
    ...explanationModal.modal,
    image: {
      path: '/assets/images/themes/green/cities-combinations/explanation.svg'
    }
  }
};

const explanationModalByEnvTypes = {
  default: explanationModal,
  [EnvType.Traffical]: explanationModalOrange,
  [EnvType.IDF]: explanationModalOrange
};

const buttons: CityCombinationsButton[] = [
  {
    id: CityCombinationsButtonId.NewRule,
    withPlus: true,
    title: dictionary.addRule,
    uId: 'cities-combinations-top-button-new-rule',
    medium: true
  },
  {
    id: CityCombinationsButtonId.ActivateAll,
    title: dictionary.activateAll,
    uId: 'cities-combinations-top-button-activate-all'
  },
  {
    id: CityCombinationsButtonId.InactivateAll,
    title: dictionary.inactivateAll,
    uId: 'cities-combinations-top-button-inactivate-all'
  }
];

export const citiesCombinationsComponentConfig = {
  trackingId: 'Settings, Cities combinations',
  explanationModalTrackingId: 'Explanation side modal',
  dictionary,
  ruleTypes,
  buttons,
  explanationModal: {
    default: explanationModalByEnvTypes,
    [AuthCustomerType.ShuttleCompany]: {
      ...explanationModalByEnvTypes,
      [EnvType.Shift]: explanationModalGreen
    }
  }
};
