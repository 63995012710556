const DICTIONARY_PATH: string = 'shared.header';

export const headerComponentConfig = {
  dictionary: {
    search: `${DICTIONARY_PATH}.search`,
    thisWeek: `${DICTIONARY_PATH}.thisWeek`,
    today: `${DICTIONARY_PATH}.today`,
    nextYear: `${DICTIONARY_PATH}.nextYear`,
    nextYearPlan: `${DICTIONARY_PATH}.nextYearPlan`,
    errorCode: 'general.errorCode'
  }
};
