<div class="builder-popup__wrapper" [delayClickOutsideInit]="true" (clickOutside)="cancel()">
  <div class="builder-popup__close" (click)="cancel()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="builder-popup__main">
    <div class="builder-popup__message">
      {{options.message | translate}}
    </div>
    <div class="builder-popup__actions">
      <button *ngIf="options.ok" class="builder-popup__actions-btn" (click)="ok()">{{options.ok | translate}}</button>
      <button *ngIf="options.cancel" class="builder-popup__actions-btn builder-popup__actions-btn_clear" (click)="cancel()">{{options.cancel | translate}}</button>
    </div> 
  </div>
</div>