<div class="builder-add-passenger-to-exist-station__wrapper" [delayClickOutsideInit]="true" (clickOutside)="close()">
  <div class="builder-add-passenger-to-exist-station__close" (click)="close()">
    <div class="u-icon u-icon_pointer u-icon_app-builder-close"></div>
  </div>
  <div class="builder-add-passenger-to-exist-station__main">
    <div class="builder-add-passenger-to-exist-station__title">
      {{config.dictionary.title | translate}} <br> {{passengerName}}
    </div>
    <div class="builder-add-passenger-to-exist-station__list">
      <u-group
        class="u-group_app-builder-add-passenger-to-exist-station builder-add-passenger-to-exist-station__item"
        [name]="config.dictionary.station | translate"
      >
        <u-select-s
          [empty]="false"
          [messages]="config.dictionary.uSelectS | translate"
          [items]="stations"
          [formControl]="form.get('stationId')"
          [valid]="form.get('stationId').valid || form.get('stationId').disabled"
          [placeholder]="config.dictionary.selectStation | translate"
        ></u-select-s>
      </u-group>
      <u-group
        *ngIf="!form.get('assignedDestination').value"
        class="u-group_app-builder-add-passenger-to-exist-station builder-add-passenger-to-exist-station__item"
        [name]="config.dictionary.destination | translate"
      >
        <u-select-s
          [empty]="false"
          [messages]="config.dictionary.uSelectS | translate"
          [items]="destinations"
          [formControl]="form.get('destinationId')"
          [valid]="form.get('destinationId').valid || form.get('destinationId').disabled"
          [placeholder]="config.dictionary.selectDestination | translate"
        ></u-select-s>
      </u-group>
      <div class="builder-add-passenger-to-exist-station__item">
        <div class="builder-add-passenger-to-exist-station__item-checkbox">
          <u-checkbox
            class="u-checkbox_box u-checkbox_app-round-checkbox"
            [formControl]="form.get('assignedDestination')"
          ></u-checkbox>
          <span class="builder-add-passenger-to-exist-station__item-checkbox-label">{{config.dictionary.addPassengerAssignedDestination | translate}}</span>
        </div>
      </div>
    </div>
    <div class="builder-add-passenger-to-exist-station__actions">
      <button 
        uButton 
        class="builder-add-passenger-to-exist-station__actions-btn" 
        [disabled]="!form.valid" 
        (click)="save()"
      >
        {{config.dictionary.save | translate}}
      </button>
    </div> 
  </div>
</div>