import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { exhaustMap, filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UInputCitiesCity } from '@shift/ulib';

import { CitiesService } from '@app/shared/services';
import { transformToInputCities } from '@app/shared/utils';
import { CitiesState } from '@app/shared/models';

@Injectable()
export class CitiesStore extends ComponentStore<CitiesState> {
  constructor(
    private readonly citiesService: CitiesService
  ) {
    super({ cities: null });
  }

  readonly cities = this.selectSignal(state => state.cities);
  readonly cities$ = this.select(state => state.cities);
  readonly getCities$ = this.cities$
    .pipe(
      tap(cities => !cities && this.getCities()),
      filter(cities => !!cities)
    );
  readonly getCitiesBySelectedBranchIds$ = (branchIds: number[]) => this.getCities$
    .pipe(
      map(cities =>
        cities.reduce((acc, city) => {
          const branches = branchIds.length ? city.branches.filter(branch => branchIds.includes(branch.branchId)) : city.branches;

          if (branches?.length) {
            return [ ...acc, { ...city, branches } ];
          }

          return acc;
        }, [])
      )
    );

  readonly getCities = this.effect((params$: Observable<void>) => params$.pipe(
    filter(() => !this.cities()),
    exhaustMap(() =>
      this.citiesService.getFilter()
        .pipe(
          tapResponse(
            data => this.updateCities(transformToInputCities(data)),
            () => null
          )
        )
    )
  ));

  readonly updateCities = this.updater((state, cities: UInputCitiesCity[]) => ({
    cities
  }));

  readonly reset = this.updater(() => ({ cities: null }));
}
