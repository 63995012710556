import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { isEqual } from 'lodash';

import {
  BuilderTuningState,
  BuilderGetShuttleCompaniesDetails,
  BuilderRouteMode
} from '@app/builder/models';
import { BuilderService } from '@app/builder/services';

@Injectable()
export class BuilderTuningStore extends ComponentStore<BuilderTuningState> {
  constructor(
    private readonly builderService: BuilderService
  ) {
    super({ data: null });
  }

  readonly drivers = this.selectSignal(({ data }) => (data?.details?.drivers || []).map(driver => ({ value: driver.id, name: driver.name })));
  readonly carTypes = this.selectSignal(({ data }) => (data?.details?.carTypes || []).map(carType => ({ value: carType.id, name: `${carType.name} (${carType.capacity})` })));
  readonly costTypes = this.selectSignal(({ data }) => (data?.details?.costTypes || []).map(costType => ({ value: costType.id, name: costType.name })));
  readonly hashcalRideTypesItems = this.selectSignal(({ data }) =>
    data?.details?.costTypes.filter(costType => costType.hashcalRideTypes).reduce((acc, item) => ({
      ...acc,
      [item.id]: item.hashcalRideTypes.map(({ id, name }) => ({ value: id, name }))
    }), {})
  );
  readonly carNumbersByCarTypeId = (carTypeId: number) => this.selectSignal(({ data }) => {
    const selectedCarType = data?.details?.carTypes.find(carType => carType.id === carTypeId);

    return data?.details?.carTypes ? data.details.carTypes
      .filter(carType => !selectedCarType || carType.capacity)
      .reduce((acc, carType) =>
        [ ...acc, ...carType.cars.map(car => ({ value: car.id, name: `${car.name} (${carType.name})` })) ], []
      ) : [];
  });

  readonly hashcalRideTypesItems$ = toObservable(this.hashcalRideTypesItems);

  readonly getShuttleCompany = this.effect((params$: Observable<{
    shuttleCompanyId: number;
    contractId: string;
    routeMode: BuilderRouteMode;
  }>) => params$.pipe(
    distinctUntilChanged((prev, curr) => this.state().data && isEqual(prev, curr)),
    switchMap(({ shuttleCompanyId, contractId, routeMode }) =>
      this.builderService.getShuttleCompanies({
        shuttleCompanyRides: [
          {
            shuttleCompanyId,
            contractId
          }
        ],
        routeMode
      })
        .pipe(
          tapResponse(
            ({ shuttleCompaniesDetails }) => this.updateDetails(shuttleCompaniesDetails?.[0]),
            () => null
          )
        )
    )
  ));

  readonly updateDetails = this.updater((state, data: BuilderGetShuttleCompaniesDetails) => ({
    data
  }));

  readonly reset = this.updater(() => ({ data: null }));
}
