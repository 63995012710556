import { DaysOfWeek } from '@app/shared/models';

export enum ActivityType {
  TempComment = 'TempComment',
  RouteBulkChange = 'RouteBulkChange',
  RouteBulkSaveFail = 'RouteBulkSaveFail',
  RouteLocked = 'RouteLocked',
  RouteUnlocked = 'RouteUnlocked',
  RouteAuditCompleted = 'RouteAuditCompleted',
  RouteSaveSuccess = 'RouteSaveSuccess',
  RouteSaveFail = 'RouteSaveFail',
  TemplateFromRoute = 'TemplateFromRoute',
  RoutesFromTemplates = 'RoutesFromTemplates',
  RouteRestored = 'RouteRestored',
  RouteCancelled = 'RouteCancelled',
  RouteNewYearDuplicated = 'RouteNewYearDuplicated',
  TemplateNewYearDuplicated = 'TemplateNewYearDuplicated',
  RoutePassengerUpdated = 'RoutePassengerUpdated',
  RoutePassengerRemoved = 'RoutePassengerRemoved',
  ReOptimization = 'ReOptimization',
  ManualScRoutesSync = 'ManualScRoutesSync'
}

export enum ActivityFiltersType {
  TempComment = 'TempComment',
  BulkChanges = 'BulkChanges',
  ChangeToRoute = 'ChangeToRoute',
  TemplateFromRoute = 'TemplateFromRoute',
  RoutesFromTemplates = 'RoutesFromTemplates',
  NewYear = 'NewYear',
  PassengerRemoval = 'PassengerRemoval',
  PassengerUpdate = 'PassengerUpdate',
  ReOptimization = 'ReOptimization',
  ReOptimizationCleanup = 'ReOptimizationCleanup',
  ManualScRoutesSync = 'ManualScRoutesSync'
}

export enum ActivityAdditionalDataReOptimizationType {
  ReOptimization = 'ReOptimization',
  Cleanup = 'Cleanup'
}

export enum ActivityFiltersStatusType {
  Active = 'active',
  Archived = 'archived'
}

export enum ActivityUpdateStatus {
  New,
  Update,
  Delete,
  Read
}

export enum ActivityAdditionalStatus {
  InProgress = 'InProgress',
  Success = 'Success',
  Failed = 'Failed',
  PartialSuccess = 'PartialSuccess',
  NoRidesAvailable = 'NoRidesAvailable',
  Started = 'Started',
  Finished = 'Finished',
  AnotherSessionAlreadyInProgress = 'AnotherSessionAlreadyInProgress'
}

export enum ActivityBulkChangeSubject {
  RideCancel = 'RideCancel',
  RideRestore = 'RideRestore',
  AccompanySet = 'AccompanySet',
  AccompanyRemove = 'AccompanyRemove',
  AccompanySwap = 'AccompanySwap',
  AssignedAccompanyCost = 'AssignedAccompanyCost',
  DriverChange = 'DriverChange',
  DriverRemove = 'DriverRemove',
  DriverSwap = 'DriverSwap',
  TimeChange = 'TimeChange',
  PassengerMoveToNewStation = 'PassengerMoveToNewStation',
  PassengerMoveToNewTarget = 'PassengerMoveToNewTarget',
  PassengerRemove = 'PassengerRemove',
  BranchNameChanged = 'BranchNameChanged',
  BranchAddressChanged = 'BranchAddressChanged',
  YitChange = 'YitChange',
  PassengerUpdate = 'PassengerUpdate'
}

export enum ActivityPassengerChangesAffectRoutesType {
  AddressChanged = 'AddressChanged',
  StationChanged = 'StationChanged',
  SchoolChanged = 'SchoolChanged',
  BranchChanged = 'BranchChanged',
  PickUpTypeChanged = 'PickUpTypeChanged',
  ClassTypeIdChanged = 'ClassTypeIdChanged',
  ClassNumberChanged = 'ClassNumberChanged',
  ScheduleHoursChanged = 'ScheduleHoursChanged',
  TravelingAloneChanged = 'TravelingAloneChanged',
  NeedElevatorChanged = 'NeedElevatorChanged',
  SchoolRemoved = 'SchoolRemoved',
  BranchRemoved = 'BranchRemoved'
}

export enum ActivityRouteFromTemplateCreationResult {
  Success = 'Success',
  TemplateIsLocked = 'TemplateIsLocked',
  Exception = 'Exception',
  NoDaysAvailable = 'NoDaysAvailable',
  RemoveDriversFailed = 'RemoveDriversFailed',
  ResetAccompaniesFailed = 'ResetAccompaniesFailed',
  SaveFailed = 'SaveFailed',
  CalculationFailed = 'CalculationFailed',
  RemoveShuttleCompanyFailed = 'RemoveShuttleCompanyFailed',
  ShuttleCompanyContractDoesntMatchSelectedPeriod = 'ShuttleCompanyContractDoesntMatchSelectedPeriod',
  ShuttleCompanySetCarTypeIsFailed = 'ShuttleCompanySetCarTypeIsFailed',
  ShuttleCompanyHasMoreThanSingleContractForSelectedPeriod = 'ShuttleCompanyHasMoreThanSingleContractForSelectedPeriod'
}

export interface ActivityPassengerChange {
  type: ActivityPassengerChangesAffectRoutesType;
  doesAffectRoutes: boolean;
}

export class ActivityBase {
  routeName: string;
  routeCode: string;
  type: ActivityType;
  additionalData: {
    type?: string | ActivityAdditionalDataReOptimizationType;
    shuttleCompaniesEmailKeys?: {
      emailCacheKey: string;
      shuttleCompanyId: number;
      shuttleCompanyName: string;
    }[];
    comment?: string;
    syncSystem?: string;
    syncedRoutesCount?: number;
    failedRoutesCount?: number;
    fileCacheKey?: string;
    changeSubject?: ActivityBulkChangeSubject;
    routesCount?: number;
    changeStatus?: ActivityAdditionalStatus;
    status?: ActivityAdditionalStatus;
    processedTemplates?: {
      creationResults: {
        date: string;
        result: ActivityRouteFromTemplateCreationResult;
      }[];
      templateCode: string;
      templateName: string;
    }[];
    errorDescriptions?: string[];
    periodStart?: string;
    periodEnd?: string;
    days?: DaysOfWeek[];
    isAnyPassengersRemoved?: boolean;
    passengerName?: string;
    isRouteUpdateFailed?: boolean;
    changes?: ActivityPassengerChange[];
    failedPeriods?: {
      dateFrom: string;
      dateTo: string;
      days: DaysOfWeek[];
    }[];
  };
  createdBy: string;
  createdDateTime: string;
  activityId: number;
  routeId: number;
  startDateTime: string;
  endDateTime: string;
  isArchived: boolean;
  customerId: number;
  isOwnedBySc: boolean;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export class Activity extends ActivityBase {
  expanded: boolean;
}

export interface ActivitiesFilters {
  statusTypes: ActivityFiltersStatusType[];
  types: ActivityFiltersType[];
  saveTypes: ActivityAdditionalStatus[];
}

export interface ActivitiesAllParams {
  routeId?: number;
  startDate: string;
  endDate: string;
}

export interface ActivitiesRouteDailyParams {
  date: string;
  routeIds: number[];
}

export interface ActivityRoute {
  routeId: number;
  activities: ActivityLive[];
}

export interface ActivitiesLiveParams {
  startDate: string;
  endDate: string;
}

export interface ActivityLive {
  activityId: number;
  isRead: boolean;
}

export interface ActivityUpdate {
  memberId: number;
  activityId: number;
  routeId: number;
  startDate: string;
  endDate: string;
  status: ActivityUpdateStatus;
  type: ActivityType;
}

export interface ActivitiesShuttleCompanyChanges {
  changesCount: number;
  attachment: {
    id: string;
    name: string;
  };
  content: string;
  subject: string;
  receivers: string[];
}
