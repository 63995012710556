import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthDataService } from '@app/auth/services';
import { HeaderMenuIcon, HeaderMenuIconChild, HeaderMenuIconsTemplate, HeaderMenuIconValue } from '@app/shared/models';
import { CommonService } from '@app/shared/services/common.service';
import { headerMenuIconsConfig } from '@app/shared/configs';
import { ActivitiesDataService } from '@app/activities/services';

@Injectable({
  providedIn: 'root'
})
export class HeaderMenuIconsService {
  private readonly authDataService = inject(AuthDataService);
  private readonly commonService = inject(CommonService);
  private readonly activitiesDataService = inject(ActivitiesDataService);

  private showMenu: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private menuIcons: BehaviorSubject<HeaderMenuIcon[]> = new BehaviorSubject([]);
  private menuIconClick: Subject<HeaderMenuIcon | HeaderMenuIconChild> = new Subject();

  showMenu$ = this.showMenu.asObservable();
  menuIcons$ = combineLatest([
    this.commonService.visibleComponents$,
    this.menuIcons.asObservable(),
    this.activitiesDataService.activitiesLiveCounter$
  ])
    .pipe(
      map(([ visibleComponents, menuIcons, activitiesLiveCounter ]) => menuIcons.map(menuIcon => ({
        ...menuIcon,
        ...(visibleComponents.hasOwnProperty(menuIcon.value) && menuIcon.activateOnClick ? { selected: visibleComponents[menuIcon.value] } : {}),
        ...(menuIcon.value === HeaderMenuIconValue.Activities ? { counter: activitiesLiveCounter } : {})
      })))
    );
  mainMenuIcons$ = this.menuIcons$.pipe(
    map(items => items.filter(item => headerMenuIconsConfig.mainMenuIconsIds.includes(item.id))),
    distinctUntilChanged()
  );
  actionMenuIcons$ = this.menuIcons$.pipe(
    map(items => items.filter(item => !headerMenuIconsConfig.mainMenuIconsIds.includes(item.id))),
    distinctUntilChanged()
  );
  menuIconClick$ = this.menuIconClick.asObservable();

  setMenuIconsByTemplate(template: HeaderMenuIconsTemplate) {
    const userInfo = this.authDataService.userInfo();
    const templatesByCustomerType = headerMenuIconsConfig.templates[userInfo.customer.type] || headerMenuIconsConfig.templates.default;
    const templates = templatesByCustomerType[environment.config.environmentType] || templatesByCustomerType.default;

    if (templates?.[template]) {
      this.menuIcons.next(
        templates[template]
          .filter(icon =>
            (
              icon.feature ? this.authDataService.checkFeature(icon.feature) :
                icon.features ? this.authDataService.checkFeatures(icon.features) :
                  !icon.featureGroup || this.authDataService.checkFeatureGroup(icon.featureGroup)
            ) &&
            (
              icon.permission ? this.authDataService.checkPermission(icon.permission) :
                !icon.permissionGroup || this.authDataService.checkPermissionGroup(icon.permissionGroup)
            )
          )
          .map((icon: HeaderMenuIcon) => {
            const children = icon.children?.filter((child: HeaderMenuIconChild) =>
              (
                child.feature ? this.authDataService.checkFeature(child.feature) :
                  child.features ? this.authDataService.checkFeatures(child.features) :
                    !child.featureGroup || this.authDataService.checkFeatureGroup(child.featureGroup)
              ) &&
              (
                child.permission ? this.authDataService.checkPermission(child.permission) :
                  !child.permissionGroup || this.authDataService.checkPermissionGroup(child.permissionGroup)
              )
            ) || [];

            return icon.children ? {
              ...icon,
              ...(icon.replaceByOneChild && children?.length === 1 ? { name: children[0].name, value: children[0].value, children: null } : { children })
            } : icon;
          })
      );
    }
  }

  resetIcons() {
    this.menuIcons.next([]);
  }

  selectIcon(icon: HeaderMenuIcon | HeaderMenuIconChild, selectIcon: boolean) {
    this.menuIconClick.next(icon);

    const isIcon = (icon as HeaderMenuIcon).urls;

    if (isIcon && selectIcon && (icon as HeaderMenuIcon).activateOnClick) {
      this.menuIcons.next(
        this.menuIcons.value.map(menuIcon => menuIcon.value === icon.value ? {
          ...menuIcon,
          selected: !menuIcon.selected
        } : menuIcon)
      );
    }
  }

  unselectIcon(icon: HeaderMenuIcon) {
    this.menuIcons.next(
      this.menuIcons.value.map(menuIcon => icon.value === menuIcon.value ? {
        ...menuIcon,
        selected: false
      } : menuIcon)
    );
  }

  updateShowMenu(value: boolean) {
    this.showMenu.next(value);
  }
}
