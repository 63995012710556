import { computed, inject, Injectable, signal } from '@angular/core';
import { UGridService } from '@shift/ulib';

import { patchSignal } from '@app/shared/utils';
import { RouteDirection } from '@app/routes/models';
import { builderRoutesConfig } from '@app/builder/configs';
import { BuilderRoute, BuilderRoutesState, BuilderRoutesStateGridProps, BuilderRouteStatus } from '@app/builder/models';
import { RoutePlannerSummaryRoute } from '@app/route-planner/models';

@Injectable({
  providedIn: 'root'
})
export class BuilderRoutesStoreService {
  private readonly uGridService = inject(UGridService);

  readonly #state = signal<BuilderRoutesState>(builderRoutesConfig.initialState);

  readonly state = this.#state.asReadonly();
  readonly showBackdrop = computed(() => this.state().showBackdrop);
  readonly routesFullChanged = computed(() => this.state().routesFullChanged);
  readonly grid = computed(() => this.state().grid);
  readonly gridColumns = computed(() => this.grid().columns);
  readonly gridRowClassObjects = computed(() => this.grid().rowClassObjects);
  readonly gridMessages = computed(() => this.grid().messages);
  readonly gridProps = computed(() => this.grid().props);
  readonly activeRouteId = computed(() => this.state().activeRoute.id);
  readonly routes = computed(() => this.state().routes);
  readonly unsavedRoutes = computed(() => this.routes().filter(route => route.status !== BuilderRouteStatus.Saved));
  readonly activeRoute = computed(() => this.routes().find(route => route.routeId === this.activeRouteId()));
  readonly search = computed(() => this.state().search);
  readonly searchedRoutes = computed(() => this.search() ?
    this.routes().filter(route => Object.values(route).some(value => value?.toString().includes(this.search()))) :
    this.routes()
  );
  readonly routesCounter = computed(() =>
    this.searchedRoutes()
      .reduce((acc, route) =>
        ({
          ...acc,
          forward: route.direction === RouteDirection.Forward ? acc.forward + 1 : acc.forward,
          backward: route.direction === RouteDirection.Backward ? acc.backward + 1 : acc.backward
        }),
      { all: this.searchedRoutes().length, forward: 0, backward: 0 }
      )
  );

  private getUpdatedRoutes(routes: BuilderRoute[], updatedRoutes: BuilderRoute[]): BuilderRoute[] {
    return routes.map(route => {
      const routeUpdated = updatedRoutes.find(updatedRoute => updatedRoute.routeId === route.routeId);

      if (routeUpdated) {
        return {
          ...route,
          ...routeUpdated
        };
      }

      return route;
    });
  }

  private getUpdatedRouteIds(routes: BuilderRoute[]): BuilderRoute[] {
    const routeId = -1;
    let idx = -1;

    return routes.map(route => route.routeId <= routeId ? {
      ...route,
      routeId: --idx
    } : route);
  }

  routesSet(routes: BuilderRoute[]) {
    patchSignal(this.#state, { routes });
  }

  routesRestore() {
    const state = this.state();

    patchSignal(this.#state, {
      routes: state.routes.map(route => route.routeId === state.activeRoute.id ? {
        ...route,
        ...state.activeRoute.initialState
      } : route)
    });
  }

  routesAdd(route: BuilderRoute, updateNewRouteIds: boolean) {
    if (updateNewRouteIds) {
      patchSignal(this.#state, { routes: this.getUpdatedRouteIds(this.state().routes) });
    }

    patchSignal(this.#state, { routes: [ route, ...this.state().routes ] });
  }

  updateRoutes(routes: BuilderRoute[]) {
    patchSignal(this.#state, { routes: this.getUpdatedRoutes(this.state().routes, routes) });
  }

  searchRoutes(search: string) {
    patchSignal(this.#state, { search });
  }

  removeRoute(id: number) {
    patchSignal(this.#state, { routes: this.state().routes.filter(route => route.routeId !== id) });
  }

  routeActiveSet(id: number) {
    patchSignal(this.#state, {
      activeRoute: {
        id,
        initialState: this.state().routes.find(route => route.routeId === id)
      }
    });
  }

  removeExistedRoutes() {
    patchSignal(this.#state, { routes: this.state().routes.filter(route => route.routeId < 0) });
  }

  routeActiveUpdate(data: any) {
    const state = this.state();

    patchSignal(this.#state, {
      routes: state.routes.map(route =>
        route.routeId === state.activeRoute.id ? { ...route, ...data } : route
      )
    });
  }

  updateRoute(routeId: number, data: Partial<BuilderRoute>) {
    patchSignal(this.#state, {
      routes: this.state().routes.map(route =>
        route.routeId === routeId ? { ...route, ...data } : route
      )
    });
  }

  addRouteFromSummary(data: RoutePlannerSummaryRoute) {
    this.routesAdd(
      {
        status: null,
        routeId: data.routeId,
        code: data.number,
        name: data.name,
        direction: data.direction,
        days: data.activeDays,
        startDate: data.startDate,
        endDate: data.endDate,
        rideStartDateTime: data.startTime,
        rideEndDateTime: data.endTime,
        totalPassengers: data.totalPassengers,
        carTypeName: data.carType ? data.carType.name : null,
        carTypeCapacity: data.carType ? data.carType.seatsCount : null,
        shuttleCompany: data.shuttleCompany ? data.shuttleCompany.name : null,
        timeType: data.timeType,
        locked: false,
        branchNames: data.branchNames
      },
      false
    );
  }

  updateGridProps(data: Partial<BuilderRoutesStateGridProps>) {
    const state = this.state();

    patchSignal(this.#state, {
      grid: {
        ...state.grid,
        props: {
          ...state.grid.props,
          ...data
        }
      }
    });
  }

  columnsFilteredChange(filtered: boolean) {
    const state = this.state();

    patchSignal(this.#state, {
      grid: {
        ...state.grid,
        columns: {
          ...state.grid.columns,
          filtered
        }
      }
    });
  }

  resetColumnsFilterChange(resetFilter: boolean) {
    const state = this.state();

    patchSignal(this.#state, {
      grid: {
        ...state.grid,
        columns: {
          ...state.grid.columns,
          resetFilter
        }
      }
    });
  }

  resetTableColumnsFilters() {
    const resetStorageProps = {
      propName: 'columnsFiltersStore',
      tableName: builderRoutesConfig.tableName,
      userId: this.uGridService.getUserIdFromLocalStorage(),
      saveTableProps: true,
      value: []
    };

    this.uGridService.saveTablePropToLocalStorage(resetStorageProps);
  }

  highlightRoute(value: number) {
    const state = this.state();

    patchSignal(this.#state, {
      grid: {
        ...state.grid,
        rowClassObjects: [ {
          className: 'highlighted-row',
          rowPropertyName: 'routeId',
          value
        } ]
      }
    });
  }

  setMessagesTable(messages: string) {
    patchSignal(this.#state, {
      grid: {
        ...this.state().grid,
        messages
      }
    });
  }

  resetMessagesTable() {
    this.setMessagesTable(builderRoutesConfig.defaultMessagesTable);
  }

  updateNotSavedRoutesAsPending() {
    patchSignal(this.#state, {
      routes: this.state().routes.map((route: BuilderRoute) =>
        ({ ...route, status: route.status !== BuilderRouteStatus.Saved ? BuilderRouteStatus.Pending : route.status })
      )
    });
  }

  updateRouteStatus(id: number, status: number) {
    patchSignal(this.#state, {
      routes: this.state().routes.map(ob => ob.routeId === id ? { ...ob, status } : ob)
    });
  }

  updateRouteErrorMessage(id: number, errorMessage: string) {
    patchSignal(this.#state, {
      routes: this.state().routes.map(route => route.routeId === id ? { ...route, errorMessage } : route)
    });
  }

  clearRoutesErrorMessage() {
    patchSignal(this.#state, {
      routes: this.state().routes.map(route => route.errorMessage ? { ...route, errorMessage: null } : route)
    });
  }

  setRoutesFullChanged(routesFullChanged: boolean) {
    patchSignal(this.#state, { routesFullChanged });
  }

  updateShowBackdrop(showBackdrop: boolean) {
    patchSignal(this.#state, { showBackdrop });
  }
}
