import { Component, OnInit, OnDestroy, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { URangePreset } from '@shift/ulib';

import {
  TrackingService,
  HeaderDataService
} from '@app/shared/services';
import {
  GeneralCardButtonValue
} from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { RouteShuttleCompaniesService } from '@app/route-shuttle-companies/services';
import {
  RoutesCancellationBillingType,
  RoutesCancellationChargeType,
  RoutesCancellationPolicesParamsPeriod,
  RoutesCancellationPolicy,
  RoutesChangeEmailSendType,
  RoutesViewTypeMode,
  RoutesPeriodsDefaultData,
  RoutesCancelRideClauseType,
  RoutesChangeCancelRideParams
} from '@app/routes/models';
import { RoutesTableService } from '@app/routes/services';
import { AuthDataService } from '@app/auth/services';
import { AuthCustomer, AuthCustomerType } from '@app/auth/models';
import { routesCancelRideComponentConfig } from './routes-cancel-ride.component.config';

@Component({
  selector: 'app-routes-cancel-ride',
  templateUrl: './routes-cancel-ride.component.html',
  styleUrls: [ './routes-cancel-ride.component.scss', './routes-cancel-ride.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesCancelRideComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses: string = 'routes-cancel-ride';

  private cancellationPolicies: RoutesCancellationPolicy[] = [];
  private unsubscribe: Subject<void> = new Subject();

  form: UntypedFormGroup;
  routesChangeEmailSendType = RoutesChangeEmailSendType;
  routesCancelRideClauseType = RoutesCancelRideClauseType;
  config = cloneDeep(routesCancelRideComponentConfig);
  activeRoute: any;
  activeDays: string[];
  viewTypeMode: RoutesViewTypeMode.DailyView | RoutesViewTypeMode.WeeklyView = RoutesViewTypeMode.DailyView;
  authCustomer: AuthCustomer;
  isSCCustomer: boolean;
  routesPeriodsDefaultData: RoutesPeriodsDefaultData;

  constructor(
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private routesTableService: RoutesTableService,
    private routeShuttleCompaniesService: RouteShuttleCompaniesService,
    private trackingService: TrackingService,
    private headerDataService: HeaderDataService,
    private authDataService: AuthDataService
  ) {}

  ngOnInit() {
    this.setRoutesPeriodsDefaultData();
    this.createForm();
    this.initAuthCustomer();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getRouteShuttleCompaniesDates() {
    if (this.isSCCustomer) {
      this.routeShuttleCompaniesService.getRouteShuttleCompaniesDates('id' in this.activeRoute ? this.activeRoute.id : this.activeRoute.routeId)
        .pipe(
          take(1),
          takeUntil(this.unsubscribe)
        )
        .subscribe(data => this.activeDays = data.dates);
    }
  }

  private initAuthCustomer() {
    this.authDataService.customer$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(authCustomer => {
        this.authCustomer = authCustomer;
        this.isSCCustomer = authCustomer.type === AuthCustomerType.ShuttleCompany;

        this.getRouteShuttleCompaniesDates();
      });
  }

  private fetchCancellationPolices(periods: RoutesCancellationPolicesParamsPeriod[]) {
    this.routesTableService.getCancellationPolices({
      routeId: this.activeRoute && this.activeRoute.routeId,
      rideStartDateTime: this.activeRoute && this.activeRoute.rideStartDateTime,
      periods
    })
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(({ cancellationPolicies, defaultPolicyId }) => {
        let cancelClauseList = [ ...routesCancelRideComponentConfig.cancelClauseList ];

        if (cancellationPolicies && !cancellationPolicies.length) {
          cancelClauseList =  [ ...cancelClauseList, routesCancelRideComponentConfig.cancelClauseAccordingToContract ];
        }

        this.config.cancelClauseList = [
          ...cancelClauseList,
          ...cancellationPolicies.map(cancellationPolicy => ({ value: cancellationPolicy.policyId , name: cancellationPolicy.name }))
        ];

        this.cancellationPolicies = cancellationPolicies;

        if (defaultPolicyId) {
          this.form.get('cancelClause').patchValue(defaultPolicyId);
        }

        this.cdRef.markForCheck();
      });
  }

  private onPeriodsChange() {
    this.form.get('periods').valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe(periods => {
        this.fetchCancellationPolices(
          periods.map(period => {
            const dates = period.dates;
            const days = period.checkDaysActive;
            const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
            const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

            return { days, dateFrom, dateTo };
          })
        );
      });
  }

  private onSelectBillingType() {
    this.form.get('cancelClause').valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe(newVal => {
        this.track(`Cancelation clause, select ${{
          [RoutesCancelRideClauseType.NoCharge]: 'no charge',
          [RoutesCancelRideClauseType.Manual]: 'manual',
          [RoutesCancelRideClauseType.AccordingToContract]: 'according to contract'
        }[newVal] || 'a clause from list'}`);

        if (newVal === RoutesCancelRideClauseType.Manual) {
          this.form.patchValue({
            chargeAmount: 0,
            chargeType: RoutesCancellationChargeType.Percentage
          });

          this.form.get('chargeType').enable();
          this.form.get('chargeAmount').enable();
        } else {
          const selectedClause = this.cancellationPolicies.find(clause => clause.policyId === newVal);

          if (selectedClause) {
            this.form.patchValue({
              chargeAmount: selectedClause.amount,
              chargeType: selectedClause.chargeType
            });

            this.form.get('chargeType').disable();
            this.form.get('chargeAmount').disable();
          }
        }
      });
  }

  setRoutesPeriodsDefaultData() {
    const { routeStartDate, routeEndDate, days, endDate, startDate } = this.activeRoute;
    const activeDate = this.headerDataService.getActiveDate();

    this.routesPeriodsDefaultData = {
      dates: [ activeDate ],
      dateFrom: this.viewTypeMode === RoutesViewTypeMode.DailyView ? routeStartDate : startDate,
      dateTo: this.viewTypeMode === RoutesViewTypeMode.DailyView ? routeEndDate : endDate,
      type: this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay,
      availablePresets: this.config.availablePresets,
      checkDaysActive: [ moment(activeDate).get('day') ],
      checkDaysAvailable: days,
      comment: ''
    };
  }

  createForm() {
    this.form = this.fb.group({
      routeId: [ 0 ],
      periods: [ [] ],
      type: [ RoutesCancellationBillingType.Manual, Validators.required ],
      cancelClause: [ RoutesCancelRideClauseType.NoCharge, Validators.required ],
      chargeAmount: [ 0, Validators.required ],
      chargeType: [ RoutesCancellationChargeType.NoCharge, Validators.required ]
    });

    this.onPeriodsChange();
    this.onSelectBillingType();

    if (this.activeRoute) {
      const routeIdForm = this.form.get('routeId');

      if (this.activeRoute.routeId) {
        routeIdForm.patchValue(this.activeRoute.routeId);
      } else if (this.activeRoute.id) {
        routeIdForm.patchValue(this.activeRoute.id);
      }
    }
  }

  saveChanges(emailSendType?: RoutesChangeEmailSendType) {
    const { routeId, type, cancelClause, chargeAmount, chargeType, periods } = this.form.getRawValue();
    const activeDate = moment(this.headerDataService.getDate()).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);

    const body: RoutesChangeCancelRideParams = {
      routeId,
      activeDate,
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        type,
        periods: periods.map(period => {
          const dates = period.dates;
          const comment = period.comment;
          const days = period.checkDaysActive;
          const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
          const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

          return { days, dateFrom, dateTo, comment };
        }),
        cancellationBillingData: {
          billingType: this.config.billingTypeByCancelClause[cancelClause] >= 0 ? this.config.billingTypeByCancelClause[cancelClause] : RoutesCancellationBillingType.CancellationClause,
          cancellationClauseId: cancelClause > 0 ? cancelClause : null,
          chargeType,
          chargeAmount
        }
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    this.routesTableService.changeCancelRide(body)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.trackingService.track('[Track daily view change] - route was canceled');

        this.close();
      });
  }

  track(message: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${message}`);
  }

  close() {
    this.bsModalRef.hide();
  }

  onButtonClick(value: GeneralCardButtonValue) {
    const params = {
      [GeneralCardButtonValue.SaveAndClose]: null,
      [GeneralCardButtonValue.SaveAndEditEmail]: RoutesChangeEmailSendType.GenerateEditableEmail,
      [GeneralCardButtonValue.SaveAndSendSC]: RoutesChangeEmailSendType.SendBackgroundEmail
    };

    this.saveChanges(params[value]);
  }
}
