import { Injectable } from '@angular/core';

import { demandsConfig } from '@app/demands/configs';
import { DemandsView } from '@app/demands/models';

@Injectable({
  providedIn: 'root'
})
export class DemandsStorageService {
  getDemands(): { view: DemandsView; } {
    return JSON.parse(localStorage.getItem(demandsConfig.storageKey));
  }

  updateDemands(data: { view?: DemandsView; }) {
    localStorage.setItem(demandsConfig.storageKey, JSON.stringify({
      ...this.getDemands(),
      ...data
    }));
  }

  getView() {
    return this.getDemands()?.view;
  }

  updateView(view: DemandsView) {
    this.updateDemands({ view });
  }
}
