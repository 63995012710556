 <div
  appWindowResize
  class="route-daily-table__main"
  (windowResize)="windowResize($event)"
  #routesDailyTable
>
  <div class="temp-changes-popup-backdrop" *ngIf="showBackdrop"></div>
  <u-grid
    #grid
    class="u-grid_app-routes-daily"
    [rowWrapperTemplate]="rowWrapper"
    [tableName]="tableName"
    [saveTableProps]="true"
    [saveColumnsFilters]="true"
    [resetColumnsFilter]="resetColumnsFilter"
    [columns]="columns"
    [rows]="routes"
    [rowClassObjects]="specificRowClassObjects"
    [columnMode]="'force'"
    [headerHeight]="45"
    [headerOffset]="10"
    [footerHeight]="0"
    [rowHeight]="55"
    [reorderable]="true"
    [scrollbarV]="true"
    [scrollbarH]="true"
    [isRtl]="isRtl"
    [lang]="lang"
    [editMode]="'cell'"
    [selected]="selectedRows"
    [selectionType]="'checkbox'"
    [showColumnsFilter]="true"
    [messages]="'uGrid' | translate"
    [disabledRowProp]="'locked'"
    [scrollToRowIndex]="scrollToRowIndex"
    [rowIdentity]="rowIdentity"
    (sort)="onSortTable($event)"
    (scrollTable)="onScrollTable($event)"
    (selectRows)="selectRoutes($event)"
    (filterRows)="onFilterRows($event)"
    (editValuesClose)="onEditValuesClose($event)"
    (resetColumnsFilterChange)="resetColumnsFilterChange($event)"
    (columnsFilteredChange)="columnsFilteredChange($event)"
    (visibleColumns)="updateVisibleColumns($event)"
    (mouseleave)="removeSpecificRowClassObjectsByClassName([ gridConfig.rowValidityClasses.valid, gridConfig.rowValidityClasses.invalid ])"
  ></u-grid>
</div>

<div
  *ngIf="commonService.showMonitoringDashboard() === false && showKpi"
  class="route-daily-table__kpi"
  [ngClass]="{ 'route-daily-table__kpi_side-menu-collapsed': uSidebarMenuService.collapsed$ | async }"
>
  <app-kpi
    [kpi]="kpi()"
    (collapseToggle)="onKpiCollapseToggle($event)"
  ></app-kpi>
</div>

<ng-template #routeMonitoringStatus ngx-datatable-cell-template let-row="row">
  <div
    class="ride-status-container"
    [ngClass]="{'ride-status-monitoring': (row.rideStatus === 'FinishedMonitored' || row.rideStatus === 'OngoingMonitored')}">
    <span
      class="ride-status"
      (click)="openDashboard(row)"
      [ngClass]="row.cancelled ? 'canceled-route' : (row.active ? defineRideMonitoringStatus(row, true) : 'ride-status-not-active-not-clickable')"
    >
      <span class="ride-status__text">
        {{
          row.cancelled ? statusCanceled :
          (
            row.active && !row.isStartTimePast ? row.monitoringStatus :
            row.isStartTimePast && row.rideStatus ? routeStatuses.finished : statusNotActive
          )
        }}
      </span>
      <span
        class="ride-status-antena"
        [ngClass]="row.rideStatus === 'FinishedMonitored'
          || row.rideStatus === 'OngoingMonitored' ? 'ride-status-antena_green' : 'ride-status-antena_gray'"
        *ngIf="!row.cancelled && row.active && (row.rideStatus === 'Finished' || row.rideStatus === 'Ongoing'
          || row.rideStatus === 'FinishedMonitored' || row.rideStatus === 'OngoingMonitored')"
      ></span>

      <app-timer
        *ngIf="row.rideStatus === 'New' && !row.cancelled && row.active && !row.isStartTimePast"
        [isToday]="isToday"
        [daysToRoute]="daysToRoute"
        [startTime]="row.routeStartTime"
        [timerFormat]="timerFormatFutureRoute"
      ></app-timer>
      <span
        class="route-got-to-destination"
        *ngIf="!row.cancelled && row.active && row.rideStatus === 'FinishedMonitored'"
      >
        <span
          *ngIf="row.stationEntranceStatus && row.stationEntranceStatus.stationEntrance === stationEntranceEnum.never"
          class="station-entrance_no-entrance-to-pick-up-area"
        >
          {{entranceToPickUpArea.never}}
        </span>
        <span
          class="route-got-to-destination-time"
          *ngIf="row.actualArriveDateTime"
        >
          <span class="route-got-to-destination-time__value">
            {{
              row.gapInMinutes > 0 ?
              (row.direction === direction.Backward ? gotToDestinationArrivedLate : gotToDestinationWithLate) :
              (row.direction === direction.Forward ? gotToDestinationOnTime : arrivedAt)}} - {{row.actualArriveDateTime | date: appConstants.TIME_FORMAT
            }}
          </span>
          <span
            *ngIf="row.gapInMinutes > 0"
            class="route-got-to-destination-time__gap"
          >
            (<span class="route-got-to-destination-time__gap-value">+{{row.gapInMinutes}}</span>{{'general.minutes' | translate}})
          </span>
        </span>
      </span>
      <span
        class="station-entrance"
        *ngIf="row.stationEntranceStatus && row.rideStatus === 'OngoingMonitored'
          && (row.stationEntranceStatus.stationEntrance === stationEntranceEnum.never
          || row.stationEntranceStatus.stationEntrance === stationEntranceEnum.afterExpectedTime)"
        [ngClass]="row.stationEntranceStatus.stationEntrance === stationEntranceEnum.never ?
          'station-entrance_no-entrance-to-pick-up-area' : 'station-entrance_entrance-after-expected-time'"
      >
        {{handleEntranceToPickUpAreas(row.stationEntranceStatus)}}
      </span>
    </span>
    <div class="ride-status__actions-wrapper">
      <div
        class="ride-status__actions"
        *ngIf="!row.hideButtons && !row.rideCanceled"
      >
        <button
          *ngIf="!!routeBuilderFeatureType && !row.locked && !isMovePassengers()"
          id="route-edit-{{row.routeId}}"
          type="button"
          class="route-daily-table-edit-button"
          [ngClass]="{
            'route-daily-table-edit-button_hidden': !row.active && !row.cancelled,
            'route-daily-table-edit-button_canceled-status': row.cancelled
          }"
          (click)="editRoute(row)"
      >
          {{(authDataSnapshotService.editRoutes() ? 'routes.daily.tableBtns.edit' : 'general.view') | translate}}
        </button>
        <div
          *ngIf="!row.locked && isEditRoutes()"
          class="route-daily-three-dots"
          [ngClass]="{
            'route-daily-three-dots_canceled-status': row.cancelled,
            'route-daily-three-dots_required-recalculation-routes': requiredRecalculation && !row.requiredRecalculation
          }"
        >
          <u-three-dots-popover
            [class]="'u-three-dots-popover_ngx-datatable'"
            [items]="row.threeDotsMenuItems"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [container]="'body'"
            [viewportElement]="viewportElement"
            [visibleClass]="true"
            [autoClose]="'outside'"
            (selectDotsItemAction)="selectDotsItemAction($event, row)"
          ></u-three-dots-popover>
        </div>
      </div>
      <div
        *ngIf="row.requiredRecalculation"
        class="ride-status__warning-icon-wrapper"
      >
        <div
          class="ride-status__warning-icon"
          [uTooltip]="requiredRecalculationTooltip"
          [tooltipClass]="'u-tooltip_app-routes-required-recalculation'"
          [container]="'body'"
          [placement]="[ 'top' ]"
        ></div>
      </div>
    </div>
  </div>
  <ng-template #requiredRecalculationTooltip>
    <div class="rides-status-required-recalculation">
      <div class="rides-status-required-recalculation__text">
        {{config.dictionary.requiredRecalculation.message | translate}}
      </div>
      <div class="rides-status-required-recalculation__text">
        {{config.dictionary.requiredRecalculation.openEditorAndSave | translate}}
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #statusColumnFilter ngx-datatable-header-filter-template let-item="item">
  <span
    *ngIf="item === 'routes.daily.routeStatuses.finished'"
    class="route-daily-table__table-filter"
    title="{{'routes.daily.routeStatuses.finishedWithoutMonitored' | translate}}"
  >
    {{'routes.daily.routeStatuses.finishedWithoutMonitored' | translate}}
  </span>
  <span
    *ngIf="item === 'routes.daily.routeStatuses.finishedMonitored'"
    class="route-daily-table__table-filter"
    title="{{'routes.daily.routeStatuses.finishedMonitoredFilter' | translate}}"
  >
    {{'routes.daily.routeStatuses.finishedMonitoredFilter' | translate}}
  </span>
  <span
    *ngIf="item !== 'routes.daily.routeStatuses.finished'
    && item !== 'routes.daily.routeStatuses.finishedMonitored'"
    class="route-daily-table__table-filter"
    title="{{item | translate}}"
  >
    {{item | translate}}
  </span>
</ng-template>

<ng-template #activitiesColumn ngx-datatable-cell-template let-row="row" let-value="value">
  <div
    *ngIf="(!row.locked || row.disabled) && !row.saveStatus && value"
    (click)="openRouteActivities(row.routeId)"
    class="route-daily-table__activities"
    [ngClass]="{ 'route-daily-table__activities_disabled': !value.length }"
  >
    <span class="route-daily-table__activities-icon"></span>
    <span class="route-daily-table__activities-counter" *ngIf="value | activitiesCounter">{{value | activitiesCounter}}</span>
  </div>
  <div
    class="route-daily-table__loader"
    *ngIf="row.locked && !row.saveStatus && !row.disabled"
    #lockedByTooltip="uTooltip"
    [uTooltip]="lockedBy"
    [viewportElement]="routesDailyTable.querySelector('datatable-body')"
    [tooltipClass]="'u-tooltip_general'"
    [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
    [container]="'body'"
    [triggers]="'manual'"
    (mouseenter)="openLockedByTooltip(row, lockedByTooltip)"
    (mouseleave)="closeLockedByTooltip(lockedByTooltip)"
  >
    <div class="loader loader_circle loader_all-events"></div>
    <ng-template #lockedBy>
      <div class="route-lock-state-clock">
        <div class="route-lock-state-clock__text">{{'general.doneBy' | translate}} {{routeLockState?.lockedByUser}}</div>
        <div class="route-lock-state-clock__text">
          {{routeLockState.lockDateTime | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME}}
        </div>
      </div>
    </ng-template>
  </div>
  <div
    class="route-daily-table__save-status"
    *ngIf="row.saveStatus"
    [ngClass]="{
      'route-daily-table__save-status_successful': row.saveStatus === routeSaveStatus.Successful,
      'route-daily-table__save-status_failed': row.saveStatus === routeSaveStatus.Failed
    }"
  ></div>
</ng-template>

<ng-template #notesColumn ngx-datatable-cell-template let-row="row" let-value="value">
  <div
    *ngIf="(!row.locked || row.disabled) && !row.saveStatus && row.notes"
    (click)="showRouteNotes(row)"
    class="route-daily-table-notes"
  >
    <span class="route-daily-table-notes__counter" *ngIf="row.notes && row.notes.unReadNoteIds.length">
      {{row.notes && row.notes.unReadNoteIds.length <= routesConfig.notes.maxDisplayCounter ? row.notes.unReadNoteIds.length : routesConfig.notes.maxDisplayCounter}}
    </span>
    <span
      class="route-daily-table-notes__icon"
      [ngClass]="{ 'route-daily-table-notes__icon_empty': !(row.notes && row.notes.anyNote) }"
    ></span>
  </div>
</ng-template>

<ng-template #startHourColumn ngx-datatable-cell-template let-row="row">
  <ng-template #warningsStartHour>
    <app-routes-daily-warning
      [type]="typesPerChanges.StartHour"
      [data]="row.plannedRideStartDateTime"
    ></app-routes-daily-warning>
  </ng-template>
  <div class="route-daily-table-cell">
    <div
      class="route-daily-table-cell__active"
      [ngClass]="{
        'route-daily-table-cell__active_clickable': isEditRoutes()
      }"
    >
      <span
        class="route-daily-table-cell__active-text"
        [ngClass]="{
          'route-daily-table-cell__active-text_warning': showWarnings(row, typesPerChanges.StartHour),
          'route-daily-table-cell__active-text_disabled': !isEditRoutes()
        }"
        [uPopover]="!row.isFullManualRide && changes"
        [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom' ]"
        [container]="'body'"
        [viewportElement]="viewportElement"
        [viewportAutoClose]="false"
        [popoverClass]="'u-popover_app-routes-change'"
        [autoClose]="false"
        #popoverStartHour="uPopover"
        (click)="onHoursChangeCellClick(row, routesChangeViewType.Hour, popoverStartHour, changeOptionStartTime);"
        role="button"
      >
        {{ row.rideStartDateTime | date: appConstants.TIME_FORMAT }}
      </span>
    </div>
    <button
      *ngIf="showWarnings(row, typesPerChanges.StartHour)"
      class="route-daily-table-cell__warning"
      [uPopover]="warningsStartHour"
      [placement]="[ 'bottom', 'top' ]"
      [container]="'body'"
      [viewportElement]="viewportElement"
      [viewportAutoClose]="false"
      [popoverClass]="'u-popover_app-warnings'"
      [autoClose]="true"
      [triggers]="'hover'"
      role="button"
    >
      !
    </button>
  </div>
</ng-template>
<ng-template #startHourColumnFilter ngx-datatable-header-filter-template let-item="item">
  <span
    class="route-daily-table__table-filter"
    title="{{ item | date: appConstants.TIME_FORMAT }}"
  >
    {{ item | date: appConstants.TIME_FORMAT }}
  </span>
</ng-template>
<ng-template #endHourColumn ngx-datatable-cell-template let-row="row">
  <ng-template #warningsEndHour>
    <app-routes-daily-warning
      [type]="typesPerChanges.EndHour"
      [data]="row.plannedRideEndDateTime"
    ></app-routes-daily-warning>
  </ng-template>
  <div class="route-daily-table-cell">
    <div
      class="route-daily-table-cell__active"
      [ngClass]="{
        'route-daily-table-cell__active_clickable': isEditRoutes()
      }"
    >
      <span
        class="route-daily-table-cell__active-text"
        [ngClass]="{
          'route-daily-table-cell__active-text_warning': showWarnings(row, typesPerChanges.EndHour),
          'route-daily-table-cell__active-text_disabled': !isEditRoutes()
        }"
        [uPopover]="!row.isFullManualRide && changes"
        [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom' ]"
        [container]="'body'"
        [viewportElement]="viewportElement"
        [viewportAutoClose]="false"
        [popoverClass]="'u-popover_app-routes-change'"
        [autoClose]="false"
        #popoverEndHour="uPopover"
        (click)="onHoursChangeCellClick(row, routesChangeViewType.Hour, popoverEndHour, changeOptionEndTime);"
        role="button"
      >
        {{ row.rideEndDateTime | date: appConstants.TIME_FORMAT }}
      </span>
    </div>
    <button
      *ngIf="showWarnings(row, typesPerChanges.EndHour)"
      class="route-daily-table-cell__warning"
      [uPopover]="warningsEndHour"
      [placement]="[ 'bottom', 'top' ]"
      [container]="'body'"
      [viewportElement]="viewportElement"
      [viewportAutoClose]="false"
      [popoverClass]="'u-popover_app-warnings'"
      [autoClose]="true"
      [triggers]="'hover'"
      role="button"
    >
      !
    </button>
  </div>
</ng-template>
<ng-template #endHourColumnFilter ngx-datatable-header-filter-template let-item="item">
  <span
    class="route-daily-table__table-filter"
    title="{{ item | date: appConstants.TIME_FORMAT }}"
  >
    {{ item | date: appConstants.TIME_FORMAT }}
  </span>
</ng-template>
<ng-template #codeColumn ngx-datatable-cell-template let-row="row">
  <div>
    <span>
      {{ row.code }}
    </span>
  </div>
</ng-template>
<ng-template #directionColumn ngx-datatable-cell-template let-row="row">
  <div>
    <span
      *ngIf="row.direction === direction.Forward"
      class="route-direction"
    >
      {{'general.direction.forward' | translate}}
    </span>
    <span
      *ngIf="row.direction === direction.Backward"
      class="route-direction"
    >
      {{'general.direction.backward' | translate}}
    </span>
  </div>
</ng-template>
<ng-template #directionFilter ngx-datatable-header-filter-template let-item="item">
  <div>
    <span
      class="route-direction"
      [title]="item.name"
    >
      {{item.name}}
    </span>
  </div>
</ng-template>
<ng-template #accompanyColumn ngx-datatable-cell-template let-row="row" let-value="value">
  <ng-template #warningsAccompany>
    <app-routes-daily-warning
      [data]="row.plannedAccompany"
      [type]="typesPerChanges.Accompany"
    ></app-routes-daily-warning>
  </ng-template>
  <div class="route-daily-table-cell">
    <div
      class="route-daily-table-cell__active"
      [ngClass]="{
        'route-daily-table-cell__active_clickable': authDataSnapshotService.assignAccompanyToRides() && !isMovePassengers()
      }"
    >
      <span
        class="route-daily-table-cell__active-text"
        [ngClass]="{
          'route-daily-table-cell__active-text_empty': !row.accompany,
          'route-daily-table-cell__active-text_warning': showWarnings(row, typesPerChanges.Accompany),
          'route-daily-table-cell__active-text_disabled-change': (isSCCustomer || isMovePassengers()) || !authDataSnapshotService.assignAccompanyToRides()
        }"
        [uPopover]="changes"
        [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom' ]"
        [container]="'body'"
        [viewportElement]="viewportElement"
        [viewportAutoClose]="false"
        [popoverClass]="'u-popover_app-routes-change'"
        [autoClose]="false"
        #popoverAccompany="uPopover"
        role="button"
        (click)="onCellClick(row, routesChangeViewType.Accompany, popoverAccompany);"
      >
        {{value | translate}}
      </span>
    </div>
    <button
      *ngIf="showWarnings(row, typesPerChanges.Accompany)"
      class="route-daily-table-cell__warning"
      [uPopover]="warningsAccompany"
      [placement]="[ 'bottom', 'top' ]"
      [container]="'body'"
      [viewportElement]="viewportElement"
      [viewportAutoClose]="false"
      [popoverClass]="'u-popover_app-warnings'"
      [autoClose]="true"
      [triggers]="'hover'"
      role="button"
    >
      !
    </button>
  </div>
</ng-template>
<ng-template #accompanyCostCell ngx-datatable-cell-template let-row="row" let-value="value">
  <div class="route-daily-table-cell">
    <div
      class="route-daily-table-cell__active"
      [ngClass]="{
        'route-daily-table-cell__active_clickable': authDataSnapshotService.assignAccompanyToRides() && !isMovePassengers(),
        'route-daily-table-cell__active_disabled': !authDataSnapshotService.assignAccompanyToRides() || isMovePassengers()
      }"
      [uPopover]="changes"
      [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom' ]"
      [container]="'body'"
      [viewportElement]="viewportElement"
      [viewportAutoClose]="false"
      [popoverClass]="'u-popover_app-routes-change'"
      [autoClose]="false"
      #popoverAccompanyCost="uPopover"
      role="button"
      (click)="onCellClick(row, routesChangeViewType.AccompanyCost, popoverAccompanyCost);"
    >
      {{value}}
    </div>
  </div>
</ng-template>
<ng-template #shuttleCompanyColumn ngx-datatable-cell-template let-row="row" let-value="value">
  <div class="route-daily-table-cell">
    <div
      class="route-daily-table-cell__active"
      [ngClass]="{
        'route-daily-table-cell__active_clickable': allowShuttleCompanyChange && isEditRoutes()
      }"
    >
      <span
        class="route-daily-table-cell__active-text"
        [ngClass]="{
          'route-daily-table-cell__active-text_empty': !row.shuttleCompany,
          'route-daily-table-cell__active-text_disabled': (!allowShuttleCompanyChange || (row.subShuttleCompany && row.subShuttleCompany.id === customerId)) && !authDataSnapshotService.editRoutes() || !isEditRoutes()
        }"
        [uPopover]="changes"
        [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom' ]"
        [container]="'body'"
        [viewportElement]="viewportElement"
        [viewportAutoClose]="false"
        [popoverClass]="'u-popover_app-routes-change'"
        [autoClose]="false"
        #popoverShuttleCompany="uPopover"
        (click)="onCellClick(row, routesChangeViewType.ShuttleCompany, popoverShuttleCompany);"
        role="button"
      >
        {{ value }}
      </span>
    </div>
  </div>
</ng-template>
<ng-template #driverColumn ngx-datatable-cell-template let-row="row">
  <div class="route-daily-table-cell">
    <div class="route-daily-table-cell__active">
      <span
        class="route-daily-table-cell__active-text"
        [ngClass]="{
          'route-daily-table-cell__active-text_clickable': !(row.shuttleCompany ? false : !row.subShuttleCompany) && isEditRoutes(),
          'route-daily-table-cell__active-text_disabled': !(row.shuttleCompany || row.subShuttleCompany) && !authDataSnapshotService.editRoutes() || !isEditRoutes()
        }"
        [uPopover]="changes"
        [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom' ]"
        [container]="'body'"
        [viewportElement]="viewportElement"
        [viewportAutoClose]="false"
        [popoverClass]="'u-popover_app-routes-change'"
        [autoClose]="false"
        #popoverDriver="uPopover"
        (click)="onCellClick(row, routesChangeViewType.ShuttleCompany, popoverDriver);"
        role="button"
      >
        {{ row.driver ? row.driver.name : row.isSelfShuttleCompany ? notAssigned : driverRequired }}
      </span>

      <div
        *ngIf="changesApprovalsEnabled && needsDriverApprove && row.driver && row.driver.approvals && row.driver.approvals.expected !== 0"
        class="route-daily-table-cell__active-drivers-approvals"
      >
        <app-routes-daily-approvals
          [isRejected]="row.driver.approvals.rejected"
          [actualApprovalsCount]="row.driver.approvals.actual"
          [expectedApprovalsCount]="row.driver.approvals.expected"
        ></app-routes-daily-approvals>
        <div
          #popoverDriversList="uPopover"
          class="route-daily-table-cell__active-drivers-approvals-info u-icon u-icon_app-info"
          [ngClass]="{ 'route-daily-table-cell__active-drivers-approvals-info_show': popoverDriversList.isOpen() }"
          [uPopover]="driversListPopover"
          [autoClose]="'outside'"
          [triggers]="'manual'"
          [container]="'body'"
          [placement]="isRtl ? [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom', 'top' ] : [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom', 'top' ]"
          [popoverClass]="'u-popover_app-routes-ride-drivers'"
          [viewportElement]="routesDailyTable.querySelector('datatable-body')"
          (click)="onDriversListPopoverOpen($event, row, popoverDriversList)"
        ></div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #carCell ngx-datatable-cell-template let-row="row">
  <div class="route-daily-table-cell">
    <div
      class="route-daily-table-cell__active"
      [ngClass]="{
        'route-daily-table-cell__active_clickable': isEditRoutes()
      }"
    >
      <div
        class="route-daily-table-cell__active-text"
        [ngClass]="{
          'route-daily-table-cell__active-text_disabled': carChangeDisabled(row) && !authDataSnapshotService.editRoutes() || !isEditRoutes()
        }"
        [uPopover]="changes"
        [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom' ]"
        [container]="'body'"
        [viewportElement]="viewportElement"
        [viewportAutoClose]="false"
        [popoverClass]="'u-popover_app-routes-change'"
        [autoClose]="false"
        #popoverCar="uPopover"
        (click)="onCellClick(row, routesChangeViewType.ShuttleCompany, popoverCar);"
        role="button"
      >
        {{row.car ? row.car.name : 'routes.daily.placeholders.required' | translate}}
        <div class="route-daily-table-car-type">{{row.car && row.car.carTypeName}}</div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #passengersAmountColumn ngx-datatable-cell-template let-row="row">
  <div
    class="route-daily-table-cell__active"
    [ngClass]="{
      'route-daily-table-cell__active_clickable': isEditRoutes() && !isSCCustomer && showPassengersInfo
    }"
  >
    <span
      *ngIf="showPassengersInfo"
      class="route-daily-table-cell__active-text route-daily-table-cell__active-text_passengers-amount"
      #popoverPassengers="uPopover"
      [uPopover]="changes"
      [placement]="[ 'left-bottom', 'left-top', 'left-bottom', 'right-bottom', 'right-top', 'right', 'bottom' ]"
      [container]="'body'"
      [viewportElement]="viewportElement"
      [viewportAutoClose]="false"
      [popoverClass]="'u-popover_app-routes-change'"
      [autoClose]="false"
      [disablePopover]="!isEditRoutes() || !allowPassengersChange && !authDataSnapshotService.editRoutes()"
      (click)="isEditRoutes() && allowPassengersChange && onCellClick(row, routesChangeViewType.Passenger, popoverPassengers);"
      role="button"
    >
      <span class="route-daily-table-cell__active-text-passengers">
        <span
          class="route-daily-table-cell__active-text-passengers-value"
          [ngClass]="{
            'route-daily-table-cell__active-text-passengers-value_disabled': !isEditRoutes()
          }"
        >
          {{row.totalPassengers}}{{row.carType ? '/' + row.carType.seatsCount : ''}}
        </span>
        <span
          #popoverPassengersList="uPopover"
          class="route-daily-table-cell__active-text-passengers-info"
          [ngClass]="{ 'route-daily-table-cell__active-text-passengers-info_show': popoverPassengersList.isOpen() }"
          [uPopover]="passengersListPopover"
          [placement]="isRtl ? [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom', 'top' ] : [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom', 'top' ]"
          [container]="'body'"
          [viewportElement]="routesDailyTable.querySelector('datatable-body')"
          [popoverClass]="'u-popover_app-routes-ride-passengers'"
          [autoClose]="'outside'"
          [triggers]="'manual'"
          (click)="onPassengersListPopoverOpen($event, row, popoverPassengersList)"
        >
          <div class="u-icon u-icon_app-info"></div>
        </span>
      </span>
    </span>
    <span
      class="route-daily-table-cell__active-text-passengers"
      *ngIf="!showPassengersInfo"
    >
      {{row.totalPassengers}}
    </span>
  </div>
</ng-template>
<ng-template
  #customersColumn
  ngx-datatable-cell-template
  let-row="row"
>
  <div
    class="route-daily-table-cell-customers"
    [ngClass]="{
      'route-daily-table-cell-customers_disabled': !isEditRoutes()
    }"
  >
    <span class="route-daily-table-cell-customers__number">
      {{row.totalPassengerCustomers}}
    </span>
    <div
      [uId]="'route-daily-table-customers-info-icon-' + row.rideId"
      #popoverCustomersList="uPopover"
      class="route-daily-table-cell-customers__icon-wrapper"
      [ngClass]="{
        'route-daily-table-cell-customers__icon-wrapper_visible': popoverCustomersList.isOpen(),
        'route-daily-table-cell__active_clickable': isEditRoutes()
      }"
      [uPopover]="customersListPopover"
      [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom', 'top' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom', 'top' ]"
      [container]="'body'"
      [viewportElement]="routesDailyTable.querySelector('datatable-body')"
      [popoverClass]="'u-popover_app-routes-ride-passengers-customers'"
      [autoClose]="'outside'"
      [triggers]="'manual'"
      (click)="onCustomersListPopoverOpen($event, row, popoverCustomersList)"
    >
      <div class="u-icon u-icon_app-routes-info"></div>
    </div>
  </div>
</ng-template>
<ng-template #timerColumn ngx-datatable-cell-template let-row="row">
  <div class="routes-daily-table__timer">
    <p
      class="days-to-end"
      *ngIf="row.dateCountShow && row.daysCount >= 0"
    >
      <span class="days-to-end__text">
        {{'routes.daily.daysToRouteEnd.endsIn' | translate}}
      </span>
      {{row.daysCount}}
      {{'routes.daily.daysToRouteEnd.days' | translate}}
    </p>
  </div>
</ng-template>

<ng-template #yitStatusCell ngx-datatable-cell-template let-row="row">
  <div class="route-daily-table-cell-yit-status">
    <div *ngIf="row.yitStatus !== null" class="route-daily-table-cell-yit-status__item">
      <img
        class="route-daily-table-cell-yit-status__icon"
        [src]="config.yitStatuses[row.yitStatus].image"
        [alt]="config.yitStatuses[row.yitStatus].name | translate"
        [uTooltip]="yitStatusDate"
        [tooltipClass]="'u-tooltip_general'"
        [placement]="[ 'bottom', 'top' ]"
        [container]="'body'"
      >
      <span class="route-daily-table-cell-yit-status__text">
        {{config.yitStatuses[row.yitStatus].name | translate}}
      </span>
    </div>

    <ng-template #yitStatusDate>
      <div class="route-daily-table-cell-yit-status-date">
        {{row.yitLastUpdate ? (row.yitLastUpdate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME) : config.dictionary.yitCtrl.executionTimeNotAvailable | translate}}
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #ctrlStatusCell ngx-datatable-cell-template let-row="row">
  <div class="route-daily-table-cell-ctrl-status">
    <ng-container *ngIf="row.ctrlStatus !== null">
      <img
        class="route-daily-table-cell-ctrl-status__icon"
        [src]="config.ctrlStatuses[row.ctrlStatus].image"
        [alt]="config.ctrlStatuses[row.ctrlStatus].name | translate"
        [uTooltip]="ctrlStatusDate"
        [tooltipClass]="'u-tooltip_general'"
        [placement]="[ 'bottom', 'top' ]"
        [container]="'body'"
      >
      <span class="route-daily-table-cell-ctrl-status__text">
        {{config.ctrlStatuses[row.ctrlStatus].name | translate}}
      </span>
      <ng-template #ctrlStatusDate>
        <div class="route-daily-table-cell-ctrl-status-date">
          {{row.ctrlLastUpdate ? (row.ctrlLastUpdate | momentConvert : appConstants.DATE_FORMAT_ISO : appConstants.DATE_FORMAT_BASE_SLASH_COMMA_TIME) : config.dictionary.yitCtrl.executionTimeNotAvailable | translate}}
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #carTypes ngx-datatable-cell-template let-row="row" let-value="value">
  <div
    class="route-daily-table-cell__car-type"
    [ngClass]="{
      'route-daily-table-cell__car-type_clickable': !isSCCustomer,
      'route-daily-table-cell__active-text_disabled': !isEditRoutes()
    }"
    [container]="'body'"
    [uPopover]="!isSCCustomer ? changes : null"
    [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom' ]"
    [viewportElement]="viewportElement"
    [viewportAutoClose]="false"
    [popoverClass]="'u-popover_app-routes-change'"
    [autoClose]="false"
    #popoverCarChange="uPopover"
    (click)="!isSCCustomer ? onCellClick(row, routesChangeViewType.Car, popoverCarChange) : null"
  >
    <span
      class="route-daily-table-cell__car-type-text"
      [container]="'body'"
      [uTooltip]="row.carType ? ('general.capacity' | translate) + ': ' + row.carType.seatsCount : value"
    >
      {{value || 'general.required' | translate}}
    </span>
  </div>
</ng-template>

<ng-template #changes>
  <app-routes-change
    [activeRoute]="activeRoute"
    [type]="selectedRoutesChangeViewType"
    [viewportElement]="document.body"
    [changeOptions]="changeOptions"
    [authCustomer]="authCustomer"
    (closeAction)="closeRoutesChange()"
  ></app-routes-change>
</ng-template>

<ng-template #passengersListPopover>
  <app-routes-ride-passengers
    [passengers]="popoversStore[routeDailyRowProp.PassengerDetails]"
  ></app-routes-ride-passengers>
</ng-template>

<ng-template #customersListPopover>
  <app-routes-ride-passengers-customers
    [customers]="popoversStore[routeDailyRowProp.PassengersCustomers]"
  ></app-routes-ride-passengers-customers>
</ng-template>

 <ng-template #driversListPopover>
   <app-routes-ride-drivers
     [drivers]="popoversStore[routeDailyRowProp.Drivers]"
   ></app-routes-ride-drivers>
 </ng-template>

<ng-template #supervisorCell ngx-datatable-cell-template let-row="row" let-value="value">
  <div class="route-daily-table-cell">
    <div class="route-daily-table-cell__active">
      <span
        class="route-daily-table-cell__active-text"
        [ngClass]="{
          'route-daily-table-cell__active-text_empty': !row.supervisor,
          'route-daily-table-cell__active-text_disabled': !(!routesTableFeatureTypeShuttleCompany || row.isOwnedBySc) || !isEditRoutes(),
          'route-daily-table-cell__active_clickable': (!routesTableFeatureTypeShuttleCompany || row.isOwnedBySc) && isEditRoutes()
        }"
        [uPopover]="changes"
        [placement]="isRtl ? [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left', 'bottom' ] : [ 'left-bottom', 'left-top', 'left', 'right-bottom', 'right-top', 'right', 'bottom' ]"
        [container]="'body'"
        [viewportElement]="viewportElement"
        [viewportAutoClose]="false"
        [popoverClass]="'u-popover_app-routes-change'"
        [autoClose]="false"
        #popoverSupervisor="uPopover"
        role="button"
        (click)="onCellClick(row, routesChangeViewType.Supervisor, popoverSupervisor);"
      >
        {{value || ('routes.daily.placeholders.without' | translate)}}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #supervisorCellFilter ngx-datatable-header-filter-template let-item="item">
  <div class="route-daily-table-cell-filter">
    {{item || ('routes.daily.placeholders.without' | translate)}}
  </div>
</ng-template>

<ng-template #costDifferenceCell ngx-datatable-cell-template let-row="row" let-value="value">
  <div 
    class="route-daily-table-cell-profit-loss"
    [ngClass]="{
      'route-daily-table-cell-profit-loss_green': value > 0,
      'route-daily-table-cell-profit-loss_red': value < 0
    }"
  >
    {{value}}
  </div>
</ng-template>

<ng-template #costDifferencePercentCell ngx-datatable-cell-template let-row="row" let-value="value">
  <div 
    class="route-daily-table-cell-profit-loss"
    [ngClass]="{
      'route-daily-table-cell-profit-loss_green': value > 0,
      'route-daily-table-cell-profit-loss_red': value < 0
    }"
  >
    {{value !== '' ? value + '%' : null}}
  </div>
</ng-template>

<ng-template #costDifferencePercentCellFilter ngx-datatable-header-filter-template let-item="item">
  <div class="route-daily-table-cell-profit-loss-filter">
    {{item !== '' ? item + '%' : translations.emptyValue}}
  </div>
</ng-template>

<ng-template #timeTrackingCell ngx-datatable-cell-template let-value="value" let-row="row" let-column="column">
  <div
    [uPopover]="changes"
    [placement]="isRtl ? [ 'left-bottom', 'left-top', 'left-bottom', 'right-bottom', 'right-top', 'right', 'bottom' ] :  [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left-bottom', 'bottom' ]"
    [container]="'body'"
    [viewportElement]="viewportElement"
    [viewportAutoClose]="false"
    [popoverClass]="'u-popover_app-routes-change'"
    [autoClose]="false"
    [triggers]="'manual'"
    #popoverTimeTrackingChanges="uPopover"
  >
    <div
      class="route-daily-table-cell__time-tracking"
      #popoverTimeTracking="uPopover"
      [ngClass]="{
        'route-daily-table-cell__active-text_disabled': !isEditRoutes()
      }"
      [container]="'body'"
      [autoClose]="'outside'"
      [uPopover]="timeTrackingPopoverTemplate"
      [placement]="isRtl ? [ 'left-bottom', 'left-top', 'left-bottom', 'right-bottom', 'right-top', 'right', 'bottom' ] :  [ 'right-bottom', 'right-top', 'right', 'left-bottom', 'left-top', 'left-bottom', 'bottom' ]"
      [popoverClass]="'u-popover_app-routes-daily-time-tracking'"
      (click)="openTimeTrackingChangesPopover(popoverTimeTracking, column.trackingName, row); timeTrackingChangesPopover = popoverTimeTrackingChanges;"
    >
      <div class="route-daily-table-cell__time-tracking-date" *ngIf="value && column.displayFormat">
        {{value | momentConvert : column.dataFormat : column.displayFormat}}
      </div>
      <div class="route-daily-table-cell__time-tracking-date" *ngIf="value && !column.displayFormat">
        {{value}}
      </div>
      <div class="route-daily-table-cell__time-tracking-not-reported" *ngIf="!value">
        {{config.dictionary.notReported | translate}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #timeTrackingPopoverTemplate>
  <div class="time-tracking-changes-popover">
    <div class="time-tracking-changes-popover__item" (click)="editTimeTracking()">
      {{config.dictionary.editTimeTracking | translate}}
    </div>
    <div class="time-tracking-changes-popover__item" (click)="resetTimeTracking()">
      {{config.dictionary.resetTimeTracking | translate}}
    </div>
  </div>
</ng-template>

<ng-template
  #rowWrapper
  let-row="row"
  let-template="template"
>
  <div
    class="routes-daily-table-row-wrapper"
    [uId]="'routes-daily-table-row-wrapper-' + row.routeId"
  >
    <div
      cdkDropList
      [id]="routesDailyMovePassengersConfig.idPrefixes.route + '-' + row.routeId"
      (cdkDropListEntered)="onDropListEntered($event, row)"
      (cdkDropListDropped)="onDropListDropped($event, row)"
    >
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template
  #createdByCell
  let-row="row"
>
  {{row.createdBy ? row.createdBy : config.dictionary.automaticProcess | translate}}
</ng-template>
