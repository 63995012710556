<div class="notes-header">
  <div class="notes-header__title">
    <span class="notes-header__title-text">{{config.dictionary.title | translate}}</span>
  </div>
  <span
    class="notes-header__close"
    (click)="closeNotes.emit()"
  ></span>
</div>
<div class="notes-body">
  <button
    uButton
    class="u-font-weight-medium notes-body__add"
    [withPlus]="true"
    (click)="addNewNote($event)"
  >
    {{config.dictionary.new | translate}}
  </button>
  <div class="notes-body__content">
    <div
      *ngFor="let note of notes.controls; let index = index; trackBy: trackById"
      class="notes-body__content-item"
      (click)="onClickInsideNote(index, noteTextarea)"
      (clickOutside)="onClickOutsideNote(index, noteTextarea)"
    >
      <textarea
        #noteTextarea
        maxlength="160"
        class="notes-body__content-item-input"
        [appAutofocus]="!note.get('id') || note.get('id').value < 0"
        [formControl]="note.get('text')"
      ></textarea>
      <div class="notes-body__content-item-actions">
        <span
          class="notes-body__content-item-actions-done"
          [uTooltip]="config.dictionary.done | translate"
          (click)="removeNote(index, $event)"
        ></span>
        <span
          #reminderPopover="uPopover"
          class="notes-body__content-item-actions-reminder"
          [ngClass]="{ 'notes-body__content-item-actions-reminder_active': note.get('reminder.id').value > 0 }"
          [uTooltip]="note.get('reminder.dateToDisplay').value || config.dictionary.reminder | translate"
          [uPopover]="note.get('reminder.id').value === -1 && noteReminder"
          [popoverClass]="'u-popover_app-notes-reminder'"
          [placement]="isRtl ? [ 'left' ] : [ 'right' ]"
          [autoClose]="false"
          [verticalAlignByViewport]="true"
          (click)="openReminder(reminderPopover, index)"
        ></span>
      </div>
    </div>
  </div>
</div>

<ng-template #noteReminder>
  <div class="notes-reminder">
    <div class="notes-reminder__header">
      <span class="notes-reminder__header-title">{{config.dictionary.reminderTitle | translate}}</span>
      <span class="notes-reminder__header-close" (click)="closeReminder($event)"></span>
    </div>
    <div class="notes-reminder__body">
      <div class="notes-reminder__body-time">
        <span class="notes-reminder__body-time-icon"></span>
        <u-input-time
          [class]="'u-input-time_center u-input-time_app-notes-reminder'"
          [defaultTime]="false"
          [formControl]="notes.at(selectedNoteIndex).get('reminder.time')"
        ></u-input-time>
      </div>
      <u-date-picker
        [lang]="lang"
        [singleDateMode]="true"
        [formControl]="notes.at(selectedNoteIndex).get('reminder.date')">
      </u-date-picker>
    </div>
    <div class="notes-reminder__actions">
      <span 
        class="notes-reminder__actions-save"
        [attr.disabled]="!notes.at(selectedNoteIndex).get('reminder').valid"
        (click)="addNoteReminder(selectedNoteIndex)"
      >
        {{config.dictionary.confirm | translate}}
      </span>
    </div>
  </div>
</ng-template>
