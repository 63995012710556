import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, finalize, tap, switchMap, takeUntil, take } from 'rxjs/operators';
import { NgProgress } from 'ngx-progressbar';
import { OAuthService } from 'angular-oauth2-oidc';
import { UPopupService } from '@shift/ulib';

import { environment } from '@environments/environment';
import { appConfig } from '@app/shared/configs/app.config';
import { ApiBaseError, Errors } from '@app/shared/models';
import { AuthDataService } from '@app/auth/services';
import { OperationGuidService } from './operation-guid.service';
import { LocalizationService } from './localization.service';
import { StatusesService } from './statuses.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy {
  apiPrefix = 'api';
  spinnerCount = 0;

  private unsubscribe: Subject<void> = new Subject();
  private customerId: number;
  private spinnerExcludedUrls: string[] = [ 'Reports/Dashboard', 'ApprovalsConfig' ];

  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService,
    private operationGuidService: OperationGuidService,
    private localizationService: LocalizationService,
    private statusesService: StatusesService,
    private ngProgress: NgProgress,
    private popupService: UPopupService,
    private authDataService: AuthDataService
  ) {
    this.initAuthCustomer();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initAuthCustomer() {
    this.authDataService.customer$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(customer => this.customerId = customer.customerId);
  }

  private getHeaders(): Observable<HttpHeaders> {
    const headersConfig: { [key: string]: string; } = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Lang': this.localizationService.getLanguage(),
      'Authorization': `Bearer ${this.oAuthService.getAccessToken()}`
    };

    if (this.operationGuidService.getGuid()) {
      headersConfig['OperationGuid'] = this.operationGuidService.getGuid();
    }

    if (this.customerId) {
      headersConfig['customerId'] = this.customerId.toString();
      headersConfig['clientType'] = 'Web';
    }

    return of(new HttpHeaders(headersConfig));
  }

  private getHeadersFormFile(): Observable<HttpHeaders> {
    const headersConfig: { [key: string]: string; } = {
      'Lang': this.localizationService.getLanguage(),
      'Authorization': `Bearer ${this.oAuthService.getAccessToken()}`
    };

    return of(new HttpHeaders(headersConfig));
  }

  private toHttpParams(params: any): HttpParams {
    return Object.getOwnPropertyNames(params)
      .reduce((p, key) => p.set(key, params[key]), new HttpParams());
  }

  private showErrorMessage = (
    error: {
      code: string;
      categoryDescription?: string;
      description?: string;
    },
    showErrorDescription?: boolean,
    noTimeoutErrorCodes?: Errors[]
  ) => {
    if (Number(error.code) < 1000) {
      this.statusesService.messageByCode(error.code)
        .subscribe((message: string) => {
          this.popupService.showErrorMessage({
            message,
            ...(noTimeoutErrorCodes && noTimeoutErrorCodes.includes(error.code as Errors) && { timeout: null })
          });
        });
    } else {
      this.popupService.showErrorMessage({
        message: showErrorDescription ? error.description : error.categoryDescription,
        ...(noTimeoutErrorCodes && noTimeoutErrorCodes.includes(error.code as Errors) && { timeout: null })
      });
    }
  };

  private extractData = (res, withoutErrorMessage: boolean = false, showErrorDescription?: boolean, noTimeoutErrorCodes?: Errors[]) => {
    try {
      const data = JSON.parse(res);

      if (!withoutErrorMessage) {
        const error = data.errors && data.errors[0] || data.warnings && data.warnings[0];

        if (error) {
          this.showErrorMessage(error, !!data.warnings || showErrorDescription, noTimeoutErrorCodes);
        }
      }

      return data;
    } catch (e) {
      return;
    }
  };

  private formatErrors = (
    res,
    withoutErrorMessage?: boolean,
    skipErrorCodesMessage?: (string | number)[],
    showErrorDescription?: boolean,
    noTimeoutErrorCodes?: Errors[]
  ) => {
    if (res.status < 200 || res.status >= 300) {
      try {
        let error = JSON.parse(res.error);
        const { errors } = error;
        let errorMessage: ApiBaseError;

        if (errors && errors.length) {
          error = errors[0];
          errorMessage = error;

          if (skipErrorCodesMessage && skipErrorCodesMessage.length) {
            const filteredErrors = errors.filter(err => !skipErrorCodesMessage.includes(err.code));

            errorMessage = filteredErrors && filteredErrors.length ? filteredErrors[0] : undefined;
          }
        }

        if (!withoutErrorMessage) {
          if (errorMessage !== undefined) {
            this.showErrorMessage(errorMessage, showErrorDescription, noTimeoutErrorCodes);
          }
        }

        return throwError(error);
      } catch (e) {
        if (!withoutErrorMessage) {
          this.showErrorMessage({ code: res.status }, false, noTimeoutErrorCodes);
        }

        return throwError({ code: res.status });
      }
    }
  };

  get(
    path: string,
    params: object = {},
    withoutSpinner?: boolean,
    withoutErrorMessage?: boolean,
    skipErrorCodesMessage?: (string | number)[],
    showErrorDescription?: boolean,
    noTimeoutErrorCodes?: Errors[]
  ): Observable<any> {
    return this.getHeaders()
      .pipe(
        tap(() => withoutSpinner || this.startSpinner(path)),
        switchMap((headers: HttpHeaders) => this.http.get(
          `${environment.config.serverUrlBase}${this.apiPrefix}/${path}`,
          { headers, params: this.toHttpParams(params), responseType: 'text' }
        )),
        map(data => this.extractData(data, withoutErrorMessage, showErrorDescription, noTimeoutErrorCodes)),
        catchError(error => this.formatErrors(error, withoutErrorMessage, skipErrorCodesMessage, showErrorDescription, noTimeoutErrorCodes)),
        finalize(() => withoutSpinner || this.stopSpinner(path))
      );
  }

  put(
    path: string,
    body: object = {},
    withoutErrorMessage?: boolean,
    skipErrorCodesMessage?: (string | number)[],
    noTimeoutErrorCodes?: Errors[],
    showErrorDescription?: boolean
  ): Observable<any> {
    return this.getHeaders()
      .pipe(
        tap(() => this.startSpinner(path)),
        switchMap((headers: HttpHeaders) => this.http.put(
          `${environment.config.serverUrlBase}${this.apiPrefix}/${path}`,
          JSON.stringify(body),
          { headers, responseType: 'text' }
        )),
        map(data => this.extractData(data, withoutErrorMessage, showErrorDescription, noTimeoutErrorCodes)),
        catchError(error => this.formatErrors(error, withoutErrorMessage, skipErrorCodesMessage, showErrorDescription, noTimeoutErrorCodes)),
        finalize(() => {
          this.stopSpinner(path);
        })
      );
  }

  post(
    path: string,
    body: Object = {},
    responseType: any = 'text',
    withoutErrorMessage?: boolean,
    withoutSpinner?: boolean,
    skipErrorCodesMessage?: (string | number)[],
    showErrorDescription?: boolean
  ): Observable<any> {
    return this.getHeaders()
      .pipe(
        tap(() => withoutSpinner || this.startSpinner(path)),
        switchMap((headers: HttpHeaders) => this.http.post(
          `${environment.config.serverUrlBase}${this.apiPrefix}/${path}`,
          JSON.stringify(body),
          { headers, responseType }
        )),
        map(data => this.extractData(data, withoutErrorMessage, showErrorDescription)),
        catchError(error => this.formatErrors(error, withoutErrorMessage, skipErrorCodesMessage, showErrorDescription)),
        finalize(() => withoutSpinner || this.stopSpinner(path))
      );
  }

  postBlob(path: string, body: object = {}, responseType: any = 'text'): Observable<any> {
    return this.getHeaders()
      .pipe(
        tap(() => this.startSpinner(path)),
        switchMap((headers: HttpHeaders) => this.http.post(
          `${environment.config.serverUrlBase}${this.apiPrefix}/${path}`,
          JSON.stringify(body),
          { headers, responseType }
        )),
        finalize(() => {
          this.stopSpinner(path);
        })
      );
  }

  getBlob(path: string, responseType: any = 'text', params: object = {}): Observable<any> {
    return this.getHeaders()
      .pipe(
        tap(() => this.startSpinner(path)),
        switchMap((headers: HttpHeaders) => this.http.get(
          `${environment.config.serverUrlBase}${this.apiPrefix}/${path}`,
          { headers, responseType, params: this.toHttpParams(params) }
        )),
        finalize(() => {
          this.stopSpinner(path);
        })
      );
  }

  postFormFile(path: string, body, withoutErrorMessage?: boolean, withoutErrorFormatting?: boolean, skipErrorCodesMessage?: (string | number)[], showErrorDescription?: boolean): Observable<any> {
    return this.getHeadersFormFile()
      .pipe(
        tap(() => this.startSpinner(path)),
        switchMap((headers: HttpHeaders) => this.http.post(
          `${environment.config.serverUrlBase}${this.apiPrefix}/${path}`,
          body,
          { headers, responseType: 'text' }
        )),
        map(data => this.extractData(data, withoutErrorMessage)),
        catchError(error => withoutErrorFormatting ? throwError(JSON.parse(error.error)) : this.formatErrors(error, withoutErrorMessage, skipErrorCodesMessage, showErrorDescription)),
        finalize(() => {
          this.stopSpinner(path);
        })
      );
  }

  delete(path: string, params: object = {}, body: object = {}): Observable<any> {
    return this.getHeaders()
      .pipe(
        tap(() => this.startSpinner(path)),
        switchMap((headers: HttpHeaders) => this.http.request(
          'delete',
          `${environment.config.serverUrlBase}${this.apiPrefix}/${path}`,
          { headers, params: this.toHttpParams(params), body, responseType: 'text' }
        )),
        map(data => this.extractData(data)),
        catchError(error => this.formatErrors(error)),
        finalize(() => {
          this.stopSpinner(path);
        })
      );
  }

  startSpinner(path: string) {
    if (this.spinnerExcludedUrls.length > 0 && this.spinnerExcludedUrls.some((x: string) => path.includes(x))) {
      return;
    }

    this.ngProgress.ref(appConfig.progressBarId).start();
    this.spinnerCount++;
  }

  stopSpinner(path: string) {
    if (this.spinnerExcludedUrls.length > 0 && this.spinnerExcludedUrls.some((x: string) => path.includes(x))) {
      return;
    }

    this.spinnerCount--;

    if (this.spinnerCount === 0) {
      this.ngProgress.ref(appConfig.progressBarId).complete();
    }
  }
}
