import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { isEqual } from 'lodash';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';

import { BuilderService } from '@app/builder/services';
import { BuilderAgendaState, BuilderGetShuttleCompanies, BuilderGetShuttleCompaniesBody, BuilderGetShuttleCompanyContracts, BuilderGetShuttleCompanyContractsBody } from '@app/builder/models';

@Injectable()
export class BuilderAgendaStore extends ComponentStore<BuilderAgendaState> {
  constructor(
    private readonly builderService: BuilderService
  ) {
    super({ shuttleCompanies: null, shuttleCompanyContracts: null });
  }

  readonly getShuttleCompaniesAndContracts = this.effect((params$: Observable<{
    shuttleCompanies: BuilderGetShuttleCompaniesBody;
    shuttleCompanyContracts: BuilderGetShuttleCompanyContractsBody;
  }>) => params$.pipe(
    distinctUntilChanged(isEqual),
    switchMap(({ shuttleCompanies, shuttleCompanyContracts }) =>
      forkJoin([
        this.builderService.getShuttleCompanies(shuttleCompanies),
        shuttleCompanyContracts ? this.builderService.getShuttleCompanyContracts(shuttleCompanyContracts) : of(null)
      ])
        .pipe(
          tapResponse(
            (data: (BuilderGetShuttleCompanies | BuilderGetShuttleCompanyContracts)[]) => this.updateShuttleCompaniesAndContracts(data),
            () => null
          )
        )
    )
  ));

  readonly updateShuttleCompaniesAndContracts = this.updater((state, data: unknown) => ({
    shuttleCompanies: data[0],
    shuttleCompanyContracts: data[1]
  }));

  readonly reset = this.updater(() => ({ shuttleCompanies: null, shuttleCompanyContracts: null }));
}
