export enum NavigationPaths {
  Home = '',
  Demands = 'Demands',
  Rides = 'Rides',
  RideOrders = 'RideOrders',
  RouteTemplates = 'RouteTemplates',
  RouteSuggestions = 'RouteSuggestions',
  Contracts = 'Contracts',
  Drivers = 'Drivers',
  Passengers = 'Passengers',
  RideSupervisors = 'RideSupervisors',
  RideCommanders = 'RideCommanders',
  Stations = 'Stations',
  Branches = 'Branches',
  ActivitySchedule = 'ActivitySchedule',
  ActivityCenters = 'ActivityCenters',
  Accompanies = 'Accompanies',
  Schools = 'Schools',
  Builder = 'Builder',
  BuilderRideOrders = 'BuilderRideOrders',
  BuilderFull = 'BuilderFull',
  BuilderRouteTemplates = 'BuilderRouteTemplates',
  Settings = 'Settings',
  BI = 'BI',
  ShuttleCompanies = 'ShuttleCompanies',
  ShuttleCompaniesCars = 'ShuttleCompanies/Cars',
  ShuttleCompaniesCustomers = 'ShuttleCompanies/Customers',
  Reports = 'Reports',
  ReportsDashboard = 'Reports/Dashboard',
  ReportsServiceSheet = 'Reports/ServiceSheet',
  ReportsActualExpenses = 'Reports/ActualExpenses',
  ReportsExpensesSummary = 'Reports/ExpensesSummary',
  ReportsIncomeDetailed = 'Reports/IncomeDetailed',
  ReportsIncomeSummary = 'Reports/IncomeSummary',
  ReportsScExpenses = 'Reports/ScExpenses',
  ReportsAgenda = 'Reports/Agenda',
  ReportsRouteDetails = 'Reports/RouteDetails',
  ReportsChangesHistory = 'Reports/ChangesHistory',
  ReportsAccompanyHours = 'Reports/AccompanyHours',
  ReportsTravelTimePerPassenger = 'Reports/TravelTimePerPassenger',
  ReportsSummaryTravelTimePerPassenger = 'Reports/SummaryTravelTimePerPassenger',
  ReportsMealPlans = 'Reports/MealPlans',
  ReportsMenuVoting = 'Reports/MenuVoting'
}
