import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';

import { TrackingService, HeaderDataService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { AuthDataService } from '@app/auth/services';
import { AuthModuleName, AuthModuleSupervisorsFeature, AuthModuleSupervisorsFeatureType } from '@app/auth/models';
import { RouteDailyRow, RouteWeeklyRow } from '@app/routes/models';
import { RoutesCommonService, RoutesTableService } from '@app/routes/services';
import { routesConfig } from '@app/routes/configs';
import { routesChangeTimeTrackingComponentConfig } from './routes-change-time-tracking.component.config';

@Component({
  selector: 'app-routes-change-time-tracking',
  templateUrl: './routes-change-time-tracking.component.html',
  styleUrls: [ './routes-change-time-tracking.component.scss', './routes-change-time-tracking.component.rtl.scss' ]
})
export class RoutesChangeTimeTrackingComponent implements OnInit, OnDestroy {
  @Input() activeRoute: RouteDailyRow | RouteWeeklyRow;

  @HostBinding('class') hostClasses: string = 'routes-change-time-tracking';

  private unsubscribe: Subject<void> = new Subject();

  form: UntypedFormGroup;
  config = cloneDeep(routesChangeTimeTrackingComponentConfig);
  supervisorType: AuthModuleSupervisorsFeatureType;
  supervisorTypes = AuthModuleSupervisorsFeatureType;

  constructor(
    private fb: UntypedFormBuilder,
    private authDataService: AuthDataService,
    private routesCommonService: RoutesCommonService,
    private trackingService: TrackingService,
    private headerDataService: HeaderDataService,
    private routesTableService: RoutesTableService
  ) {}

  ngOnInit() {
    this.getSupervisorsFeature();
    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getSupervisorsFeature() {
    this.authDataService.moduleFeatureByName$(AuthModuleName.Supervisors, AuthModuleSupervisorsFeature.Type)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(supervisorsType => this.supervisorType = supervisorsType);
  }

  private initForm() {
    this.form = this.fb.group({
      startDate: [ this.activeRoute['timeTrackingStartDateTime'] ],
      startTime: [ this.activeRoute['timeTrackingStartDateTime'] ? moment(this.activeRoute['timeTrackingStartDateTime']).format(AppConstants.TIME_FORMAT) : null ],
      endDate: [ this.activeRoute['timeTrackingEndDateTime'] ],
      endTime: [ this.activeRoute['timeTrackingEndDateTime'] ? moment(this.activeRoute['timeTrackingEndDateTime']).format(AppConstants.TIME_FORMAT) : null ],
      comment: [ '' ]
    });

    this.form.get('startDate').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.trackEvent('change start date'));

    this.form.get('startTime').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.trackEvent('change start time'));

    this.form.get('endDate').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.trackEvent('change end date'));

    this.form.get('endTime').valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.trackEvent('change end time'));
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${routesConfig.trackingId}] - supv. time report - ${event}`);
  }

  saveChanges() {
    const startDateTime = this.form.value.startDate && moment(this.form.value.startDate).utc().set({
      hours: moment(this.form.value.startTime, AppConstants.TIME_FORMAT).hours(),
      minutes: moment(this.form.value.startTime, AppConstants.TIME_FORMAT).minutes()
    }).format(AppConstants.DATE_FORMAT_ISO);

    const endDateTime = this.form.value.endDate && moment(this.form.value.endDate).utc().set({
      hours: moment(this.form.value.endTime, AppConstants.TIME_FORMAT).hours(),
      minutes: moment(this.form.value.endTime, AppConstants.TIME_FORMAT).minutes()
    }).format(AppConstants.DATE_FORMAT_ISO);

    this.trackEvent('click on save&close');

    this.routesTableService.changeTimeTrack({
      routeId: this.activeRoute.routeId,
      activeDate: this.headerDataService.getActiveDate(),
      value: {
        comment: this.form.value.comment,
        rideId: this.activeRoute['rideId'],
        startDateTime,
        endDateTime
      }
    })
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }
}
