import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '@app/shared/components';
import {
  AuthAfterSchoolActivitiesGuard,
  AuthBIGuard,
  AuthModuleBIGuard,
  AuthModuleContractsGuard,
  AuthModuleCustomerSettingsGuard,
  AuthModuleFeatureGroupGuard,
  AuthModuleFeatureGuard,
  AuthModuleFeaturesGuard,
  AuthModuleReportsGuard,
  AuthModuleRoutePlannerGuard,
  AuthModuleRouteSuggestionsGuard,
  AuthModuleRouteTemplatesGuard,
  AuthSCOrSelfSCGuard,
  AuthSupervisorGuard
} from '@app/auth/guards';
import {
  AuthModuleAccompanyFeature,
  AuthModuleAccompanyFeatureEntityManager,
  AuthModuleBranchesFeature,
  AuthModuleBranchesFeatureEntityManager,
  AuthModuleCustomerStationsFeature,
  AuthModuleCustomerStationsFeatureEntityManager,
  AuthModuleDemandsPassengersViewFeature,
  AuthModuleDemandsPassengersViewFeatureEntityManager,
  AuthModuleDemandsShiftsViewFeature,
  AuthModuleDemandsShiftsViewFeatureEntityManager,
  AuthModuleEducationInstitutionsFeature,
  AuthModuleEducationInstitutionsFeatureEntityManager,
  AuthModuleFeatureGroupCondition,
  AuthModuleName,
  AuthModulePassengersFeature,
  AuthModulePassengersFeatureEntityManager,
  AuthModuleRideOrdersFeature,
  AuthModuleSupervisorsFeature,
  AuthModuleSupervisorsFeatureEntityManager,
  AuthModuleSupervisorsFeatureType
} from '@app/auth/models';
import { BuilderBuildMode, BuilderViewMode } from '@app/builder/models';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: 'Rides',
    loadChildren: () => import('./routes/routes.module').then(m => m.RoutesModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      feature: {
        module: AuthModuleName.RoutesTable
      }
    }
  },
  {
    path: 'RideOrders',
    loadChildren: () => import('./ride-orders/ride-orders.module').then(m => m.RideOrdersModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      feature: {
        module: AuthModuleName.RideOrders,
        name: AuthModuleRideOrdersFeature.Type
      }
    }
  },
  {
    path: 'RouteTemplates',
    loadChildren: () => import('./route-templates/route-templates.module').then(m => m.RouteTemplatesModule),
    canActivate: [ AuthModuleRouteTemplatesGuard ]
  },
  {
    path: 'RouteSuggestions',
    loadChildren: () => import('./route-suggestions/route-suggestions.module').then(m => m.RouteSuggestionsModule),
    canActivate: [ AuthModuleRouteSuggestionsGuard ]
  },
  {
    path: 'Reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [ AuthModuleReportsGuard ]
  },
  {
    path: 'Contracts',
    loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
    canActivate: [ AuthModuleContractsGuard ]
  },
  {
    path: 'Drivers',
    loadChildren: () => import('./drivers/drivers.module').then(m => m.DriversModule),
    canActivate: [ AuthSCOrSelfSCGuard ]
  },
  {
    path: 'ShuttleCompanies',
    loadChildren: () => import('./shuttle-companies/shuttle-companies.module').then(m => m.ShuttleCompaniesModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      feature: {
        module: AuthModuleName.Transportation
      }
    }
  },
  {
    path: 'Passengers',
    loadChildren: () => import('./passengers/passengers.module').then(m => m.PassengersModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      feature: {
        module: AuthModuleName.Passengers,
        name: AuthModulePassengersFeature.EntityManager,
        values: [ AuthModulePassengersFeatureEntityManager.EntityManagerFeature ]
      }
    }
  },
  {
    path: 'RideSupervisors',
    loadChildren: () => import('./ride-supervisors/ride-supervisors.module').then(m => m.RideSupervisorsModule),
    canActivate: [ AuthModuleFeaturesGuard, AuthSupervisorGuard ],
    data: {
      features: [
        {
          module: AuthModuleName.Supervisors,
          name: AuthModuleSupervisorsFeature.Type,
          values: [ AuthModuleSupervisorsFeatureType.Generic ]
        },
        {
          module: AuthModuleName.Supervisors,
          name: AuthModuleSupervisorsFeature.EntityManager,
          values: [ AuthModuleSupervisorsFeatureEntityManager.EntityManagerFeature ]
        }
      ]
    }
  },
  {
    path: 'RideCommanders',
    loadChildren: () => import('./ride-commanders/ride-commanders.module').then(m => m.RideCommandersModule),
    canActivate: [ AuthModuleFeaturesGuard, AuthSupervisorGuard ],
    data: {
      features: [
        {
          module: AuthModuleName.Supervisors,
          name: AuthModuleSupervisorsFeature.Type,
          values: [ AuthModuleSupervisorsFeatureType.Commander ]
        },
        {
          module: AuthModuleName.Supervisors,
          name: AuthModuleSupervisorsFeature.EntityManager,
          values: [ AuthModuleSupervisorsFeatureEntityManager.EntityManagerFeature ]
        }
      ]
    }
  },
  {
    path: 'Stations',
    loadChildren: () => import('./stations/stations.module').then(m => m.StationsModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      feature: {
        module: AuthModuleName.CustomerStations,
        name: AuthModuleCustomerStationsFeature.EntityManager,
        values: [ AuthModuleCustomerStationsFeatureEntityManager.EntityManagerFeature ]
      }
    }
  },
  {
    path: 'Branches',
    loadChildren: () => import('./branches/branches.module').then(m => m.BranchesModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      feature: {
        module: AuthModuleName.Branches,
        name: AuthModuleBranchesFeature.EntityManager,
        values: [ AuthModuleBranchesFeatureEntityManager.EntityManagerFeature ]
      }
    }
  },
  {
    path: 'ActivityCenters',
    loadChildren: () => import('./branches/branches.module').then(m => m.BranchesModule),
    canActivate: [ AuthModuleFeatureGuard, AuthAfterSchoolActivitiesGuard ],
    data: {
      feature: {
        module: AuthModuleName.Branches,
        name: AuthModuleBranchesFeature.EntityManager,
        values: [ AuthModuleBranchesFeatureEntityManager.EntityManagerFeature ]
      }
    }
  },
  {
    path: 'Accompanies',
    loadChildren: () => import('./accompanies/accompanies.module').then(m => m.AccompaniesModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      feature: {
        module: AuthModuleName.Accompany,
        name: AuthModuleAccompanyFeature.EntityManager,
        values: [ AuthModuleAccompanyFeatureEntityManager.EntityManagerFeature ]
      }
    }
  },
  {
    path: 'Schools',
    loadChildren: () => import('./schools/schools.module').then(m => m.SchoolsModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      feature: {
        module: AuthModuleName.EducationInstitutions,
        name: AuthModuleEducationInstitutionsFeature.EntityManager,
        values: [ AuthModuleEducationInstitutionsFeatureEntityManager.EntityManagerFeature ]
      }
    }
  },
  {
    path: 'Builder',
    loadChildren: () => import('./builder/builder.module').then(m => m.BuilderModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      viewMode: BuilderViewMode.Default,
      feature: {
        module: AuthModuleName.RouteBuilder
      }
    }
  },
  {
    path: 'BuilderRideOrders',
    loadChildren: () => import('./builder/builder.module').then(m => m.BuilderModule),
    canActivate: [ AuthModuleFeatureGuard ],
    data: {
      viewMode: BuilderViewMode.Default,
      buildMode: BuilderBuildMode.RideOrders,
      feature: {
        module: AuthModuleName.RideOrders,
        name: AuthModuleRideOrdersFeature.Type
      }
    }
  },
  {
    path: 'BuilderFull',
    loadChildren: () => import('./builder/builder.module').then(m => m.BuilderModule),
    canActivate: [ AuthModuleRoutePlannerGuard ],
    data: {
      viewMode: BuilderViewMode.Full
    }
  },
  {
    path: 'BuilderRouteTemplates',
    loadChildren: () => import('./builder/builder.module').then(m => m.BuilderModule),
    canActivate: [ AuthModuleRouteTemplatesGuard ],
    data: {
      viewMode: BuilderViewMode.Default,
      buildMode: BuilderBuildMode.RouteTemplates
    }
  },
  {
    path: 'Settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [ AuthModuleCustomerSettingsGuard ]
  },
  {
    path: 'BI',
    loadChildren: () => import('./bi/bi.module').then(m => m.BIModule),
    canActivate: [ AuthBIGuard, AuthModuleBIGuard ]
  },
  {
    path: 'Demands',
    loadChildren: () => import('./demands/demands.module').then(m => m.DemandsModule),
    canActivate: [ AuthModuleFeatureGroupGuard ],
    data: {
      featureGroup: {
        condition: AuthModuleFeatureGroupCondition.Or,
        values: [
          [
            {
              module: AuthModuleName.DemandsPassengersView,
              name: AuthModuleDemandsPassengersViewFeature.EntityManager,
              values: [ AuthModuleDemandsPassengersViewFeatureEntityManager.EntityManagerFeature ]
            }
          ],
          [
            {
              module: AuthModuleName.DemandsShiftsView,
              name: AuthModuleDemandsShiftsViewFeature.EntityManager,
              values: [ AuthModuleDemandsPassengersViewFeatureEntityManager.EntityManagerFeature ]
            }
          ]
        ]
      }
    }
  },
  {
    path: 'ActivitySchedule',
    loadChildren: () => import('./demands/demands.module').then(m => m.DemandsModule),
    canActivate: [ AuthModuleFeatureGroupGuard, AuthAfterSchoolActivitiesGuard ],
    data: {
      featureGroup: {
        condition: AuthModuleFeatureGroupCondition.Or,
        values: [
          [
            {
              module: AuthModuleName.DemandsPassengersView,
              name: AuthModuleDemandsPassengersViewFeature.EntityManager,
              values: [ AuthModuleDemandsPassengersViewFeatureEntityManager.EntityManagerFeature ]
            }
          ],
          [
            {
              module: AuthModuleName.DemandsShiftsView,
              name: AuthModuleDemandsShiftsViewFeature.EntityManager,
              values: [ AuthModuleDemandsShiftsViewFeatureEntityManager.EntityManagerFeature ]
            }
          ]
        ]
      }
    }
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { useHash: false, initialNavigation: 'enabledNonBlocking', enableTracing: false }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
