import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

import { AppConstants } from '@app/shared/constants';
import {
  AuthModuleName,
  AuthModuleRoutePlannerFeature,
  AuthModuleRoutePlannerFeatureDirections
} from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { RoutePlannerDirectionType } from '@app/route-planner/models';
import { BuilderFullOptions, BuilderFullConfigData } from '@app/builder/models';
import { builderFullConfig } from '@app/builder/configs';

@Injectable()
export class BuilderFullCalculationStoreService {
  optionsDefault: BuilderFullOptions = builderFullConfig.optionsDefault;
  options: BuilderFullOptions = builderFullConfig.options;
  configData: BuilderFullConfigData = builderFullConfig.configData;

  constructor(
    private authDataService: AuthDataService
  ) {
    this.initDirections();
  }

  private initDirections() {
    this.authDataService.moduleFeatureByName$(AuthModuleName.RoutePlanner, AuthModuleRoutePlannerFeature.Directions)
      .pipe(take(1))
      .subscribe(directions => {
        if (directions !== AuthModuleRoutePlannerFeatureDirections.PickupDropOff) {
          this.options.directions = this.optionsDefault.directions.filter(ob => ob.value !== RoutePlannerDirectionType.BackwardAndForward);
        }
      });
  }

  updateConfigData(data: BuilderFullConfigData): void {
    Object.assign(this.configData, {
      ...data,
      minDate: moment(data.minDate, AppConstants.DATE_FORMAT_BASE_LINE).startOf('day').subtract(1, 'days').format(AppConstants.DATE_FORMAT_ISO),
      maxDate: moment(data.maxDate, AppConstants.DATE_FORMAT_BASE_LINE).startOf('day').add(1, 'days').format(AppConstants.DATE_FORMAT_ISO)
    });

    Object.assign(this.optionsDefault, {
      shuttleCompanies: (data.shuttleCompanies ?? []).map(shuttleCompany => ({ value: shuttleCompany.id, name: shuttleCompany.name })),
      vehicleTypes: data.vehicleTypes.map(vehicleType => ({ value: vehicleType.id, name: `${vehicleType.name} (${vehicleType.seatsCount})` }))
    });

    this.updateOptions({
      shuttleCompanies: this.optionsDefault.shuttleCompanies,
      vehicleTypes: this.optionsDefault.vehicleTypes
    });
  }

  updateOptions(data: any): void {
    Object.assign(this.options, data);
  }
}
