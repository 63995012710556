import { Component, OnDestroy, OnInit, inject } from '@angular/core';

import { DemandsStore } from '@app/demands/stores';
import { DemandsView } from '@app/demands/models';

@Component({
  selector: 'app-demands',
  templateUrl: './demands.component.html'
})
export class DemandsComponent implements OnInit, OnDestroy {
  public readonly demandsStore = inject(DemandsStore);

  demandsView = DemandsView;

  ngOnInit() {
    this.demandsStore.updateShowViewToggle(true);
  }

  ngOnDestroy() {
    this.demandsStore.updateShowViewToggle(false);
  }
}
