import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';

import { environment } from '@environments/environment';
import { AuthService } from '@app/auth/services';
import { authConfig } from '@app/auth/configs';

@Injectable()
export class AuthRefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private token: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private oAuthService: OAuthService,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessTokenExpiration = this.oAuthService.getAccessTokenExpiration();
    const currentTime = new Date().getTime() + (authConfig.accessTokenExtraTime * 1000);
    const expiredToken = !!(accessTokenExpiration && accessTokenExpiration <= currentTime);

    return req.url && !req.url.includes(environment.config.idpAuthority) ? of(expiredToken)
      .pipe(
        switchMap(expired => expired ? this.refreshToken(req, next) : next.handle(req))
      ) : next.handle(req);
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.token.next(null);

      return from(this.oAuthService.refreshToken())
        .pipe(
          catchError(err => {
            this.isRefreshing = false;

            this.authService.clearStorages();
            this.oAuthService.initLoginFlow();

            return throwError(err);
          }),
          switchMap((data: TokenResponse) => {
            this.isRefreshing = false;

            this.token.next(data.access_token);

            return next.handle(this.addTokenHeader(request, data.access_token));
          })
        );
    }

    return this.token.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }
}
