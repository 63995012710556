import { Injectable, inject, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthFacade } from '@app/auth/state/facades';
import {
  AuthCustomer,
  AuthModuleFeature,
  AuthModuleFeatureGroup,
  AuthModuleFeatureGroupCondition,
  AuthModuleName,
  AuthModules,
  AuthPermission,
  AuthPermissionGroup,
  AuthPermissionGroupCondition,
  AuthPolicies
} from '@app/auth/models';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
  public readonly authFacade = inject(AuthFacade);

  readonly userInfo$ = this.authFacade.userInfo$;
  readonly customer$: Observable<AuthCustomer> = this.userInfo$.pipe(map(data => data.customer));
  readonly permissions$: Observable<AuthPermission[]> = this.userInfo$.pipe(map(data => data.permissions));
  readonly policies$: Observable<AuthPolicies> = this.userInfo$.pipe(map(data => data.policies));
  readonly modules$: Observable<AuthModules> = this.userInfo$.pipe(map(data => data.modules));
  readonly moduleByName$ = (name: AuthModuleName) => this.modules$.pipe(map(modules => modules?.[name]));
  readonly moduleByNameAvailable$ = (name: AuthModuleName) => this.modules$.pipe(map(modules => !!modules?.[name]));
  readonly moduleFeatureByName$ = (module: AuthModuleName, feature: string) => this.modules$.pipe(map(modules => modules?.[module]?.[feature]));

  readonly userInfo = toSignal(this.userInfo$);
  readonly customer = computed(() => this.userInfo().customer);
  readonly modules = computed(() => this.userInfo().modules);
  readonly permissions = computed(() => this.userInfo().permissions);
  readonly policies = computed(() => this.userInfo().policies);
  readonly createRoutes = computed(() => this.checkPermission(AuthPermission.CreateRoutes));
  readonly editRoutes = computed(() => this.checkPermission(AuthPermission.EditRoutes));
  readonly deleteRoutes = computed(() => this.checkPermission(AuthPermission.DeleteRoutes));
  readonly assignAccompanyToRides = computed(() => this.checkPermission(AuthPermission.AssignAccompanyToRides));
  readonly approveRideOrders = computed(() => this.checkPermission(AuthPermission.ApproveRideOrders));
  readonly policiesAndSettingsManagement = computed(() => this.checkPermission(AuthPermission.PoliciesAndSettingsManagement));
  readonly managePassengers = computed(() => this.checkPermission(AuthPermission.ManagePassengers));
  readonly manageAccompanies = computed(() => this.checkPermission(AuthPermission.ManageAccompanies));
  readonly manageShuttleCompanies = computed(() => this.checkPermission(AuthPermission.ManageShuttleCompanies));

  checkPermission(permission: AuthPermission) {
    return this.permissions().includes(permission);
  }

  checkPermissions(permissions: AuthPermission[]) {
    return permissions.every(permission => this.checkPermission(permission));
  }

  checkPermissionGroup(group: AuthPermissionGroup) {
    return group.condition === AuthPermissionGroupCondition.Or ?
      group.values.some(value => this.checkPermissions(value)) :
      group.values.every(value => this.checkPermissions(value));
  }

  checkFeature(feature: AuthModuleFeature): boolean {
    if (feature) {
      const featureModule = this.modules()[feature.module];
      let check = !!featureModule;

      if (!check) { return false; }

      if (feature.name) {
        check = !!featureModule[feature.name];
      }

      if (feature.name && feature.values) {
        check = !!(featureModule[feature.name] && (
          Array.isArray(featureModule[feature.name]) ?
            feature.values.every(value => featureModule[feature.name].includes(value)) :
            feature.values.includes(featureModule[feature.name]))
        );
      }

      return check;
    }

    return false;
  }

  checkFeatures(features: AuthModuleFeature[]): boolean {
    return features.every(feature => this.checkFeature(feature));
  }

  checkFeatureGroup(feature: AuthModuleFeatureGroup): boolean {
    return feature.condition === AuthModuleFeatureGroupCondition.Or ?
      feature.values.some(value => this.checkFeatures(value)) :
      feature.values.every(value => this.checkFeatures(value));
  }
}
