import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { SignalRService } from '@app/shared/services';
import { RoutePlannerCalculated, RoutePlannerFinished, RoutePlannerRouteStatus } from '@app/route-planner/models';

@Injectable({
  providedIn: 'root'
})
export class RoutePlannerHubService extends SignalRService {
  BASE_PATH = 'routePlannerHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  onCalculated(): Observable<RoutePlannerCalculated> {
    return this.onChannel<RoutePlannerCalculated>('Calculated');
  }

  onRouteStatus(): Observable<RoutePlannerRouteStatus> {
    return this.onChannel<RoutePlannerRouteStatus>('RouteStatus');
  }

  onFinished(): Observable<RoutePlannerFinished> {
    return this.onChannel<RoutePlannerFinished>('Finished');
  }
}
