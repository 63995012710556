import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  AfterViewChecked,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as moment from 'moment';
import { first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { forkJoin, of, Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { ReactiveFormsModule } from '@angular/forms';
import { UButtonModule, UButtonSize, UButtonView, UCommonModule, UIdModule, UMenuIconsModule, UPopupService, USearchModule, USidebarMenuService } from '@shift/ulib';

import { SharedModule } from '@app/shared/shared.module';
import { MonitoringDashboardModule } from '@app/monitoring-dashboard/monitoring-dashboard.module';
import { environment } from '@environments/environment';
import {
  CommonService,
  LocalizationService,
  HeaderSearchFiltersService,
  TrackingService,
  HeaderMenuIconsService,
  HeaderDataService
} from '@app/shared/services';
import { AppLanguage, EnvType } from '@app/shared/models';
import { AppConstants } from '@app/shared/constants';
import { CobrowseIoService } from '@app/shared/services/cobrowse-io.service';
import { ActivitiesDataService } from '@app/activities/services';
import { AuthDataService } from '@app/auth/services';
import { AuthCustomerType, AuthModuleName, AuthModuleNextYearFeature } from '@app/auth/models';
import { RoutesService } from '@app/routes/services';
import { NextYearDataService } from '@app/next-year/services';
import { DemandsStore } from '@app/demands/stores';
import { FileSaverService } from '@app/files/services';
import { UserNotificationHubService } from '@app/user/services';
import { UserNotificationBackgroundTaskError } from '@app/user/models';
import { HeaderRoutesViewTypeComponent } from './header-routes-view-type/header-routes-view-type.component';
import { HeaderDemandsViewComponent } from './header-demands-view/header-demands-view.component';
import { headerComponentConfig } from './header.component.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss', './header.component.rtl.scss' ],
  standalone: true,
  imports: [ ReactiveFormsModule, CommonModule, TranslateModule, UMenuIconsModule, UButtonModule, USearchModule, UCommonModule, UIdModule, SharedModule, MonitoringDashboardModule, HeaderRoutesViewTypeComponent, HeaderDemandsViewComponent ]
})
export class HeaderComponent implements OnInit, AfterViewChecked, OnDestroy {
  @HostBinding('class') hostClasses: string = 'header';

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(headerComponentConfig);
  isShiftNYEnv: boolean = environment.config.environmentType === EnvType.ShiftNY;
  isShuttleCompanyCustomer: boolean = false;
  appConstants = AppConstants;
  uButtonView = UButtonView;
  uButtonSize = UButtonSize;

  isRtl: boolean = this.localizationService.isRtl();
  lang: AppLanguage = this.localizationService.getLanguage();
  showWeekSwitch: boolean;
  showTodayButton: boolean;
  showThisWeekButton: boolean;
  isNextYearAvailable: boolean;

  constructor(
    private localizationService: LocalizationService,
    private trackingService: TrackingService,
    private userNotificationHubService: UserNotificationHubService,
    private routesService: RoutesService,
    private fileSaverService: FileSaverService,
    private cobrowseIoService: CobrowseIoService,
    private cd: ChangeDetectorRef,
    private activitiesDataService: ActivitiesDataService,
    private popupService: UPopupService,
    private authDataService: AuthDataService,
    public nextYearDataService: NextYearDataService,
    public commonService: CommonService,
    public headerSearchFiltersService: HeaderSearchFiltersService,
    public headerMenuIconsService: HeaderMenuIconsService,
    public headerDataService: HeaderDataService,
    public translateService: TranslateService,
    public uSidebarMenuService: USidebarMenuService,
    public demandsStore: DemandsStore
  ) {}

  ngOnInit() {
    this.getNextYearFeatureType();
    this.userNotification();
    this.getAuthUserInfo();
    this.initShowElements();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.userNotificationHubService.stop();
    this.activitiesDataService.destroyActivitiesHub();
  }

  private getNextYearFeatureType() {
    this.authDataService.moduleFeatureByName$(AuthModuleName.NextYear, AuthModuleNextYearFeature.Type)
      .pipe(take(1))
      .subscribe(type => this.isNextYearAvailable = !!type);
  }

  initShowElements() {
    this.headerDataService.showWeekSwitch$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(showWeekSwitch => this.showWeekSwitch = showWeekSwitch);

    this.headerDataService.date$
      .pipe(
        takeUntil(this.unsubscribe),
        map(date => {
          const today = moment().startOf('day');
          const activeDay = moment(date, AppConstants.DATE_FORMAT_ISO).startOf('day');

          return !today.isSame(activeDay);
        })
      )
      .subscribe(showTodayButton => this.showTodayButton = showTodayButton);

    this.headerDataService.weekSwitchChange$
      .pipe(
        takeUntil(this.unsubscribe),
        map(({ startDate, endDate }) => {
          const today = moment().startOf('day');
          const fromDate = moment(startDate, AppConstants.DATE_FORMAT_ISO).startOf('day');
          const toDate = moment(endDate, AppConstants.DATE_FORMAT_ISO).startOf('day');

          return !(today.isSameOrAfter(fromDate) && today.isSameOrBefore(toDate));
        })
      )
      .subscribe(showThisWeekButton => this.showThisWeekButton = showThisWeekButton);
  }

  backToday() {
    const today = moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    this.trackingService.track('[Calendar] - date change');

    this.headerDataService.dateSet(today);
  }

  backToThisWeek() {
    this.headerDataService.updateDateWeekSwitch(moment());
  }

  userNotification(): void {
    this.userNotificationHubService.init();
    this.userNotificationHubService.start();

    this.userNotificationHubService
      .onDownloadRouteDetails()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(data => forkJoin([ data.fileName.includes('.xlsx') ? this.routesService.downloadExcel(data.key) : this.routesService.downloadPdf(data.key), of(data) ])),
        tap(([ res, data ]) => this.fileSaverService.downloadBlobFile(res, data.fileName))
      )
      .subscribe();

    this.userNotificationHubService
      .onBackgroundTaskError()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => this.showErrors(data));
  }

  showErrors(data: UserNotificationBackgroundTaskError) {
    this.translateService
      .get(this.config.dictionary.errorCode)
      .pipe(first())
      .subscribe(res => this.popupService.showErrorMessage({ message: (res[data.errorCode]) }));
  }

  getAuthUserInfo(): void {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(userInfo => {
        const authCustomerType = userInfo.customer.type;

        this.cobrowseIoService.initializeService(userInfo);
        this.isShuttleCompanyCustomer = authCustomerType === AuthCustomerType.ShuttleCompany;

        this.activitiesDataService.updateMemberId(userInfo.person.memberId);
        this.activitiesDataService.loadActivitiesLive(this.headerDataService.getDateRange());
        this.activitiesDataService.initActivitiesHub();
      });
  }

  toggleNextYearMode() {
    if (this.nextYearDataService.isInNextYearMode) {
      this.nextYearDataService.leaveNextYear();

      return;
    }

    this.trackingService.track('[Routes & rides] - Global Header - calendar - click on New year');
    this.nextYearDataService.nextYearIconClick();
  }
}
