import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { UGridService } from '@shift/ulib';

import { RouteDirection } from '@app/routes/models';
import { builderRoutesConfig } from '@app/builder/configs';
import { BuilderRoute, BuilderRoutesState, BuilderRoutesStateGridProps, BuilderRouteStatus } from '@app/builder/models';
import { RoutePlannerSummaryRoute } from '@app/route-planner/models';

@Injectable({
  providedIn: 'root'
})
export class BuilderRoutesStore extends ComponentStore<BuilderRoutesState> {
  constructor(
    private uGridService: UGridService
  ) {
    super(builderRoutesConfig.initialState);
  }

  readonly showBackdrop = this.selectSignal(({ showBackdrop }) => showBackdrop);
  readonly routesFullChanged = this.selectSignal(({ routesFullChanged }) => routesFullChanged);
  readonly grid = this.selectSignal(({ grid }) => grid);
  readonly gridColumns = this.selectSignal(
    this.grid,
    grid => grid.columns
  );
  readonly gridRowClassObjects = this.selectSignal(
    this.grid,
    grid => grid.rowClassObjects
  );
  readonly gridMessages = this.selectSignal(
    this.grid,
    grid => grid.messages
  );
  readonly gridProps = this.selectSignal(
    this.grid,
    grid => grid.props
  );
  readonly routes = this.selectSignal(({ routes }) => routes);
  readonly activeRouteId = this.selectSignal(({ activeRoute }) => activeRoute.id);
  readonly unsavedRoutes = this.selectSignal(
    this.routes,
    routes => routes.filter(route => route.status !== BuilderRouteStatus.Saved)
  );
  readonly activeRoute = this.selectSignal(
    this.routes,
    this.activeRouteId,
    (routes, activeRouteId) => routes.find(route => route.routeId === activeRouteId)
  );
  readonly search = this.selectSignal(({ search }) => search);
  readonly searchedRoutes = this.selectSignal(
    this.routes,
    this.search,
    (routes, search) =>
      search ? routes.filter(
        route => Object.values(route).some(value => value?.toString().includes(search))
      ) : routes
  );
  readonly routesCounter = this.selectSignal(
    this.searchedRoutes,
    routes =>
      routes.reduce((acc, route) => ({
        ...acc,
        forward: route.direction === RouteDirection.Forward ? acc.forward + 1 : acc.forward,
        backward: route.direction === RouteDirection.Backward ? acc.backward + 1 : acc.backward
      }), { all: routes.length, forward: 0, backward: 0 })
  );

  private getUpdatedRoutes(routes: BuilderRoute[], updatedRoutes: BuilderRoute[]): BuilderRoute[] {
    return routes.map(route => {
      const routeUpdated = updatedRoutes.find(updatedRoute => updatedRoute.routeId === route.routeId);

      if (routeUpdated) {
        return {
          ...route,
          ...routeUpdated
        };
      }

      return route;
    });
  }

  private getUpdatedRouteIds(routes: BuilderRoute[]): BuilderRoute[] {
    const routeId = -1;
    let idx = -1;

    return routes.map(route => route.routeId <= routeId ? {
      ...route,
      routeId: --idx
    } : route);
  }

  routesSet(data: BuilderRoute[]) {
    this.patchState({ routes: data });
  }

  routesRestore() {
    const state = this.state();

    this.patchState({
      routes: state.routes.map(route => route.routeId === state.activeRoute.id ? {
        ...route,
        ...state.activeRoute.initialState
      } : route)
    });
  }

  routesAdd(route: BuilderRoute, updateNewRouteIds: boolean) {
    if (updateNewRouteIds) {
      this.patchState({ routes: this.getUpdatedRouteIds(this.state().routes) });
    }

    this.patchState({ routes: [ route, ...this.state().routes ] });
  }

  updateRoutes(routes: BuilderRoute[]) {
    this.patchState({ routes: this.getUpdatedRoutes(this.state().routes, routes) });
  }

  searchRoutes(search: string) {
    this.patchState({ search });
  }

  removeRoute(id: number) {
    this.patchState({ routes: this.state().routes.filter(route => route.routeId !== id) });
  }

  routeActiveSet(id: number) {
    this.patchState({
      activeRoute: {
        id,
        initialState: this.state().routes.find(route => route.routeId === id)
      }
    });
  }

  removeExistedRoutes() {
    this.patchState({ routes: this.state().routes.filter(route => route.routeId < 0) });
  }

  routeActiveUpdate(data: any) {
    const state = this.state();

    this.patchState({
      routes: state.routes.map(route =>
        route.routeId === state.activeRoute.id ? { ...route, ...data } : route
      )
    });
  }

  updateRoute(routeId: number, data: Partial<BuilderRoute>) {
    this.patchState({
      routes: this.state().routes.map(route =>
        route.routeId === routeId ? { ...route, ...data } : route
      )
    });
  }

  addRouteFromSummary(data: RoutePlannerSummaryRoute) {
    this.routesAdd(
      {
        status: null,
        routeId: data.routeId,
        code: data.number,
        name: data.name,
        direction: data.direction,
        days: data.activeDays,
        startDate: data.startDate,
        endDate: data.endDate,
        rideStartDateTime: data.startTime,
        rideEndDateTime: data.endTime,
        totalPassengers: data.totalPassengers,
        carTypeName: data.carType ? data.carType.name : null,
        carTypeCapacity: data.carType ? data.carType.seatsCount : null,
        shuttleCompany: data.shuttleCompany ? data.shuttleCompany.name : null,
        timeType: data.timeType,
        locked: false,
        branchNames: data.branchNames
      },
      false
    );
  }

  updateGridProps(data: Partial<BuilderRoutesStateGridProps>) {
    const state = this.state();

    this.patchState({
      grid: {
        ...state.grid,
        props: {
          ...state.grid.props,
          ...data
        }
      }
    });
  }

  columnsFilteredChange(filtered: boolean) {
    const state = this.state();

    this.patchState({
      grid: {
        ...state.grid,
        columns: {
          ...state.grid.columns,
          filtered
        }
      }
    });
  }

  resetColumnsFilterChange(resetFilter: boolean) {
    const state = this.state();

    this.patchState({
      grid: {
        ...state.grid,
        columns: {
          ...state.grid.columns,
          resetFilter
        }
      }
    });
  }

  resetTableColumnsFilters() {
    const resetStorageProps = {
      propName: 'columnsFiltersStore',
      tableName: builderRoutesConfig.tableName,
      userId: this.uGridService.getUserIdFromLocalStorage(),
      saveTableProps: true,
      value: []
    };

    this.uGridService.saveTablePropToLocalStorage(resetStorageProps);
  }

  highlightRoute(value: number) {
    const state = this.state();

    this.patchState({
      grid: {
        ...state.grid,
        rowClassObjects: [ {
          className: 'highlighted-row',
          rowPropertyName: 'routeId',
          value
        } ]
      }
    });
  }

  setMessagesTable(messages: string) {
    this.patchState({
      grid: {
        ...this.state().grid,
        messages
      }
    });
  }

  resetMessagesTable() {
    this.setMessagesTable(builderRoutesConfig.defaultMessagesTable);
  }

  updateNotSavedRoutesAsPending() {
    this.patchState({
      routes: this.state().routes.map((route: BuilderRoute) =>
        ({ ...route, status: route.status !== BuilderRouteStatus.Saved ? BuilderRouteStatus.Pending : route.status })
      )
    });
  }

  updateRouteStatus(id: number, status: number) {
    this.patchState({
      routes: this.state().routes.map(ob => ob.routeId === id ? { ...ob, status } : ob)
    });
  }

  updateRouteErrorMessage(id: number, errorMessage: string) {
    this.patchState({
      routes: this.state().routes.map(route => route.routeId === id ? { ...route, errorMessage } : route)
    });
  }

  clearRoutesErrorMessage() {
    this.patchState({
      routes: this.state().routes.map(route => route.errorMessage ? { ...route, errorMessage: null } : route)
    });
  }

  setRoutesFullChanged(routesFullChanged: boolean) {
    this.patchState({ routesFullChanged });
  }

  updateShowBackdrop(showBackdrop: boolean) {
    this.patchState({ showBackdrop });
  }
}
