<div class="monitoring-dashboard-info__wrapper" *ngIf="ride.details">
  <div class="monitoring-dashboard-info__content" (click)="toggleShowLegends(); track('click on Info icon')">
    <div
      class="monitoring-dashboard-info__route-name"
      [uTooltip]="ride.routeName + ' ' + ride.details.code"
      [container]="'body'"
      [placement]="['bottom', 'top']"
    >
      {{ride.routeName}} {{ride.details.code}}
    </div>
    <div class="monitoring-dashboard-info__route-info">
      <div class="monitoring-dashboard-info__route-distance">
        {{config.dictionary.km | translate}}-
        {{ride.details.distance | kmMileConvertor}}
      </div>
      <div class="monitoring-dashboard-info__route-time">
        {{config.dictionary.time | translate}}-
        {{ride.details.duration}}
      </div>
      <div class="monitoring-dashboard-info__route-icon">
        <img [src]="showLegends ? '/assets/images/common/info-active.svg' : '/assets/images/common/info.svg'">
      </div>
    </div>
  </div>
  <div *ngIf="showLegends" class="monitoring-dashboard-info__legends">
    <div class="monitoring-dashboard-info__legends-header">
      <div class="monitoring-dashboard-info__legends-header-title">
        {{config.dictionary.title | translate}}
      </div>
      <button class="monitoring-dashboard-info__legends-header-close" (click)="toggleShowLegends()">
        <img class="monitoring-dashboard-info__legends-header-close-icon" src="/assets/images/icons/close.svg">
      </button>
    </div>
    <div *ngFor="let item of config.legends" class="monitoring-dashboard-info__legend">
      <img [src]="item.iconUrl" class="monitoring-dashboard-info__legend-icon">
      <div class="monitoring-dashboard-info__legend-title">
        {{item.title | translate}}
      </div>
      <div *ngIf="item.description" class="monitoring-dashboard-info__legend-description">
        {{item.description | translate}}
      </div>
    </div>
  </div>
</div>
