import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';
import * as moment from 'moment';
import { UButtonSize, URangePreset, USelectSItem } from '@shift/ulib';

import {
  TrackingService,
  HeaderDataService
} from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { PassengerAskPopupComponent } from '@app/shared/components';
import {
  ModalActions,
  GeneralOptionsType
} from '@app/shared/models';
import { AuthDataSnapshotService } from '@app/auth/services';
import {
  PassengersAddEditMode,
  PassengersChangesActions
} from '@app/passengers/models';
import { RouteChangeValidationService, RoutesCommonService, RoutesTableService } from '@app/routes/services';
import { PassengersDataService } from '@app/passengers/services';
import { RoutesChangeEmailSendType, RouteRecalculateMode, RouteType } from '@app/routes/models';
import { routesChangePassengerComponentConfig } from './routes-change-passenger.component.config';

@Component({
  selector: 'app-routes-change-passenger',
  templateUrl: './routes-change-passenger.component.html',
  styleUrls: [ './routes-change-passenger.component.scss', './routes-change-passenger.component.rtl.scss' ],
  providers: [ BsModalRef, AuthDataSnapshotService ]
})
export class RoutesChangePassengerComponent implements OnInit, OnDestroy {
  @Input() activeRoute: any;
  @Input() viewportElement: HTMLElement;

  @HostBinding('class') hostClasses: string = 'routes-change-passenger';

  private unsubscribe: Subject<void> = new Subject();

  form: UntypedFormGroup;
  passengersChangesActions = PassengersChangesActions;
  config = cloneDeep(routesChangePassengerComponentConfig);
  actions: USelectSItem[] = this.config.actions.default;
  passengers: any[] = [];
  storeRidePassengers: any[] = [];
  showSaveBtn = false;
  modalContentRef: any;
  routesChangeEmailSendType = RoutesChangeEmailSendType;
  uButtonSize = UButtonSize;

  constructor(
    private fb: UntypedFormBuilder,
    private routesTableService: RoutesTableService,
    private modalService: BsModalService,
    private modalRef: BsModalRef,
    private routesCommonService: RoutesCommonService,
    private headerDataService: HeaderDataService,
    private trackingService: TrackingService,
    private passengersDataService: PassengersDataService,
    private routeChangeValidationService: RouteChangeValidationService,
    private authDataSnapshotService: AuthDataSnapshotService
  ) {}

  ngOnInit() {
    this.createForm();

    if (this.activeRoute) {
      const routeIdForm = this.form.get('routeId');

      routeIdForm.patchValue(this.activeRoute.routeId);
      this.getRidePassenger(this.activeRoute.rideId);
      this.updateCheckDays(this.activeRoute);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    if (this.modalContentRef) {
      this.modalContentRef.unsubscribe();
    }

    this.modalRef.hide();
  }

  get changesForm(): UntypedFormGroup {
    return this.form.get('datesChange') as UntypedFormGroup;
  }

  private createForm() {
    this.form = this.fb.group({
      routeId: [ 0 ],
      action: [ {
        value: (this.activeRoute.isFullManualRide || this.activeRoute.routeType === RouteType.Shuttle) ? PassengersChangesActions.Remove : null,
        disabled: (this.activeRoute.isFullManualRide || this.activeRoute.routeType === RouteType.Shuttle)
      } ],
      passengerId: [ '', Validators.required ],
      datesChange: this.fb.group({
        dates: [ [] ],
        dateFrom: [ '' ],
        dateTo: [ '' ],
        type: [ this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay ],
        availablePresets: [ this.config.availablePresets ],
        checkDaysActive: [ [] ],
        checkDaysAvailable: [ [] ]
      }),
      type: [ 2, Validators.required ],
      routeRecalculateMode: [ RouteRecalculateMode.KeepCurrent, Validators.required ],
      comment: [ '' ],
      removeStation: [ false ],
      removeTarget: [ false ]
    });

    this.onFormValueChanges();
  }

  private openPassengerAddEditModal() {
    this.passengersDataService.openAddEditModal(null, PassengersAddEditMode.AssignToRoute)
      .pipe(
        switchMap(data => data.content.action),
        takeUntil(this.unsubscribe)
      )
      .subscribe(({ type, value }: { type: ModalActions; value: any; }) => {
        switch (type) {
          case ModalActions.Close: {
            this.updatePassengerIdControl();

            break;
          }

          case ModalActions.Submit: {
            const isExistPassenger = this.passengers.some(passenger => passenger.value === value.passengerId);
            const passengerUpdate = { value: value.passengerId, name: `${value.firstName} ${value.lastName}` };

            this.passengers = isExistPassenger ?
              this.passengers.map(passenger => passenger.value === passengerUpdate.value ? passengerUpdate : passenger) :
              [ ...this.passengers, passengerUpdate ];
            this.storeRidePassengers = this.passengers;

            this.updatePassengerIdControl(value.passengerId);

            break;
          }
        }
      });
  }

  private onFormValueChanges() {
    const actionForm = this.form.get('action');
    const typeForm = this.form.get('type');
    const passengerForm = this.form.get('passengerId');
    const recalculateRouteForm = this.form.get('routeRecalculateMode');

    actionForm.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((action: any) => {
        if (action === PassengersChangesActions.Add) {
          this.getCustomerPassenger();
        } else {
          this.passengers = this.storeRidePassengers;
        }
      });

    passengerForm.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (value === GeneralOptionsType.AddNew) {
          this.openPassengerAddEditModal();
        }

        this.showSaveBtn = true;
      });

    typeForm.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(type => this.showSaveBtn = !!(type === 1 && this.form.controls['passengerId'].valid));

    recalculateRouteForm.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(recalculateRoute => {
        this.trackingService.track(`[Route table, Change from table] - Recalculate route - Select ${this.config.recalculateRouteOptionsPlanhat[recalculateRoute]}`);
      });
  }

  private getRidePassenger(rideId: number) {
    this.routesTableService.getRidePassengers(rideId)
      .pipe(first())
      .subscribe(res => {
        if (res.success) {
          this.passengers = res.value.map(passenger => ({ value: passenger.id, name: passenger.name }));

          this.storeRidePassengers = this.passengers;

          this.setActions();
        }
      });
  }

  private setActions() {
    if (this.passengers.length === 0 && !this.activeRoute.isFullManualRide) {
      this.actions = this.config.actions.noPassengers;

      this.form.get('action').patchValue(PassengersChangesActions.Add);
    } else {
      this.form.get('action').patchValue(PassengersChangesActions.Remove);
    }
  }

  private updatePassengerIdControl(newPassengerId?: number) {
    this.form.get('passengerId').patchValue(newPassengerId || '');
  }

  private updatePassengers(passengers: { id: number; name: string; }[]) {
    const updatedPassengers = passengers.map((passenger) => ({ value: passenger.id, name: passenger.name }));

    this.passengers = this.form.get('action').value === PassengersChangesActions.Add && this.authDataSnapshotService.managePassengers() ?
      [
        this.config.addNewPassengerOption,
        ...updatedPassengers
      ] :
      updatedPassengers;
  }

  private getCustomerPassenger() {
    this.routesTableService.getCustomerPassengers()
      .pipe(first())
      .subscribe((passengers: { id: number; name: string; }[]) => this.updatePassengers(passengers));
  }

  private getValueFromForm() {
    const {
      passengerId,
      type,
      comment,
      routeRecalculateMode
    } = this.form.value;

    const dates = this.changesForm.get('dates').value;
    const checkDaysActive = this.changesForm.get('checkDaysActive').value;
    const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
    const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

    return {
      passengerId,
      type,
      comment,
      dateFrom,
      dateTo,
      days: checkDaysActive,
      routeRecalculateMode
    };
  }

  private openAskPassenger(routeId: number, emailSendType?: RoutesChangeEmailSendType) {
    this.modalRef = this.modalService.show(
      PassengerAskPopupComponent,
      {
        class: 'u-modal u-modal_app-passenger-ask-popup',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        keyboard: false
      }
    );

    this.modalContentRef = this.modalRef.content.action
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ type, content }: any) => {
        if (type === 'apply') {
          const checkboxes = content.checkboxes.map(({ value, check }) => ({
            value,
            check
          }));
          const removeStationForm = this.form.get('removeStation');
          const removeTargetFrom = this.form.get('removeTarget');

          checkboxes.forEach(({ value, check }) => {
            switch (value) {
              case 'station': {
                removeStationForm.patchValue(check);
                break;
              }

              case 'destination': {
                removeTargetFrom.patchValue(check);
                break;
              }
            }
          });

          const { removeStation, removeTarget } = this.form.value;
          const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);
          const removeBody = {
            routeId,
            activeDate,
            sendBackgroundEmail: false,
            generateEditableEmail: false,
            value: {
              ...this.getValueFromForm(),
              removeStation,
              removeTarget,
              cancelRideIfNotValid: true
            }
          };

          if (emailSendType) {
            removeBody[emailSendType] = true;
          }

          this.removePassenger(removeBody, routeId);
        }
      });
  }

  private updateCheckDays(route: any) {
    const { routeStartDate, routeEndDate, days } = route;
    const activeDate = this.headerDataService.getActiveDate();

    this.changesForm.patchValue({
      dates: [ activeDate ],
      dateFrom: routeStartDate,
      dateTo: routeEndDate,
      checkDaysActive: [ moment(activeDate).get('day') ],
      checkDaysAvailable: days
    });
  }

  private addPassenger(params: any, routeId: any) {
    this.routeChangeValidationService.validationAddPassenger(params)
      .pipe(
        switchMap(() => this.routesTableService.changeAddPassenger(params))
      )
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }

  private removePassenger(params: any, routeId: any) {
    this.routeChangeValidationService.validationRemovePassenger(params)
      .pipe(
        switchMap(() => this.routesTableService.changeRemovePassenger(params))
      )
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }

  updatePeriod(data: any) {
    this.changesForm.patchValue({
      dates: data.dates,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });
  }

  updateDates({ dates, checkDaysActive }): void {
    const datesStore: string[] = this.changesForm.get('dates').value;

    if (dates && dates.length) {
      if (!isEqual(dates, datesStore)) {
        this.changesForm.patchValue({
          dates: dates.map((item: string) => moment(item).startOf('day').format(AppConstants.DATE_FORMAT_ISO)),
          checkDaysActive
        });
      }
    }
  }

  saveChanges(emailSendType?: RoutesChangeEmailSendType) {
    const formValue = this.getValueFromForm();
    const { action, routeId } = this.form.getRawValue();
    const activeDate = moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);

    if (!formValue.passengerId) { return; }

    const body = {
      routeId,
      activeDate,
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        ...formValue
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    switch (action) {
      case PassengersChangesActions.Add: {
        this.addPassenger(body, routeId);
        break;
      }

      case PassengersChangesActions.Remove: {
        this.openAskPassenger(routeId, emailSendType);
        break;
      }
    }
  }
}
