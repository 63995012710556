import { UGridNgxDatatable } from '@shift/ulib';
import { TemplateRef } from '@angular/core';

import { AuthModuleFeature, AuthPermission } from '@app/auth/models';

export interface GridColumn extends UGridNgxDatatable.TableColumn {
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  permission?: AuthPermission;
  hideable?: boolean;
  filterType?: string;
  filterTemplate?: TemplateRef<any>;
  filterTypeItemsFn?: Function;
  filterTypeItemsSearchFn?: Function;
  filterTypeItemsArraySearchFn?: Function;
  filterSortingFunction?: Function;
  hideColumn?: boolean;
  custom?: boolean;
  cellTemplateName?: string;
  propAlias?: string;
  cellClassFnName?: string;
  cellClassName?: string;
}
