import { UMenuIcon, UMenuIconChild } from '@shift/ulib';

import { AuthModuleFeature, AuthModuleFeatureGroup, AuthPermission, AuthPermissionGroup } from '@app/auth/models';

export enum HeaderMenuIconsTemplate {
  Accompanies = 'accompanies',
  Branches = 'branches',
  BuilderDefaultRoutes = 'builderDefaultRoutes',
  BuilderDefaultTemplates = 'builderDefaultTemplates',
  BuilderFull = 'builderFull',
  BuilderFullResults = 'builderFullResults',
  Contracts = 'contracts',
  Drivers = 'drivers',
  Passengers = 'passengers',
  PassengersArmy = 'passengersArmy',
  PassengersMunicipality = 'passengersMunicipality',
  Reports = 'reports',
  RideSupervisors = 'rideSupervisors',
  RideOrders = 'rideOrders',
  RouteTemplates = 'routeTemplates',
  RoutesDaily = 'routesDaily',
  RoutesSC = 'routesSC',
  RoutesWeekly = 'routesWeekly',
  Schools = 'schools',
  DemandsPassengersView = 'demandsPassengersView',
  DemandsShiftsView = 'demandsShiftsView',
  ShuttleCompanies = 'shuttleCompanies',
  ShuttleCompaniesGeneral = 'shuttleCompaniesGeneral',
  ShuttleCompaniesCustomers = 'shuttleCompaniesCustomers',
  Stations = 'Stations'
}

export interface HeaderMenuIcon extends UMenuIcon {
  replaceByOneChild?: boolean;
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
  activateOnClick?: boolean;
  children?: HeaderMenuIconChild[];
  permission?: AuthPermission;
  permissionGroup?: AuthPermissionGroup;
}

export interface HeaderMenuIconChild extends UMenuIconChild {
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
  permission?: AuthPermission;
  permissionGroup?: AuthPermissionGroup;
}

export enum HeaderMenuIconValue {
  Activities = 'activities',
  MonitoringDashboard = 'monitoring-dashboard',
  Feed = 'feed',
  Import = 'import',
  Notes = 'notes',
  NewBuilder = 'new-builder',
  NewBuilderFull = 'new-builder-full',
  NewPassenger = 'new-passenger',
  NextYear = 'next-year',
  Plus = 'plus',
  Refresh = 'refresh',
  SendChanges = 'send-changes',
  PassengersImport = 'passengers-import',
  ImportContract = 'import-contract',
  MovePassengers = 'move-passengers'
}
