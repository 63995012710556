import { Observable, fromEvent, merge, combineLatest, interval, of } from 'rxjs';
import { distinctUntilChanged, share, filter, switchMap, map } from 'rxjs/operators';
import { KeyCode } from '@shift/ulib';

export const fromEventCustom = (target, eventName, type = false) => new Observable((subscriber) => {
  const handler = event => subscriber.next(event);

  // Add the event handler to the target
  target.addEventListener(eventName, handler, type);

  return () => {
    // Detach the event handler from the target
    target.removeEventListener(eventName, handler);
  };
});

export const counterTimer = (count: number, intervalTime: number) => of(new Date())
  .pipe(
    switchMap(startDate =>
      interval(intervalTime)
        .pipe(
          map(() => count - Math.round((new Date().valueOf() - startDate.valueOf()) / 1000))
        )
    )
  );

export const shortcut = (keyCodes: KeyCode[]) => {
  const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown');
  const keyUp$ = fromEvent<KeyboardEvent>(document, 'keyup');

  const keyEvents = merge(keyDown$, keyUp$).pipe(
    distinctUntilChanged((prev, next) => prev.code === next.code && prev.type === next.type),
    share()
  );

  const createKeyPressStream = (charCode: KeyCode) =>
    keyEvents.pipe(filter(event => event.code === charCode.valueOf()));

  return combineLatest(keyCodes.map(el => createKeyPressStream(el)))
    .pipe(
      filter<KeyboardEvent[]>(arr => arr.every(ob => ob.type === 'keydown'))
    );
};

export function sequence() {
  return (source: Observable<KeyboardEvent[]>) => source.pipe(
    filter(arr => {
      const sorted = [ ...arr ]
        .sort((prev, next) => (prev.timeStamp < next.timeStamp ? -1 : 1))
        .map(ob => ob.code)
        .join();

      const seq = arr.map(ob => ob.code).join();

      return sorted === seq;
    })
  );
}

export function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export function isNotNullOrUndefined<T>() {
  return (source$: Observable<null | undefined | T>): Observable<T> => source$.pipe(filter(inputIsNotNullOrUndefined));
}
