import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { BuilderRouteAddEdit } from '@app/builder/models';

@Injectable({
  providedIn: 'root'
})
export class BuilderCommonService {
  private close: Subject<void> = new Subject();
  private saveRoute: Subject<void> = new Subject();
  private selectedPassengerId: BehaviorSubject<number> = new BehaviorSubject(null);
  private editRouteId: BehaviorSubject<number> = new BehaviorSubject(null);
  private editRouteData: BehaviorSubject<BuilderRouteAddEdit> = new BehaviorSubject(null);
  private editRouteChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private activeRouteData: BehaviorSubject<BuilderRouteAddEdit> = new BehaviorSubject(null);
  private lastEditedRouteId: BehaviorSubject<number> = new BehaviorSubject(null);
  private isExpanded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  close$ = this.close.asObservable();
  saveRoute$ = this.saveRoute.asObservable();
  selectedPassengerId$ = this.selectedPassengerId.asObservable();
  editRouteId$ = this.editRouteId.asObservable();
  editRouteData$ = this.editRouteData.asObservable();
  editRouteChanged$ = this.editRouteChanged.asObservable();
  activeRouteData$ = this.activeRouteData.asObservable();
  lastEditedRouteId$ = this.lastEditedRouteId.asObservable();
  isExpanded$ = this.isExpanded.asObservable();

  getSelectedPassengerId() {
    return this.selectedPassengerId.getValue();
  }

  getEditRouteId() {
    return this.editRouteId.getValue();
  }

  getEditRouteChanged() {
    return this.editRouteChanged.getValue();
  }

  getActiveRouteData() {
    return this.activeRouteData.getValue();
  }

  updateSelectedPassengerId(passengerId: number) {
    this.selectedPassengerId.next(passengerId);
  }

  editRouteIdSet(id: number) {
    this.editRouteId.next(id);
    this.editRouteChanged.next(false);
  }

  lastEditedRouteIdSet(id: number) {
    this.lastEditedRouteId.next(id);
  }

  editRouteDataSet(data: BuilderRouteAddEdit) {
    this.editRouteData.next(data);
  }

  updateActiveRouteData(data: BuilderRouteAddEdit) {
    this.activeRouteData.next(data);
  }

  editRouteChangedSet(value: boolean) {
    this.editRouteChanged.next(value);
  }

  closeAction() {
    this.close.next();
  }

  saveRouteAction() {
    this.saveRoute.next();
  }

  toggleIsExpanded() {
    this.isExpanded.next(!this.isExpanded.value);
  }
}
