export class AppConstants {
  public static DATE_FORMAT_ISO: string = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
  public static DATE_FORMAT_BASE_LINE: string = 'YYYY-MM-DD';
  public static DATE_FORMAT_BASE_HYPHEN: string = 'DD-MM-YYYY';
  public static DATE_FORMAT_BASE_SLASH: string = 'DD/MM/YYYY';
  public static DATE_FORMAT_BASE_SLASH_SHORT: string = 'DD/MM/YY';
  public static DATE_FORMAT_BASE_SLASH_SIMPLE: string = 'DD/MM';
  public static DATE_FORMAT_BASE_SLASH_TIME: string = 'DD/MM/YYYY HH:mm';
  public static DATE_FORMAT_BASE_SLASH_COMMA_TIME: string = 'DD/MM/YYYY, HH:mm';
  public static DATE_FORMAT_BASE_SLASH_SHORT_TIME: string = 'DD/MM/YY HH:mm';
  public static DATE_FORMAT_BASE_SLASH_SHORT_COMMA_TIME: string = 'DD/MM/YY, HH:mm';
  public static DATE_FORMAT_BASE_DOT: string = 'DD.MM.YYYY';
  public static DATE_FORMAT_BASE_DOT_SHORT: string = 'DD.MM.YY';
  public static DATE_FORMAT_BASE_DOT_DD_MM: string = 'DD.MM';
  public static DATE_FORMAT_BASE_DOT_DDD_DD_MM: string = 'ddd DD.MM';
  public static DATE_FORMAT_BASE_TIME_LINE: string = 'YYYY-MM-DD[T]HH:mm';
  public static TIME_FORMAT: string = 'HH:mm';
  public static TIME_FORMAT_FULL: string = 'HH:mm:ss';
  public static TIME_FORMAT_MOMENT_MIN: string = '*hh:mm';

  // eslint-disable-next-line max-len
  public static EMAIL_REG_EXP: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

  public static BIRTH_DATE_REG_EXP: RegExp = /^(0?[1-9]|[12][0-9]|3[01])[\.\-](0?[1-9]|1[012])[\.\-](19\d\d|200\d|20\d\d)$/;

  public static NUMBER_FORMAT: string = '1.0-2';
  public static MILES_FACTOR: number = 0.621371;

  public static NUMBER_LOCALE: string = 'en-IL';

  public static VAT_RATE: number = 0.17;
}
