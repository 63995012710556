import { UMultiselectItem, USelectSItem } from '@shift/ulib';

import { AuthModuleFeature, AuthModuleFeatureGroup, AuthPermission } from '@app/auth/models';

export enum AddEditModalAction {
  Submit = 'submit',
  Add = 'add',
  Edit = 'edit',
  Delete = 'delete',
  ShutDown = 'shutDown'
}

export enum AddEditModalInputType {
  Text = 'text',
  Number = 'number',
  Textarea = 'textarea',
  Select = 'select',
  Toggle = 'toggle',
  Address = 'address',
  Date = 'date',
  Multiselect = 'multiselect'
}

export class AddEditModalConfig {
  id?: string;
  title: string;
  tabs: AddEditModalTab[];
  buttons?: AddEditModalButton[];
  categoriesToFetch?: string[];
  showInvalidTabIndicator?: boolean;
  hiddenDataFields?: AddEditModalDataField[];
  dictionary?: AddEditModalDictionary;
  tracking?: AddEditModalTracking;
  fieldsMessage?: { [key: string]: string };
  noMarginTabs?: string[];
  noVerticalScrollTabs?: string[];
}

export class AddEditModalTab {
  title?: string;
  apiPath?: string;
  dataName: string; // Used as an id && if formGroupTab = true --> represents tab dataProp name inside dataObject
  subTabs?: AddEditModalTab[];
  formGroupTab?: boolean;
  formGroupAutoInitTab?: boolean;
  formCustom?: boolean;
  fullHeight?: boolean;
  columns: AddEditModalColumn[];
  multipleRows?: boolean;
  showInEditModeOnly?: boolean;
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
}

export class AddEditModalColumn {
  title?: string;
  fields?: AddEditModalField[];
  subtitle?: string;
  nextRow?: boolean;
  feature?: AuthModuleFeature;
}

export class AddEditModalField {
  id?: string;
  title?: string;
  subscribeForChanges?: boolean;
  subscribeForEventData?: boolean;
  subscribeForBlur?: boolean;
  subscribeForValidAndDirtyBlur?: boolean;
  dataField?: AddEditModalDataField;
  dataFields?: AddEditModalDataField[];
  inputType?: AddEditModalInputType;
  loadComponent?: any;
  placeholder?: string;
  config?: AddEditModalFieldConfig;
  conditions?: {
    show?: {
      fieldName: string;
      values: any[];
    }
  };
}

export class AddEditModalDataField {
  name?: string;
  required?: boolean;
  defaultValue?: any;
  unwrapped?: boolean;
  readonly?: boolean;
  editReadonly?: boolean;
  isArray?: boolean;
  isGroup?: boolean;
  customValidatorName?: string;
  arrayDataFields?: AddEditModalDataField[];
  groupDataFields?: AddEditModalDataField[];
  feature?: AuthModuleFeature;
}

export class AddEditModalFieldConfig {
  selectItems?: (USelectSItem | UMultiselectItem)[];
  selectItemsMetadataKey?: string;
  maxLength?: number;
  inputTypeNumber?: {
    decimal?: boolean;
    maxDecimalDigits?: number;
    startWithZero?: boolean;
    min?: number;
    max?: number;
  };
  inputTypeSelect?: {
    empty?: boolean;
  };
  inputTypeDate?: {
    disableDaysAfterToday?: boolean;
  };
}

export class AddEditModalDictionary {
  yes?: string;
  no?: string;
  error?: string;
  submitSuccess?: string;
  deleteConfirm?: string;
}

export class AddEditModalButton {
  name: string;
  clear: boolean;
  disabled?: boolean;
  action: AddEditModalAction;
  permission?: AuthPermission;
}

export class AddEditModalFieldChange {
  newVal: any;
  fieldName: string;
  itemIndex?: number;
}

export class AddEditModalFieldBlur {
  fieldName: string;
  value: number | string;
  type: AddEditModalInputType;
}

export interface AddEditModalTracking {
  [AddEditModalAction.Add]?: string;
  [AddEditModalAction.Edit]?: string;
  [AddEditModalAction.Delete]?: string;
}

export interface AddEditModalActionEvent<T> {
  type: AddEditModalAction;
  data?: T;
}
