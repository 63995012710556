import { ChangeDetectorRef, computed, DestroyRef, Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { NgProgress } from 'ngx-progressbar';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeAll,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap
} from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep, isArray, mergeWith, omit } from 'lodash';
import { toSignal } from '@angular/core/rxjs-interop';
import { KeyCode, UMultiselectItem, UPopupService } from '@shift/ulib';

import {
  BuilderRoute,
  BuilderRouteStatus,
  BuilderTimeType,
  BuilderFullCostType,
  BuilderFullRoutesFor,
  BuilderFullVisibleComponents,
  BuilderFullStep,
  BuilderFullOptions,
  BuilderFullConfigData,
  BuilderFullFilterDefault
} from '@app/builder/models';
import {
  RoutePlannerDirectionType,
  RoutePlannerErrorTemplate,
  RoutePlannerRouteGenerationMode,
  RoutePlannerShift
} from '@app/route-planner/models';
import {
  CitiesCombinationsByBranchService,
  HeaderSearchFiltersService,
  MasterCustomerService,
  OperationGuidService,
  RoutePolicyService,
  TrackingService
} from '@app/shared/services';
import { DaysOfWeek, Errors, NavigationPaths, RoutePolicy } from '@app/shared/models';
import { sequence, shortcut, transformToInputCities } from '@app/shared/utils';
import { appConfig } from '@app/shared/configs';
import { AuthDataService } from '@app/auth/services';
import { PassengersExtraFilters } from '@app/passengers/models';
import {
  AuthModuleName,
  AuthModuleRoutePlannerFeature,
  AuthModuleRoutePlannerFeatureShuttleCompanyDefaultCostType,
  AuthModuleRoutePlannerFeatureType,
  AuthOptionalModule
} from '@app/auth/models';
import { AuthDataSnapshotService } from '@app/auth/services';
import { RoutesFacade } from '@app/routes/state/facades';
import { builderFullConfig } from '@app/builder/configs';
import { BuilderRoutesPassengersInfoStore, BuilderRoutesStore } from '@app/builder/stores';
import { RoutePlannerService, RoutePlannerHubService } from '@app/route-planner/services';
import { BuilderFullCommonService } from './builder-full-common.service';
import { BuilderFullCalculationStoreService } from './builder-full-calculation-store.service';

@Injectable()
export class BuilderFullCalculationService implements OnDestroy {
  private ridesWithoutShuttleCountText: string;
  private unsubscribe: Subject<void> = new Subject();
  private savingRoutes: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private summaryLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private errorTemplatesByErrorCode: { [key in Errors]: TemplateRef<any>; };

  readonly userInfo$ = this.authDataService.userInfo$.pipe(take(1));
  readonly routePlannerType$ = this.userInfo$.pipe(map(userInfo => userInfo.modules?.routePlanner?.type));
  readonly routePlannerTypeGeneric$ = this.routePlannerType$.pipe(map(type => type === AuthModuleRoutePlannerFeatureType.Generic));
  readonly routePlannerTypeShuttleCompany$ = this.routePlannerType$.pipe(map(type => type === AuthModuleRoutePlannerFeatureType.ShuttleCompany));
  readonly savingRoutes$: Observable<boolean> = this.savingRoutes.asObservable();
  readonly summaryLoading$: Observable<boolean> = this.summaryLoading.asObservable();
  readonly subCustomers$ = this.masterCustomerService.getSubCustomers()
    .pipe(
      map(items => items.map(item => ({ value: item.id, name: item.name }))),
      tap(items => this.subCustomers = items)
    );

  readonly userInfo = toSignal(this.userInfo$);
  readonly routePlannerTypeGeneric = toSignal(this.routePlannerTypeGeneric$);
  readonly routePlannerTypeShuttleCompany = toSignal(this.routePlannerTypeShuttleCompany$);
  readonly customers = toSignal(this.routePlannerTypeShuttleCompany$
    .pipe(
      switchMap(data => data ? this.routePlannerService.getCustomers() : of([]))
    ),
  { initialValue: [] }
  );
  readonly customerItems = computed(() => this.customers().map(item => ({ value: item.id, name: item.name })));
  readonly routePlannerMasterCustomer = computed(() => !!(this.userInfo().modules?.routePlanner?.masterCustomer));

  form: UntypedFormGroup = this.formBuilder.group({});
  filterDatesRangeDefault = {
    dates: [],
    checkDaysActive: [],
    checkDaysAvailable: [ DaysOfWeek.Sunday, DaysOfWeek.Monday, DaysOfWeek.Tuesday, DaysOfWeek.Wednesday, DaysOfWeek.Thursday, DaysOfWeek.Friday, DaysOfWeek.Saturday ]
  };
  filterDatesRange = cloneDeep(this.filterDatesRangeDefault);
  passengerIds: number[] = [];
  sessionGuid: string;
  saveProgress: number = 0;
  saveProgressInc: number = 0;
  saveProgressStarted: boolean = false;
  passengersExtraFiltersDefault: PassengersExtraFilters = {
    values: {
      byDemand: true,
      directions: null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      routesFor: null,
      customerId: null
    },
    originalOptions: {
      shifts: null
    },
    options: {
      shifts: null
    }
  };
  passengersExtraFilters: PassengersExtraFilters = cloneDeep(this.passengersExtraFiltersDefault);
  filterDefault: BuilderFullFilterDefault;
  blockedAlgorithmHotkeys: boolean = false;
  subscriptions: Subscription = new Subscription();
  subCustomers: UMultiselectItem[];
  selectedSubCustomers: UMultiselectItem[];
  isAfterSchoolActivitiesEnabled: boolean;

  get optionsDefault(): BuilderFullOptions {
    return this.builderFullCalculationStoreService.optionsDefault;
  }

  get options(): BuilderFullOptions {
    return this.builderFullCalculationStoreService.options;
  }

  get configData(): BuilderFullConfigData {
    return this.builderFullCalculationStoreService.configData;
  }

  get visibleComponents(): BuilderFullVisibleComponents {
    return this.builderFullCommonService.visibleComponents;
  }

  get passengersCount(): number {
    return this.passengerIds.length;
  }

  get calculationAllowed(): boolean {
    return this.form.valid && this.passengersCount > 0;
  }

  get filter(): UntypedFormGroup {
    return this.form.get('filter') as UntypedFormGroup;
  }

  get routePolicy(): UntypedFormGroup {
    return this.form.get('routePolicy') as UntypedFormGroup;
  }

  get vehicleTypes(): UntypedFormControl {
    return this.form.get('vehicleTypes') as UntypedFormControl;
  }

  constructor(
    private destroyRef: DestroyRef,
    private cd: ChangeDetectorRef,
    private router: Router,
    private ngProgress: NgProgress,
    private formBuilder: UntypedFormBuilder,
    private uPopupService: UPopupService,
    private authDataSnapshotService: AuthDataSnapshotService,
    private routePlannerService: RoutePlannerService,
    private routePlannerHubService: RoutePlannerHubService,
    private builderFullCommonService: BuilderFullCommonService,
    private translateService: TranslateService,
    private operationGuidService: OperationGuidService,
    private builderFullCalculationStoreService: BuilderFullCalculationStoreService,
    private trackingService: TrackingService,
    private authDataService: AuthDataService,
    private routesFacade: RoutesFacade,
    private citiesCombinationsByBranchService: CitiesCombinationsByBranchService,
    private headerSearchFiltersService: HeaderSearchFiltersService,
    private routePolicyService: RoutePolicyService,
    private masterCustomerService: MasterCustomerService,
    private builderRoutesPassengersInfoStore: BuilderRoutesPassengersInfoStore,
    public builderRoutesStore: BuilderRoutesStore
  ) {
    this.init();
    this.getTranslation();
    this.setVehicleTypes();
    this.initAlgorithmHotkeys();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.routePlannerHubService.stop();
    this.operationGuidService.removeGuid();
  }

  private getTranslation() {
    this.translateService.get(builderFullConfig.dictionary.ridesWithoutShuttleCount)
      .pipe(take(1))
      .subscribe(translated => this.ridesWithoutShuttleCountText = translated);
  }

  private trackEvent(event: string) {
    this.trackingService.track(`[${builderFullConfig.trackingId}] - ${event}`);
  }

  updateErrorTemplatesByErrorCode(code: Errors, templateRef: TemplateRef<any>) {
    this.errorTemplatesByErrorCode = {
      ...this.errorTemplatesByErrorCode,
      [code]: templateRef
    };
  }

  initConfigData(customerId?: number): void {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();

    this.routePlannerService
      .get(customerId)
      .pipe(
        tap(data => {
          this.sessionGuid = data.sessionId;

          this.operationGuidService.setGuid(data.sessionId);

          this.initRoutePlannerHub(data.sessionId);
        })
      )
      .subscribe((data: BuilderFullConfigData) => {
        this.builderFullCalculationStoreService.updateConfigData(data);

        this.citiesCombinationsByBranchService.initBranchesData(
          data.cityCombinations.rules?.[0]?.branchId,
          data.branches.map(({ id, name }) => ({ value: id, name }))
        );

        this.citiesCombinationsByBranchService.updateCitiesCombinationsByBranchId(
          data.cityCombinations.rules,
          transformToInputCities(data.cityCombinations.citiesTree)
        );

        this.citiesCombinationsByBranchService.updateCustomerId(customerId);

        this.citiesCombinationsByBranchService.onBranchFormValueChanges();

        this.setRoutePolicy(data.routePolicy);

        const passengersExtraFiltersDefault = cloneDeep(this.passengersExtraFiltersDefault);

        this.passengersExtraFilters = {
          ...passengersExtraFiltersDefault,
          values: {
            ...passengersExtraFiltersDefault,
            customerId
          }
        };

        const byDemand = this.configData.isDemandsPlanningEnabled;

        this.filter.get('byDemand').patchValue(byDemand, { emitEvent: false });

        if (byDemand) {
          this.filter.get('byDemand').enable({ emitEvent: false });
        } else {
          this.filter.get('byDemand').disable({ emitEvent: false });
        }

        this.passengersExtraFiltersDefault.values.byDemand = byDemand;
        this.passengersExtraFilters.values.byDemand = byDemand;

        if (this.isAfterSchoolActivitiesEnabled) {
          this.filter.get('routesFor').patchValue(this.configData.isDemandsPlanningEnabled ? BuilderFullRoutesFor.All : BuilderFullRoutesFor.Schools, { emitEvent: false });

          if (this.configData.isDemandsPlanningEnabled) {
            this.filter.get('routesFor').enable({ emitEvent: false });
          } else {
            this.filter.get('routesFor').disable({ emitEvent: false });
          }
        }

        this.refreshPassengersExtraFilters(data);
        this.onFilterChanges();
      });
  }

  onFilterChanges(): void {
    for (const filterControlKey of Object.keys(this.filter.controls)) {
      this.subscriptions.add(this.filter.get(filterControlKey).valueChanges.subscribe(() => {
        this.builderRoutesStore.setRoutesFullChanged(true);

        if (filterControlKey !== 'subCustomers') {
          const branchIds = <number[]>this.headerSearchFiltersService.getFiltersValue().branches;

          if (branchIds) {
            this.refreshPassengersExtraFilters(this.builderFullCalculationStoreService.configData, branchIds);
          }
        }
      }));
    }
  }

  refreshPassengersExtraFilters(data: BuilderFullConfigData, branchIds?: number[]): void {
    if (data.shifts) {
      if (branchIds && branchIds.length) {
        const mainBranchId = data.branches.find(ob => ob.isMain).id;
        const shifts = data.shifts
          .filter(ob => branchIds.includes(ob.branchId) || ob.branchId === mainBranchId)
          .map(ob => ({ value: ob.id, name: ob.name }));

        this.updatePassengersExtraFilters({
          originalOptions: {
            shifts: data.shifts
          },
          options: {
            shifts
          }
        });
      } else {
        this.updatePassengersExtraFilters({
          originalOptions: {
            shifts: data.shifts
          },
          options: {
            shifts: data.shifts.map((ob: RoutePlannerShift) => ({ value: ob.id, name: ob.name }))
          }
        });
      }
    }
  }

  init = (): void => {
    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(user => {
        this.isAfterSchoolActivitiesEnabled = user.optionalModules.includes(AuthOptionalModule.AfterSchoolActivities);

        const shuttleCompanyDefaultCostType = user.modules[AuthModuleName.RoutePlanner][AuthModuleRoutePlannerFeature.ShuttleCompanyDefaultCostType];

        this.form.setControl('filter', this.generateFilter(
          shuttleCompanyDefaultCostType === AuthModuleRoutePlannerFeatureShuttleCompanyDefaultCostType.FixedCost
        ));

        if (this.routePlannerTypeShuttleCompany()) {
          this.updateRoutePlannerTypeShuttleCompanyFieldsState();
        }

        if (this.routePlannerTypeGeneric()) {
          this.initConfigData();
        }
      });
  };

  setRoutePolicy = (data: RoutePolicy): void => {
    this.form.setControl('routePolicy', this.routePolicyService.generateRoutePolicy(data));
  };

  setVehicleTypes = (): void => {
    this.form.setControl('vehicleTypes', new UntypedFormControl([]));
  };

  private updateRoutePlannerTypeShuttleCompanyFieldsState() {
    const valid = this.filter.get('customerId').valid;
    const fields = [ 'direction', 'startDate', 'startTime', 'endTime', 'costType', 'mode' ];

    for (const field of fields) {
      if (valid) {
        this.filter.get(field).enable({ emitEvent: false });
      } else {
        this.filter.get(field).disable({ emitEvent: false });
      }
    }
  }

  generateFilter(isFixedCost: boolean): UntypedFormGroup {
    this.filterDefault = {
      direction: null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      activeDays: [],
      byDemand: false,
      shuttleCompanyId: null,
      calculationTimeType: BuilderTimeType.StartTime,
      customTime: null,
      costType: isFixedCost ? BuilderFullCostType.FixedPrice : BuilderFullCostType.AccordingToContract,
      fixedCost: isFixedCost ? 0 : null,
      mode: RoutePlannerRouteGenerationMode.New,
      routeTemplateIds: [],
      minimizeVehicleType: false,
      allowMultipleTemplateUsage: false
    };

    const filterForm = this.formBuilder.group({
      direction: [ this.filterDefault.direction, [ Validators.required ] ],
      startDate: [ this.filterDefault.startDate, [ Validators.required ] ],
      startTime: [ this.filterDefault.startTime ],
      endDate: [ this.filterDefault.endDate, [ Validators.required ] ],
      endTime: [ this.filterDefault.endTime ],
      activeDays: [ this.filterDefault.activeDays, [ Validators.required ] ],
      byDemand: [ { value: this.filterDefault.byDemand, disabled: true } ],
      shuttleCompanyId: [ this.filterDefault.shuttleCompanyId ],
      calculationTimeType: [ { value: this.filterDefault.calculationTimeType, disabled: true } ],
      customTime: [ { value: this.filterDefault.customTime, disabled: true } ],
      costType: [ this.filterDefault.costType ],
      fixedCost: [ this.filterDefault.fixedCost ],
      mode: [ this.filterDefault.mode ],
      routeTemplateIds: [ this.filterDefault.routeTemplateIds ],
      minimizeVehicleType: [ this.filterDefault.minimizeVehicleType ],
      allowMultipleTemplateUsage: [ this.filterDefault.allowMultipleTemplateUsage ]
    });

    if (this.isAfterSchoolActivitiesEnabled) {
      filterForm.addControl('routesFor', this.formBuilder.control({
        value: BuilderFullRoutesFor.Schools,
        disabled: true
      }));
    }

    if (this.routePlannerMasterCustomer()) {
      filterForm.addControl('subCustomers', new UntypedFormControl([], { validators: [ Validators.required ], updateOn: 'blur' }));
    }

    if (this.routePlannerTypeShuttleCompany()) {
      filterForm.addControl('customerId', new UntypedFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }));
    }

    const subCustomers = filterForm.get('subCustomers');
    const customerId = filterForm.get('customerId');
    const directionForm = filterForm.get('direction');
    const startDateForm = filterForm.get('startDate');
    const startTimeForm = filterForm.get('startTime');
    const endDateForm = filterForm.get('endDate');
    const endTimeForm = filterForm.get('endTime');
    const routesForForm = filterForm.get('routesFor');
    const byDemandForm = filterForm.get('byDemand');
    const shuttleCompanyIdForm = filterForm.get('shuttleCompanyId');
    const calculationTimeTypeForm = filterForm.get('calculationTimeType');
    const customTimeForm = filterForm.get('customTime');
    const costTypeForm = filterForm.get('costType');
    const fixedCostForm = filterForm.get('fixedCost');
    const modeForm = filterForm.get('mode');
    const routeTemplateIdsForm = filterForm.get('routeTemplateIds');
    const minimizeVehicleTypeForm = filterForm.get('minimizeVehicleType');
    const allowMultipleTemplateUsageForm = filterForm.get('allowMultipleTemplateUsage');

    if (subCustomers) {
      subCustomers
        .valueChanges
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.unsubscribe)
        )
        .subscribe(data => {
          this.trackEvent('select customers');

          this.selectedSubCustomers = this.subCustomers.filter(subCustomer => data.includes(subCustomer.value));
        });
    }

    if (customerId) {
      customerId
        .valueChanges
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.unsubscribe)
        )
        .subscribe(data => {
          this.filter.patchValue(this.filterDefault);
          this.filterDatesRange = cloneDeep(this.filterDatesRangeDefault);
          this.builderFullCommonService.updatePassengers({
            selected: [],
            selectedItems: []
          });
          this.updatePassengerIds([]);
          this.initConfigData(data);
          this.updateRoutePlannerTypeShuttleCompanyFieldsState();
        });
    }

    const timeFormValidators = (value: boolean) => {
      if (value) {
        customTimeForm.clearValidators();
        customTimeForm.updateValueAndValidity();
      } else {
        customTimeForm.setValidators([ Validators.required ]);
        customTimeForm.updateValueAndValidity();
      }
    };

    timeFormValidators(byDemandForm.value);

    byDemandForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((byDemand: boolean) => {
        this.updatePassengersExtraFilters({
          values: {
            byDemand
          }
        });

        if (byDemand) {
          this.trackEvent('click on demands');
        } else {
          this.trackEvent('click on manual');

          startTimeForm.patchValue(null);
          endTimeForm.patchValue(null);

          this.updatePassengersExtraFilters({
            values: {
              startTime: null,
              endTime: null
            }
          });
        }

        timeFormValidators(byDemand);
      });

    directionForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((value: number) => {
        this.trackEvent('select direction');

        if (value === RoutePlannerDirectionType.Forward) {
          calculationTimeTypeForm.patchValue(BuilderTimeType.EndTime);
          calculationTimeTypeForm.enable();

          this.builderFullCalculationStoreService.updateOptions({
            timeTypes: this.optionsDefault.timeTypes
          });
        }

        if (value === RoutePlannerDirectionType.Backward) {
          calculationTimeTypeForm.patchValue(BuilderTimeType.StartTime);
          calculationTimeTypeForm.enable();

          this.builderFullCalculationStoreService.updateOptions({
            timeTypes: this.optionsDefault.timeTypes.filter(ob => ob.value !== RoutePlannerDirectionType.BackwardAndForward)
          });
        }

        if (value === RoutePlannerDirectionType.BackwardAndForward || routesForForm && routesForForm.value === BuilderFullRoutesFor.Activities) {
          byDemandForm.disable();
          byDemandForm.patchValue(true);
          calculationTimeTypeForm.patchValue(null);
        } else if (this.configData.isDemandsPlanningEnabled) {
          byDemandForm.enable();
        }

        customTimeForm.enable();
        customTimeForm.patchValue(null);

        this.updatePassengersExtraFilters({
          values: {
            directions: value === RoutePlannerDirectionType.BackwardAndForward ?
              [ RoutePlannerDirectionType.Forward, RoutePlannerDirectionType.Backward ] :
              [ value ]
          }
        });
      });

    startDateForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(startDate => {
        this.trackEvent('select start date');
        this.updatePassengersExtraFilters({
          values: {
            startDate
          }
        });
      });

    startTimeForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.trackEvent('set start time');
        this.updatePassengersExtraFilters({
          values: {
            startTime: this.filter.get('startTime').value
          }
        });
      });

    endDateForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(endDate => {
        this.trackEvent('select end date');
        this.updatePassengersExtraFilters({
          values: {
            endDate
          }
        });
      });

    endTimeForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.trackEvent('set end time');
        this.updatePassengersExtraFilters({
          values: {
            endTime: this.filter.get('endTime').value
          }
        });
      });

    if (routesForForm) {
      routesForForm
        .valueChanges
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.unsubscribe)
        )
        .subscribe((routesFor: BuilderFullRoutesFor) => {
          this.trackEvent('select routes for');
          this.updatePassengersExtraFilters({
            values: {
              routesFor
            }
          });

          modeForm.patchValue(RoutePlannerRouteGenerationMode.New);

          if (routesFor === BuilderFullRoutesFor.Activities) {
            byDemandForm.disable();
            customTimeForm.disable();
            calculationTimeTypeForm.disable();

            byDemandForm.patchValue(true);
            customTimeForm.patchValue(null);
            calculationTimeTypeForm.patchValue(null);
          } else {
            customTimeForm.enable();
            calculationTimeTypeForm.enable();

            if (this.configData.isDemandsPlanningEnabled) {
              byDemandForm.enable();
            }
          }
        });
    }

    customTimeForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe((value) => {
        if (value) {
          this.trackEvent('select time');
        }
      });

    shuttleCompanyIdForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe),
        tap(() => this.trackEvent('select SC')),
        tap(() => this.setVehicleTypes())
      )
      .subscribe();

    costTypeForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(costType => {
        this.trackEvent('select pricing');

        fixedCostForm.patchValue(costType === BuilderFullCostType.FixedPrice ? 0 : null);
      });

    fixedCostForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.trackEvent('set price');
      });

    modeForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(value => {
        this.trackEvent(`side menu - Planning type - ${value === RoutePlannerRouteGenerationMode.New ? 'Optimized routes' : 'Templates'}`);

        routeTemplateIdsForm.patchValue([], { emitEvent: false });
        minimizeVehicleTypeForm.patchValue(false, { emitEvent: false });
        allowMultipleTemplateUsageForm.patchValue(false, { emitEvent: false });

        if (value === RoutePlannerRouteGenerationMode.ByTemplate) {
          routeTemplateIdsForm.setValidators([ Validators.required ]);
        } else {
          routeTemplateIdsForm.clearValidators();
        }

        routeTemplateIdsForm.updateValueAndValidity();
      });

    minimizeVehicleTypeForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(value =>
        this.trackEvent(`side menu - Templates - Minimize vehicle type - ${value ? 'Check' : 'Uncheck'}`)
      );

    allowMultipleTemplateUsageForm
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(value =>
        this.trackEvent(`side menu - Templates - Duplicate template - ${value ? 'Check' : 'Uncheck'}`)
      );

    return filterForm;
  }

  filterDatesRangeChange(data: any): void {
    this.filterDatesRange = {
      ...this.filterDatesRange,
      dates: data.dates,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    };

    const startDate = data.dates[0] || null;
    const endDate = data.dates[data.dates.length - 1] || null;

    this.filter.patchValue({
      startDate,
      endDate,
      activeDays: data.checkDaysActive
    });
  }

  updatePassengerIds(ids: number[]): void {
    this.passengerIds = ids;
  }

  updatePassengersExtraFilters(data): void {
    if (data && data.options && data.options.shifts.length) {
      this.passengersExtraFilters.originalOptions.shifts = data.originalOptions.shifts;
      this.passengersExtraFilters.options.shifts = data.options.shifts;
    } else {
      this.passengersExtraFilters = mergeWith(
        cloneDeep(this.passengersExtraFilters),
        data,
        (objValue, srcValue) => {
          if (isArray(srcValue)) {
            return srcValue;
          }
        }
      );
    }
  }

  async initRoutePlannerHub(guid: string) {
    await this.routePlannerHubService.stop();

    this.routePlannerHubService.init(`?guid=${guid}`);

    await this.routePlannerHubService.start();

    this.routePlannerHubOnCalculated();
    this.routePlannerHubOnRouteStatus();
    this.routePlannerHubOnFinished();
  }

  routePlannerHubOnCalculated(): void {
    const subscription: Subscription = this.routePlannerHubService
      .onCalculated()
      .pipe(
        delay(200),
        filter(data => data.sessionGuid === this.sessionGuid),
        tap(res => {
          if (res.success) {
            this.routePlannerService
              .getSummary()
              .subscribe(
                data => {
                  if (data.ridesWithoutShuttleCount) {
                    this.uPopupService.showMessage({
                      message: this.ridesWithoutShuttleCountText.replace('{{amount}}', `${data.ridesWithoutShuttleCount}`),
                      yes: 'general.confirm'
                    },
                    null);
                  }

                  const routes: BuilderRoute[] = data.routes.map(obj => ({
                    status: null,
                    routeId: obj.routeId,
                    code: obj.number,
                    name: obj.name,
                    direction: obj.direction,
                    days: obj.activeDays,
                    startDate: obj.startDate,
                    endDate: obj.endDate,
                    rideStartDateTime: obj.startTime,
                    rideEndDateTime: obj.endTime,
                    totalPassengers: obj.totalPassengers,
                    carTypeName: obj.carType ? obj.carType.name : null,
                    carTypeCapacity: obj.carType ? obj.carType.seatsCount : null,
                    shuttleCompany: obj.shuttleCompany ? obj.shuttleCompany.name : null,
                    timeType: obj.timeType,
                    allowEmptyStations: obj.allowEmptyStations,
                    locked: false,
                    branchNames: obj.branchNames
                  }));

                  this.builderRoutesStore.routesSet(routes);

                  this.builderFullCommonService.getSkippedPassengers(data.skippedPassengers, data.startDate, data.endDate);

                  this.cd.detectChanges();
                },
                () => {},
                () => {
                  this.builderRoutesStore.setMessagesTable('builder.routes.uGridMessagesTableFull');

                  this.summaryLoading.next(false);
                }
              );
          } else {
            this.builderRoutesStore.setMessagesTable('builder.routes.uGridMessagesTableFull');

            this.summaryLoading.next(false);

            const error = res.errors?.find(obj => this.errorTemplatesByErrorCode?.[obj.code]);

            if (this.errorTemplatesByErrorCode?.[error?.code]) {
              this.showErrorTemplatePopup(error.description, this.errorTemplatesByErrorCode[error.code], res.additionalData);
            }
          }

          this.ngProgress.ref(appConfig.progressBarId).complete();
        })
      )
      .subscribe();

    this.subscriptions.add(subscription);
  }

  private showErrorTemplatePopup(message: string, templateRef: TemplateRef<any>, templates: RoutePlannerErrorTemplate[]) {
    this.uPopupService.showErrorMessage({
      message,
      template: {
        ref: templateRef,
        context: {
          templates
        }
      }
    });
  }

  routePlannerHubOnRouteStatus(): void {
    const subscription: Subscription = this.routePlannerHubService
      .onRouteStatus()
      .pipe(
        filter(data => data.sessionGuid === this.sessionGuid),
        tap(data => {
          this.builderRoutesStore.updateRouteStatus(data.routeId, data.status);

          if (data.status === BuilderRouteStatus.Deleted) {
            this.builderRoutesStore.removeRoute(data.routeId);
          }

          if (data.status === BuilderRouteStatus.Saved || data.status === BuilderRouteStatus.Failed) {
            this.saveProgress += this.saveProgressInc;

            if (this.saveProgressStarted && this.saveProgress < 90) {
              this.ngProgress.ref(appConfig.progressBarId).set(this.saveProgress);
            }

            const errorMessage = data?.errors?.[0];

            if (errorMessage) {
              this.builderRoutesStore.updateRouteErrorMessage(data.routeId, errorMessage.description);
            }
          }
        })
      )
      .subscribe();

    this.subscriptions.add(subscription);
  }

  routePlannerHubOnFinished(): void {
    const subscription: Subscription = this.routePlannerHubService
      .onFinished()
      .pipe(
        filter(data => data.sessionGuid === this.sessionGuid),
        tap(data => {
          this.saveProgressStarted = false;
          this.ngProgress.ref(appConfig.progressBarId).complete();

          if (data.success) {
            this.builderFullCommonService.showMessage('success', 'builder.full.routes.messages.saved');
          } else {
            this.builderFullCommonService.showMessage('error', 'builder.full.routes.messages.failed');
          }
        }),
        delay(2000),
        tap(data => {
          this.updateSavingRoutes(false);

          this.builderFullCommonService.removeMessage();

          if (data.success) {
            this.routesFacade.dailyItemsReset();
            this.builderRoutesStore.setRoutesFullChanged(false);

            this.router.navigate([ NavigationPaths.Rides ]);
          }
        })
      )
      .subscribe();

    this.subscriptions.add(subscription);
  }

  calculateRoutes(): void {
    if (this.calculationAllowed) {
      this.builderRoutesStore.setMessagesTable('builder.routes.uGridMessagesTableFullCalculation');
      this.builderFullCommonService.updateVisible({
        main: false,
        filter: false,
        map: false,
        passengers: false,
        routes: true
      });

      this.builderFullCommonService.updateSteps([
        {
          value: BuilderFullStep.SelectPassengers,
          active: false,
          clickable: true
        },
        {
          value: BuilderFullStep.PlanningDetails,
          active: false,
          clickable: true
        },
        {
          value: BuilderFullStep.RoutesResults,
          active: true,
          disabled: false,
          clickable: false
        }
      ]);

      this.builderRoutesStore.routesSet([]);
      this.builderRoutesStore.routeActiveSet(null);
      this.builderRoutesPassengersInfoStore.reset();

      const value = this.form.getRawValue();
      const filters = this.headerSearchFiltersService.getFiltersValueByUrl('BuilderFull');
      const body = {
        ...omit({
          ...value.filter,
          directions:
            value.filter.direction === RoutePlannerDirectionType.BackwardAndForward ?
              [ RoutePlannerDirectionType.Forward, RoutePlannerDirectionType.Backward ] : [ value.filter.direction ],
          customTime: value.filter.byDemand ? null : value.filter.customTime,
          calculationTimeType: value.filter.byDemand ? null : value.filter.calculationTimeType || null
        }, [ 'direction', 'byDemand', 'costType' ]),
        routePolicy: this.authDataSnapshotService.policiesAndSettingsManagement() ? value.routePolicy : null,
        cityCombinationRules: this.authDataSnapshotService.policiesAndSettingsManagement() ? this.citiesCombinationsByBranchService.getRules() : [],
        passengerIds: this.passengerIds,
        vehicleTypeIds: this.vehicleTypes.value.length ? this.vehicleTypes.value : null,
        shiftIds: filters.shifts,
        useWeeklySchedule: value.filter.routesFor === BuilderFullRoutesFor.All || value.filter.routesFor === BuilderFullRoutesFor.Schools || !this.isAfterSchoolActivitiesEnabled,
        useDemands: value.filter.routesFor === BuilderFullRoutesFor.All || value.filter.routesFor === BuilderFullRoutesFor.Activities,
        ...(
          value.filter.byDemand ? {
            demandBranchIds: filters.branches,
            demandCities: filters.cities
          } : {
            branchIds: filters.branches,
            passengerCities: filters.cities
          }
        )
      };

      const progress = builderFullConfig.calculationRoutes.progressbar.value;
      let progressInc = builderFullConfig.calculationRoutes.progressbar.increaseStep;
      const progressTimeInterval = builderFullConfig.calculationRoutes.progressbar.timeInterval;

      if (body.passengerIds.length >= 50) {
        progressInc = 1;
      }

      this.routePlannerService
        .start(body)
        .pipe(
          first(),
          switchMap(() => this.calculateRoutesProgressBar(progress, progressInc, progressTimeInterval))
        )
        .subscribe();
    }
  }

  calculateRoutesProgressBar(progress: number, progressInc: number, progressTimeInterval: number): Observable<any> {
    let progressStore = progress;

    this.summaryLoading.next(true);

    return this.ngProgress.ref(appConfig.progressBarId).state
      .pipe(
        filter(data => !data.active && data.value === 0),
        first(),
        delay(200),
        switchMap(() => {
          if (this.summaryLoading.value) {
            this.ngProgress.ref(appConfig.progressBarId).set(progressStore);

            return timer(0, progressTimeInterval)
              .pipe(
                takeWhile(() => this.ngProgress.ref(appConfig.progressBarId).isStarted && progressStore < 90),
                tap(() => {
                  progressStore += progressInc;

                  this.ngProgress.ref(appConfig.progressBarId).set(progressStore);
                })
              );
          }

          return EMPTY;
        })
      );
  }

  finishAndCreate(): void {
    this.builderFullCommonService.updateVisible({
      main: false
    });

    this.builderRoutesStore.updateNotSavedRoutesAsPending();
    this.builderRoutesStore.clearRoutesErrorMessage();
    this.builderFullCommonService.showMessage('success', 'builder.full.routes.messages.saving');

    this.saveProgress = 0;
    this.saveProgressInc = 100 / this.builderRoutesStore.unsavedRoutes().length;

    this.updateSavingRoutes(true);

    this.routePlannerService
      .save()
      .pipe(
        first(),
        catchError(err => {
          this.updateSavingRoutes(false);

          return throwError(err);
        }),
        switchMap(() =>
          this.ngProgress.ref(appConfig.progressBarId).state
            .pipe(
              filter(data => !data.active && data.value === 0),
              first(),
              delay(200),
              filter(() => this.saveProgress < 90),
              tap(() => {
                this.ngProgress.ref(appConfig.progressBarId).set(this.saveProgress);
                this.saveProgressStarted = true;
              })
            )
        )
      )
      .subscribe();
  }

  initAlgorithmHotkeys(): void {
    of(...builderFullConfig.algorithmHotkeys)
      .pipe(
        map((data: KeyCode[]) => shortcut(data)),
        mergeAll(),
        sequence(),
        filter(() => this.visibleComponents.routes && !this.blockedAlgorithmHotkeys && !this.ngProgress.ref(appConfig.progressBarId).isStarted),
        switchMap(event =>
          this.routePlannerService.algorithmSession()
            .pipe(
              tap(() => this.setBlockedAlgorithmHotkeys(true)),
              map(() => {
                this.succeedAlgorithmPopup();
                return of(event);
              }),
              catchError(() => {
                this.failedAlgorithmPopup();
                return of(event);
              })
            )
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  succeedAlgorithmPopup(): void {
    this.uPopupService.showMessage(
      {
        message: 'builder.full.algorithmPopup.succeed',
        yes: 'general.confirm',
        copyInput: {
          value: this.sessionGuid
        }
      },
      () => this.setBlockedAlgorithmHotkeys(false),
      null,
      () => this.setBlockedAlgorithmHotkeys(false)
    );
  }

  failedAlgorithmPopup(): void {
    this.uPopupService.showMessage(
      {
        message: 'builder.full.algorithmPopup.failed',
        yes: 'general.confirm'
      },
      () => this.setBlockedAlgorithmHotkeys(false),
      null,
      () => this.setBlockedAlgorithmHotkeys(false)
    );
  }

  setBlockedAlgorithmHotkeys(value: boolean): void {
    this.blockedAlgorithmHotkeys = value;
  }

  updateSavingRoutes(value: boolean) {
    this.savingRoutes.next(value);
  }
}
