import { AddEditModalConfig, AddEditModalTab } from '@app/shared/models';
import { MonitoringSettings } from '@app/monitoring/models';
import { ShuttleCompanyContractBase } from './shuttle-company-contracts.models';

export enum ShuttleCompanyRideLocationType {
  StartLocation = 0,
  EndLocation = 1
}

export enum ShuttleCompanyRideEditPermission {
  Time = 'Time',
  Cancel = 'Cancel',
  Restore = 'Restore',
  ChangePath = 'ChangePath'
}

export enum ShuttleCompaniesImportContractRoundMethod {
  Inactive = '0',
  Active = '1'
}

export interface ShuttleCompany {
  name: string;
  phone: string;
  address: string;
  comments: string;
  isSelfShuttleCompany: boolean;
  shuttleCompanyId: number;
  lockDateTime?: string;
  lockedByUser?: string;
  locked?: boolean;
  sapCode?: string;
}

export interface ShuttleCompanyRow extends ShuttleCompany {
  id: number;
}

export interface ShuttleCompanyStateChanged {
  lockDateTime: string;
  lockedByUser: string;
  shuttleCompanyId: number;
  locked: boolean;
  contractsLocked: boolean;
}

export interface ShuttleCompanyAddEdit {
  shuttleCompanyId: number;
  details: ShuttleCompanyDetails;
  contacts: ShuttleCompanyContact[];
  contracts: ShuttleCompanyContractBase[];
  integrations: ShuttleCompanyIntegrations;
  monitoring: MonitoringSettings;
}

export interface ShuttleCompanyDetails {
  name: string;
  address: {
    fullAddress: string;
    latitude: number;
    longitude: number;
  };
  businessIdentity: string;
  businessType: number;
  comments?: string;
  rideEditingPermissions: ShuttleCompanyRideEditPermission[];
  existingInCurrentCustomer: boolean;
  isSelfShuttleCompany: boolean;
  shuttleCompanyId: number;
  sapCode?: string;
}

export interface ShuttleCompanyIntegrationsYitRouteByCity {
  hash: string;
  carTypeName: string;
  yitCode: string;
  cities: string;
}

class ShuttleCompanyContact {
  groupId: number = null;
  role: string = '';
  name: string = '';
  contacts: ShuttleCompanyContactItem[] = [];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class ShuttleCompanyContactItem {
  type: number = null;
  contact: string = '';
}

export class ShuttleCompanyIntegrations {
  yit: ShuttleCompanyIntegrationsYit;
}

export class ShuttleCompanyIntegrationsYit {
  isActive: boolean;
  autoAssign: boolean;
  autoSendToYIT: boolean;
  yitToken: string;
  yitAccount: string;
  routesByCities: ShuttleCompanyIntegrationsYitRouteByCity[];
}

export class ShuttleCompaniesCustomerDataItem {
  id: number;
  name: string;
}

export class ShuttleCompaniesCustomerData {
  carTypes: {
    id: number;
    name: string;
    capacity: number;
  }[];
  monitoringCarStatuses: ShuttleCompaniesCustomerDataItem[];
  monitoringVendors: ShuttleCompaniesCustomerDataItem[];
  fuelTypes: ShuttleCompaniesCustomerDataItem[];
  ownershipTypes: ShuttleCompaniesCustomerDataItem[];
  vehicleStatuses: ShuttleCompaniesCustomerDataItem[];
}

export interface ShuttleCompaniesAddEditComponentConfig {
  dictionary: { [key: string]: string; };
  selfSCTabs?: AddEditModalTab[];
  addEditModalConfig: AddEditModalConfig;
}

export interface ShuttleCompaniesImportContract {
  contractId: string;
  contractName: string;
  shuttleCompanyId: number;
  shuttleCompanyName: string;
}

export interface ShuttleCompaniesImportContractInfo {
  success: boolean;
  contractName: string;
  contractNumber: string;
  shuttleCompanyName: string;
  errors: string[];
}
