import { computed, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { VisibleComponent, VisibleComponents } from '@app/shared/models';
import { CommonStorageService } from './common-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private readonly commonStorageService = inject(CommonStorageService);

  readonly #previousUrl: WritableSignal<string> = signal('/');
  readonly #previousFullUrl: WritableSignal<string> = signal(null);
  readonly #showReOptimizationOnLoad: WritableSignal<boolean> = signal(false);
  readonly #visibleComponents: WritableSignal<VisibleComponents> = signal({
    [VisibleComponent.Feed]: false,
    [VisibleComponent.Notes]: false,
    [VisibleComponent.RouteNotes]: false,
    [VisibleComponent.Activities]: false,
    [VisibleComponent.MonitoringDashboard]: false,
    [VisibleComponent.ImportContract]: false,
    [VisibleComponent.PassengersImport]: false,
    [VisibleComponent.MovePassengers]: false,
    [VisibleComponent.ReOptimization]: false,
    ...this.commonStorageService.getVisibleComponents()
  });

  readonly previousUrl = this.#previousUrl.asReadonly();
  readonly previousFullUrl = this.#previousFullUrl.asReadonly();
  readonly showReOptimizationOnLoad = this.#showReOptimizationOnLoad.asReadonly();
  readonly visibleComponents = this.#visibleComponents.asReadonly();
  readonly showNotes = computed(() => this.visibleComponents[VisibleComponent.Notes]);
  readonly showActivities = computed(() => this.visibleComponents()[VisibleComponent.Activities]);
  readonly showRouteNotes = computed(() => this.visibleComponents()[VisibleComponent.RouteNotes]);
  readonly showFeed = computed(() => this.visibleComponents()[VisibleComponent.Feed]);
  readonly showMonitoringDashboard = computed(() => this.visibleComponents()[VisibleComponent.MonitoringDashboard]);
  readonly showImportContract = computed(() => this.visibleComponents()[VisibleComponent.ImportContract]);
  readonly showPassengersImport = computed(() => this.visibleComponents()[VisibleComponent.PassengersImport]);
  readonly showMovePassengers = computed(() => this.visibleComponents()[VisibleComponent.MovePassengers]);
  readonly showReOptimization = computed(() => this.visibleComponents()[VisibleComponent.ReOptimization]);

  readonly visibleComponents$ = toObservable(this.visibleComponents);
  readonly showRouteNotes$ = toObservable(this.showRouteNotes);
  readonly showReOptimization$ = toObservable(this.showReOptimization);

  updatePreviousUrl(data: string) {
    this.#previousUrl.set(data);
  }

  updatePreviousFullUrl(value: string) {
    this.#previousFullUrl.set(value);
  }

  updateShowReOptimizationOnLoad(value: boolean) {
    this.#showReOptimizationOnLoad.set(value);
  }

  updateVisibleComponent(component: VisibleComponent, value: boolean, hideAll: boolean = false) {
    const visibleComponents = {
      ...this.#visibleComponents(),
      [component]: value,
      ...(value || hideAll ?
        Object.keys(this.#visibleComponents()).reduce((acc, key) => ({
          ...acc,
          [key]: key === component ? value : false
        }), {}) : {}
      ) as VisibleComponents
    };

    this.#visibleComponents.set(visibleComponents);

    this.commonStorageService.updateVisibleComponents(visibleComponents);
  }
}
