<div #demandsTable class="demands-passengers-view-table">
  <app-table-page
    *ngIf="!isLoading"
    class="table-page_demands-passengers-view"
    [config]="config.table"
    (refresh)="getPassengers()"
    (excelExport)="exportToExcel()"
    (addRow)="addEditRow()"
    (closeIconClicked)="onCloseIconClicked()"
    (selectRows)="rowsSelected($event)"
    (visibleColumns)="columnsSelect($event)"
    (scrollTable)="onScrollTable($event)"
  ></app-table-page>
</div>

<div 
  *ngIf="demandsCommonService.backdrop$ | async" 
  class="demands-passengers-view-backdrop"
  (click)="demandsCommonService.clickOnBackdrop()"
></div>

<ng-template
  #dayCellHeader
  ngx-datatable-header-template
  let-column="column"
>
  <span
    class="demands-passengers-view-table-day-column-header"
  >
    <span class="demands-passengers-view-table-day-column-header__day">{{(config.dictionary.weekDays + '.' + [column.dayOfWeek]) | translate}}</span>
    <span class="demands-passengers-view-table-day-column-header__date">{{column.date}}</span>
  </span>
</ng-template>

<ng-template
  ngx-datatable-cell-template
  #demandsNameCell
  let-row="row"
  let-value="value"
>
  <div class="demands-passengers-view-table-name-cell">
    <span *ngIf="!row.bulkAssignShift" class="demands-passengers-view-table-name-cell__text">{{value}}</span>
    <button
      *ngIf="!isBulkAssignOpen && !row.bulkAssignShift"
      uButton
      type="button"
      class="demands-passengers-view-table-name-cell__edit u-grid-button"
      (click)="$event.stopPropagation(); addEditRow(row.id);"
    >
      {{(authDataSnapshotService.managePassengers() ? 'general.edit' : 'general.view') | translate}}
    </button>
    <span *ngIf="row.bulkAssignShift" class="demands-passengers-view-table-name-cell__text demands-passengers-view-table-name-cell__text_bulk-assign">{{value | translate}}</span>
    <u-three-dots-popover
      *ngIf="isBulkAssignOpen ? row.bulkAssignShift : !row.bulkAssignShift"
      [class]="'u-three-dots-popover_ngx-datatable demands-passengers-view-table-name-cell__three-dots'"
      [items]="config.threeDotsItems"
      [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
      [container]="'body'"
      [visibleClass]="true"
      [autoClose]="'outside'"
      (selectDotsItemAction)="selectBulkAssignDotsItemAction($event, row)"
    ></u-three-dots-popover>
  </div>
</ng-template>

<ng-template 
  #dayCell 
  ngx-datatable-cell-template
  let-value="value"
  let-column="column"
  let-row="row"
>
  <app-demands-day
    [featureType]="featureType"
    [demands]="value" 
    [date]="column.dateISO"
    [dayOfWeek]="column.dayOfWeek"
    [hasBranches]="hasBranches"
    [passenger]="!row.bulkAssignShift && row"
    [passengers]="row.bulkAssignShift && tablePageService.selectedRows"
    [isBulkAssignOpen]="isBulkAssignOpen"
  ></app-demands-day>
</ng-template>

<ng-template #departmentCell ngx-datatable-cell-template let-value="value">
  <span 
    class="demands-passengers-view-table-cell"
    [uTooltip]="value || ('passengers.general.noDepartment' | translate)"
    [tooltipClass]="'u-tooltip_app-wrap-long-word'"
    [placement]="['bottom', 'top']"
    [container]="'body'"
  >
    {{value || ('passengers.general.noDepartment' | translate)}}
  </span>
</ng-template>

<ng-template #yesNoCell let-value="value">
  <span 
    class="demands-passengers-view-table-cell"
    [uTooltip]="'passengers.general.' + (value ? 'yes' : 'no') | translate"
    [tooltipClass]="'u-tooltip_app-wrap-long-word'"
    [placement]="['bottom', 'top']"
    [container]="'body'"
  > 
    {{'passengers.general.' + (value ? 'yes' : 'no') | translate}}
  </span>
</ng-template>

<ng-template #yesNoCellFilter let-item="item">
  <span
    class="demands-passengers-view-table-cell-filter"
    title="{{'passengers.general.' + (item? 'yes' : 'no') | translate}}"
  >
    {{'passengers.general.' + (item? 'yes' : 'no') | translate}}
  </span>
</ng-template>

<ng-template #statusCell let-value="value">
  <span
    [uTooltip]="passengerStatusType[value]"
    [viewportElement]="uGrid"
    [tooltipClass]="'u-tooltip_app-wrap-long-word'"
    [placement]="['bottom', 'top']"
    [container]="'body'"
    class="demands-passengers-view-table-cell"
  >
    {{passengerStatusType[value]}}
  </span>
</ng-template>

<ng-template #statusCellFilter let-item="item">
  <span class="demands-passengers-view-table-cell-filter">{{passengerStatusType[item]}}</span>
</ng-template>

<ng-template #commentCell ngx-datatable-cell-template let-value="value">
  <span 
    class="demands-passengers-view-table-cell"
    [uTooltip]="value"
    [tooltipClass]="'u-tooltip_app-wrap-long-word'"
    [placement]="[ 'bottom', 'top' ]"
    [container]="'body'"
  >
    {{value}}
  </span>
</ng-template>

<ng-template #stationCell ngx-datatable-cell-template let-value="value">
  <span 
    class="demands-passengers-view-table-cell"
    [uTooltip]="value ? value : 'passengers.general.without' | translate"
    [tooltipClass]="'u-tooltip_app-wrap-long-word'"
    [placement]="['bottom', 'top']"
    [container]="'body'"
  >
    {{value ? value : 'passengers.general.without' | translate}}
  </span>
</ng-template>

<ng-template #tagsCell ngx-datatable-cell-template let-value="value">
  <div class="demands-passengers-view-table-cell-tags">
    <u-tags-view
      [tags]="value"
      [margin]="8"
      [isRtl]="isRtl"
    ></u-tags-view>
  </div>
</ng-template>

<ng-template #genderCell ngx-datatable-cell-template let-value="value">
  <span class="demands-passengers-view-table-cell">{{config.dictionary.genders[value] | translate}}</span>
</ng-template>

<ng-template #genderCellFilter let-item="item">
  <span class="demands-passengers-view-table-cell-filter" title="{{config.dictionary.genders[item] | translate}}">{{config.dictionary.genders[item] | translate}}</span>
</ng-template>

<ng-template #distanceToStationCell ngx-datatable-cell-template let-value="value">
  <span *ngIf="value >= 0" class="demands-passengers-view-table-cell-distance-to-station">
    {{value | number: appConstants.NUMBER_FORMAT}} {{'general.units.meter' | translate}}
  </span>
  <span *ngIf="value === passengerDistanceToStation.NoStation" class="demands-passengers-view-table-cell-distance-to-station">
    {{config.dictionary.noStation | translate}}
  </span>
</ng-template>

<ng-template #distanceToStationCellFilter let-item="item" ngx-datatable-header-filter-template>
  <span *ngIf="item === passengerDistanceToStation.NoValue" class="demands-passengers-view-table-cell-filter">
    {{config.dictionary.noValue | translate}}
  </span>
  <span *ngIf="item === passengerDistanceToStation.NoStation" class="demands-passengers-view-table-cell-filter">
    {{config.dictionary.noStation | translate}}
  </span>
  <span *ngIf="item.between && item.to" class="demands-passengers-view-table-cell-filter">
    {{config.dictionary.between | translate}} {{item.between}} - {{item.to}} {{'general.units.meter' | translate}}
  </span>
  <span *ngIf="item.between && !item.to" class="demands-passengers-view-table-cell-filter">
    {{config.dictionary.above | translate}} {{item.between}} {{'general.units.meter' | translate}}
  </span>
  <span *ngIf="!item.between && item.to" class="demands-passengers-view-table-cell-filter">
    {{config.dictionary.under | translate}}{{item.to}} {{'general.units.meter' | translate}}
  </span>
</ng-template>
