import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { exhaustMap, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BuilderService } from '@app/builder/services';
import { BuilderPassengersState } from '@app/builder/models';

@Injectable()
export class BuilderPassengersStore extends ComponentStore<BuilderPassengersState> {
  constructor(
    private readonly builderService: BuilderService
  ) {
    super({ mobiles: {} });
  }

  readonly mobileByPassengerId = (passengerId: number) => this.selectSignal(({ mobiles }) => mobiles[passengerId]);
  readonly mobileByPassengerId$ = (passengerId: number) => this.select(({ mobiles }) => mobiles[passengerId]);

  readonly getMobileByPassengerId = this.effect((params$: Observable<{
    passengerId: number;
  }>) => params$.pipe(
    filter(({ passengerId }) => this.mobileByPassengerId(passengerId)() === undefined),
    exhaustMap(({ passengerId }) =>
      this.builderService.getPassengerMobile(passengerId)
        .pipe(
          tapResponse(
            mobile => this.updateMobile({ passengerId, mobile }),
            () => null
          )
        )
    )
  ));

  readonly updateMobile = this.updater((state, data: { passengerId: number; mobile: string; }) => ({
    mobiles: {
      ...state.mobiles,
      [data.passengerId]: data.mobile || null
    }
  }));

  readonly reset = this.updater(() => ({ mobiles: {} }));
}
