import { USidebarMenuHeader } from '@shift/ulib';

import {
  AuthCustomerType,
  AuthModuleAccompanyFeature,
  AuthModuleAccompanyFeatureEntityManager,
  AuthModuleBIFeature,
  AuthModuleBIFeatureType,
  AuthModuleBranchesFeature,
  AuthModuleBranchesFeatureEntityManager,
  AuthModuleBranchesFeatureType,
  AuthModuleContractsFeature,
  AuthModuleContractsFeatureType,
  AuthModuleCustomerSettingsFeature,
  AuthModuleCustomerSettingsFeatureType,
  AuthModuleCustomerStationsFeature,
  AuthModuleCustomerStationsFeatureEntityManager,
  AuthModuleEducationInstitutionsFeature,
  AuthModuleEducationInstitutionsFeatureEntityManager,
  AuthModuleName,
  AuthModulePassengersFeature,
  AuthModulePassengersFeatureEntityManager,
  AuthModuleRideOrdersFeature,
  AuthModuleDemandsPassengersViewFeature,
  AuthModuleDemandsPassengersViewFeatureEntityManager,
  AuthModuleDemandsPassengersViewFeatureType,
  AuthModuleShuttleCompanyCustomersFeature,
  AuthModuleShuttleCompanyCustomersFeatureEntityManager,
  AuthModuleSupervisorsFeature,
  AuthModuleSupervisorsFeatureEntityManager,
  AuthModuleSupervisorsFeatureType,
  AuthOptionalModule,
  AuthModuleFeatureGroupCondition,
  AuthModuleDemandsShiftsViewFeature,
  AuthModuleDemandsShiftsViewFeatureEntityManager,
  AuthModuleDemandsShiftsViewFeatureType,
  AuthModuleTransportationFeature,
  AuthModuleTransportationFeatureExecutorType
} from '@app/auth/models';
import { EnvType, Languages, NavigationPaths, SidebarMenuHeaderItemValue, SidebarTopMenuItem } from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.sidebarMenu';

const menuHeader: USidebarMenuHeader = {
  dictionary: {
    language: `${DICTIONARY_PATH}.header.language`
  },
  languages: [
    {
      name: 'En',
      code: Languages.English,
      isActive: false
    },
    {
      name: 'עב',
      code: Languages.Hebrew,
      isActive: false
    }
  ],
  logoPath: null,
  items: [
    {
      value: SidebarMenuHeaderItemValue.SwitchCustomer,
      name: `${DICTIONARY_PATH}.header.switchCustomer`
    },
    {
      value: SidebarMenuHeaderItemValue.AccessibilityAffidavit,
      name: `${DICTIONARY_PATH}.header.accessibilityAffidavit`
    },
    {
      value: SidebarMenuHeaderItemValue.Logout,
      name: `${DICTIONARY_PATH}.header.logout`
    }
  ]
};

const generalMenuItems = {
  rides: {
    link: NavigationPaths.Rides,
    name: `${DICTIONARY_PATH}.menu.routes`,
    icon: '/assets/images/icons/side-menu/rides.svg',
    activeIcon: '/assets/images/icons/side-menu/rides-active.svg',
    feature: {
      module: AuthModuleName.RoutesTable
    }
  },
  builderFull: {
    link: NavigationPaths.BuilderFull,
    name: `${DICTIONARY_PATH}.menu.routePlanner`,
    icon: '/assets/images/icons/side-menu/route-planner.svg',
    activeIcon: '/assets/images/icons/side-menu/route-planner-active.svg',
    feature: {
      module: AuthModuleName.RoutePlanner
    }
  },
  routeTemplates: {
    link: NavigationPaths.RouteTemplates,
    name: `${DICTIONARY_PATH}.menu.routeTemplates`,
    icon: '/assets/images/icons/side-menu/route-templates.svg',
    activeIcon: '/assets/images/icons/side-menu/route-templates-active.svg',
    feature: {
      module: AuthModuleName.RouteTemplates
    }
  },
  database: {
    name: `${DICTIONARY_PATH}.menu.database.title`,
    id: 'database',
    icon: '/assets/images/icons/side-menu/database.svg',
    activeIcon: '/assets/images/icons/side-menu/database-active.svg'
  },
  drivers: {
    link: NavigationPaths.Drivers,
    name: `${DICTIONARY_PATH}.menu.database.drivers`
  },
  shuttleCompaniesCars: {
    link: NavigationPaths.ShuttleCompaniesCars,
    name: `${DICTIONARY_PATH}.menu.database.vehicles`
  },
  shuttleCompaniesCustomers: {
    link: NavigationPaths.ShuttleCompaniesCustomers,
    name: `${DICTIONARY_PATH}.menu.database.customers`
  },
  shuttleCompanies: {
    link: NavigationPaths.ShuttleCompanies,
    name: `${DICTIONARY_PATH}.menu.database.shuttleCompanies`
  },
  reports: {
    link: NavigationPaths.Reports,
    name: `${DICTIONARY_PATH}.menu.reports`,
    icon: '/assets/images/icons/side-menu/reports.svg',
    activeIcon: '/assets/images/icons/side-menu/reports-active.svg'
  },
  contracts: {
    link: NavigationPaths.Contracts,
    name: `${DICTIONARY_PATH}.menu.contracts`,
    icon: '/assets/images/icons/side-menu/contracts.svg',
    activeIcon: '/assets/images/icons/side-menu/contracts-active.svg',
    feature: {
      module: AuthModuleName.Contracts,
      name: AuthModuleContractsFeature.Type,
      values: [ AuthModuleContractsFeatureType.Generic ]
    }
  },
  bi: {
    link: NavigationPaths.BI,
    name: `${DICTIONARY_PATH}.menu.insights`,
    icon: '/assets/images/icons/side-menu/data-analysis.svg',
    activeIcon: '/assets/images/icons/side-menu/data-analysis-active.svg',
    optionalModule: AuthOptionalModule.BI
  },
  settings: {
    link: NavigationPaths.Settings,
    name: `${DICTIONARY_PATH}.menu.settingsAndPolicy`,
    icon: '/assets/images/icons/side-menu/settings.svg',
    activeIcon: '/assets/images/icons/side-menu/settings-active.svg'
  }
};

const menuItems: { [key: string]: SidebarTopMenuItem[]; } = {
  default: [
    generalMenuItems.rides,
    {
      link: NavigationPaths.RideOrders,
      name: `${DICTIONARY_PATH}.menu.rideOrders`,
      icon: '/assets/images/icons/side-menu/ride-orders.svg',
      activeIcon: '/assets/images/icons/side-menu/ride-orders-active.svg',
      feature: {
        module: AuthModuleName.RideOrders,
        name: AuthModuleRideOrdersFeature.Type
      }
    },
    generalMenuItems.builderFull,
    {
      link: NavigationPaths.RouteSuggestions,
      name: `${DICTIONARY_PATH}.menu.routeSuggestions`,
      icon: '/assets/images/icons/side-menu/route-suggestions.svg',
      activeIcon: '/assets/images/icons/side-menu/route-suggestions-active.svg',
      feature: {
        module: AuthModuleName.RouteSuggestions
      }
    },
    generalMenuItems.routeTemplates,
    {
      ...generalMenuItems.database,
      children: [
        {
          link: NavigationPaths.Passengers,
          name: `${DICTIONARY_PATH}.menu.database.passengers`,
          feature: {
            module: AuthModuleName.Passengers,
            name: AuthModulePassengersFeature.EntityManager,
            values: [ AuthModulePassengersFeatureEntityManager.EntityManagerFeature ]
          }
        },
        {
          link: NavigationPaths.Accompanies,
          name: `${DICTIONARY_PATH}.menu.database.accompanies`,
          feature: {
            module: AuthModuleName.Accompany,
            name: AuthModuleAccompanyFeature.EntityManager,
            values: [ AuthModuleAccompanyFeatureEntityManager.EntityManagerFeature ]
          }
        },
        {
          link: NavigationPaths.RideSupervisors,
          name: `${DICTIONARY_PATH}.menu.database.rideSupervisors`,
          optionalModule: AuthOptionalModule.Supervisor,
          features: [
            {
              module: AuthModuleName.Supervisors,
              name: AuthModuleSupervisorsFeature.EntityManager,
              values: [ AuthModuleSupervisorsFeatureEntityManager.EntityManagerFeature ]
            },
            {
              module: AuthModuleName.Supervisors,
              name: AuthModuleSupervisorsFeature.Type,
              values: [ AuthModuleSupervisorsFeatureType.Generic ]
            }
          ]
        },
        {
          link: NavigationPaths.RideCommanders,
          name: `${DICTIONARY_PATH}.menu.database.rideCommanders`,
          optionalModule: AuthOptionalModule.Supervisor,
          features: [
            {
              module: AuthModuleName.Supervisors,
              name: AuthModuleSupervisorsFeature.EntityManager,
              values: [ AuthModuleSupervisorsFeatureEntityManager.EntityManagerFeature ]
            },
            {
              module: AuthModuleName.Supervisors,
              name: AuthModuleSupervisorsFeature.Type,
              values: [ AuthModuleSupervisorsFeatureType.Commander ]
            }
          ]
        },
        {
          link: NavigationPaths.Schools,
          name: `${DICTIONARY_PATH}.menu.database.educationInstitutions`,
          feature: {
            module: AuthModuleName.EducationInstitutions,
            name: AuthModuleEducationInstitutionsFeature.EntityManager,
            values: [ AuthModuleEducationInstitutionsFeatureEntityManager.EntityManagerFeature ]
          }
        },
        {
          link: NavigationPaths.ActivityCenters,
          name: `${DICTIONARY_PATH}.menu.activityCenters`,
          optionalModule: AuthOptionalModule.AfterSchoolActivities,
          features: [
            {
              module: AuthModuleName.Branches,
              name: AuthModuleBranchesFeature.EntityManager,
              values: [ AuthModuleBranchesFeatureEntityManager.EntityManagerFeature ]
            },
            {
              module: AuthModuleName.Branches,
              name: AuthModuleBranchesFeature.Type,
              values: [ AuthModuleBranchesFeatureType.ActivityCenter ]
            }
          ]
        },
        {
          link: NavigationPaths.Stations,
          name: `${DICTIONARY_PATH}.menu.stations`,
          feature: {
            module: AuthModuleName.CustomerStations,
            name: AuthModuleCustomerStationsFeature.EntityManager,
            values: [ AuthModuleCustomerStationsFeatureEntityManager.EntityManagerFeature ]
          }
        },
        {
          link: NavigationPaths.Branches,
          name: `${DICTIONARY_PATH}.menu.bases`,
          features: [
            {
              module: AuthModuleName.Branches,
              name: AuthModuleBranchesFeature.EntityManager,
              values: [ AuthModuleBranchesFeatureEntityManager.EntityManagerFeature ]
            },
            {
              module: AuthModuleName.Branches,
              name: AuthModuleBranchesFeature.Type,
              values: [ AuthModuleBranchesFeatureType.Base ]
            }
          ]
        },
        generalMenuItems.shuttleCompanies,
        {
          ...generalMenuItems.drivers,
          checkBySelfSCId: true
        },
        {
          ...generalMenuItems.shuttleCompaniesCars,
          checkBySelfSCId: true
        },
        {
          link: NavigationPaths.Branches,
          name: `${DICTIONARY_PATH}.menu.branches`,
          features: [
            {
              module: AuthModuleName.Branches,
              name: AuthModuleBranchesFeature.EntityManager,
              values: [ AuthModuleBranchesFeatureEntityManager.EntityManagerFeature ]
            },
            {
              module: AuthModuleName.Branches,
              name: AuthModuleBranchesFeature.Type,
              values: [ AuthModuleBranchesFeatureType.Generic, AuthModuleBranchesFeatureType.Iec ]
            }
          ]
        },
        {
          ...generalMenuItems.shuttleCompaniesCustomers,
          feature: {
            module: AuthModuleName.ShuttleCompanyCustomers,
            name: AuthModuleShuttleCompanyCustomersFeature.EntityManager,
            values: [ AuthModuleShuttleCompanyCustomersFeatureEntityManager.SelfShuttleCompany ]
          }
        }
      ]
    },
    {
      link: NavigationPaths.Demands,
      name: `${DICTIONARY_PATH}.menu.demands`,
      icon: '/assets/images/icons/side-menu/demands.svg',
      activeIcon: '/assets/images/icons/side-menu/demands-active.svg',
      featureGroup: {
        condition: AuthModuleFeatureGroupCondition.Or,
        values: [
          [
            {
              module: AuthModuleName.DemandsPassengersView,
              name: AuthModuleDemandsPassengersViewFeature.EntityManager,
              values: [ AuthModuleDemandsPassengersViewFeatureEntityManager.EntityManagerFeature ]
            },
            {
              module: AuthModuleName.DemandsPassengersView,
              name: AuthModuleDemandsPassengersViewFeature.Type,
              values: [ AuthModuleDemandsPassengersViewFeatureType.Generic, AuthModuleDemandsPassengersViewFeatureType.Soldier ]
            }
          ],
          [
            {
              module: AuthModuleName.DemandsShiftsView,
              name: AuthModuleDemandsShiftsViewFeature.EntityManager,
              values: [ AuthModuleDemandsShiftsViewFeatureEntityManager.EntityManagerFeature ]
            },
            {
              module: AuthModuleName.DemandsShiftsView,
              name: AuthModuleDemandsShiftsViewFeature.Type,
              values: [ AuthModuleDemandsShiftsViewFeatureType.Generic, AuthModuleDemandsShiftsViewFeatureType.Soldier ]
            }
          ]
        ]
      }
    },
    {
      link: NavigationPaths.ActivitySchedule,
      name: `${DICTIONARY_PATH}.menu.activitySchedule`,
      icon: '/assets/images/icons/side-menu/demands.svg',
      activeIcon: '/assets/images/icons/side-menu/demands-active.svg',
      optionalModule: AuthOptionalModule.AfterSchoolActivities,
      featureGroup: {
        condition: AuthModuleFeatureGroupCondition.Or,
        values: [
          [
            {
              module: AuthModuleName.DemandsPassengersView,
              name: AuthModuleDemandsPassengersViewFeature.EntityManager,
              values: [ AuthModuleDemandsPassengersViewFeatureEntityManager.EntityManagerFeature ]
            },
            {
              module: AuthModuleName.DemandsPassengersView,
              name: AuthModuleDemandsPassengersViewFeature.Type,
              values: [ AuthModuleDemandsPassengersViewFeatureType.Student ]
            }
          ],
          [
            {
              module: AuthModuleName.DemandsShiftsView,
              name: AuthModuleDemandsShiftsViewFeature.EntityManager,
              values: [ AuthModuleDemandsShiftsViewFeatureEntityManager.EntityManagerFeature ]
            },
            {
              module: AuthModuleName.DemandsShiftsView,
              name: AuthModuleDemandsShiftsViewFeature.Type,
              values: [ AuthModuleDemandsShiftsViewFeatureType.Student ]
            }
          ]
        ]
      }
    },
    {
      ...generalMenuItems.reports,
      feature: {
        module: AuthModuleName.Reports
      }
    },
    {
      ...generalMenuItems.bi,
      feature: {
        module: AuthModuleName.BI,
        name: AuthModuleBIFeature.Type,
        values: [ AuthModuleBIFeatureType.Traffical ]
      }
    },
    generalMenuItems.contracts,
    {
      ...generalMenuItems.bi,
      feature: {
        module: AuthModuleName.BI,
        name: AuthModuleBIFeature.Type,
        values: [ AuthModuleBIFeatureType.Generic, AuthModuleBIFeatureType.Army, AuthModuleBIFeatureType.ArmyMaster ]
      }
    },
    {
      ...generalMenuItems.settings,
      feature: {
        module: AuthModuleName.CustomerSettings
      }
    }
  ],
  [AuthCustomerType.ShuttleCompany]: [
    generalMenuItems.rides,
    generalMenuItems.builderFull,
    generalMenuItems.routeTemplates,
    {
      ...generalMenuItems.database,
      children: [
        generalMenuItems.drivers,
        generalMenuItems.shuttleCompaniesCars,
        {
          ...generalMenuItems.shuttleCompanies,
          feature: {
            module: AuthModuleName.Transportation,
            name: AuthModuleTransportationFeature.ExecutorType,
            values: [ AuthModuleTransportationFeatureExecutorType.ShuttleCompany ]
          }
        },
        {
          ...generalMenuItems.shuttleCompanies,
          name: `${DICTIONARY_PATH}.menu.database.subContractors`,
          feature: {
            module: AuthModuleName.Transportation,
            name: AuthModuleTransportationFeature.ExecutorType,
            values: [ AuthModuleTransportationFeatureExecutorType.SubContractor ]
          }
        },
        {
          ...generalMenuItems.shuttleCompaniesCustomers,
          feature: {
            module: AuthModuleName.ShuttleCompanyCustomers,
            name: AuthModuleShuttleCompanyCustomersFeature.EntityManager,
            values: [ AuthModuleShuttleCompanyCustomersFeatureEntityManager.Generic ]
          }
        }
      ]
    },
    generalMenuItems.reports,
    generalMenuItems.contracts,
    {
      ...generalMenuItems.bi,
      feature: {
        module: AuthModuleName.BI,
        name: AuthModuleBIFeature.Type,
        values: [ AuthModuleBIFeatureType.ShuttleCompany ]
      }
    },
    {
      ...generalMenuItems.settings,
      feature: {
        module: AuthModuleName.CustomerSettings,
        name: AuthModuleCustomerSettingsFeature.Type,
        values: [ AuthModuleCustomerSettingsFeatureType.ShuttleCompany ]
      }
    }
  ]
};

const menuDefault = {
  items: menuItems.default,
  header: menuHeader,
  customNavigate: true,
  bottomItems: [
    {
      id: 'screen-share',
      name: `${DICTIONARY_PATH}.bottomItems.screenShare`,
      icon: '/assets/images/icons/side-menu/screen-share.svg',
      activeIcon: '/assets/images/icons/side-menu/screen-share-active.svg'
    },
    {
      id: 'chat-button',
      name: `${DICTIONARY_PATH}.bottomItems.chat`,
      icon: '/assets/images/icons/side-menu/chat.svg',
      activeIcon: '/assets/images/icons/side-menu/chat-active.svg'
    }
  ]
};

const menuHeaderLogosByEnvTypes = {
  default: '/assets/images/logo/shift.svg',
  [EnvType.IDF]: '/assets/images/themes/idf/logo/idf.png',
  [EnvType.Traffical]: '/assets/images/themes/traffical/logo/traffical.svg',
  [EnvType.IEC]: '/assets/images/themes/iec/logo/iec.svg',
  [EnvType.Police]: '/assets/images/themes/police/logo/police.svg'
};

export const sidebarMenuComponentConfig = {
  trackingId: 'Side Menu',
  trackingMessagesByMenuItemId: {
    'screen-share': 'Click on Remote Control icon',
    'chat-button': 'Click on Chat icon'
  },
  menuHeaderLogos: {
    default: menuHeaderLogosByEnvTypes,
    [AuthCustomerType.ShuttleCompany]: {
      ...menuHeaderLogosByEnvTypes,
      [EnvType.Shift]: '/assets/images/themes/shift-shuttle-company/logo/shift-shuttle-company.svg'
    }
  },
  menu: {
    default: menuDefault,
    [AuthCustomerType.ShuttleCompany]: {
      ...menuDefault,
      items: menuItems[AuthCustomerType.ShuttleCompany]
    }
  }
};
