export enum RouteTempCommentApplicableTo {
  Driver = 1,
  Accompany = 2,
  BusinessRideSupervisor = 3,
  ArmyRideCommander = 4
}

export interface GetTempCommentApplicableToParams {
  routeId: number;
  startDate: string;
  endDate: string;
}

export interface AddNewTempCommentBody {
  routeId: number;
  startDate: string;
  endDate: string;
  commentText: string;
  applicableTo: RouteTempCommentApplicableTo[];
}
