import { UInputCitiesCity } from '@shift/ulib';

import {
  DaysOfWeek,
  MovePassengersRoute,
  MovePassengersRouteBase,
  MovePassengersRouteMapViewStationType,
  MovePassengersRouteMapViewWaypoint,
  RoutePolicy,
  CityCombinations,
  CityCombinationsRule
} from '@app/shared/models';
import { BuilderTimeType } from '@app/builder/models';
import { RouteDirection } from '@app/routes/models';

export enum RoutePlannerDirectionType {
  Forward,
  Backward,
  BackwardAndForward = 3
}

export enum RoutePlannerRouteGenerationMode {
  New,
  ByTemplate
}

export interface RoutePlannerSkippedPassenger {
  passengerId: number;
  name: string;
  phone: string;
  passengerStation: string;
  targetStation: string;
  daysOfWeek: number[];
  date: string;
  direction: RoutePlannerDirectionType;
  skipReason: string;
}

export interface RoutePlannerShuttleCompany {
  id: number;
  name: string;
  priceByCityEnabled: boolean;
  priceByKmEnabled: boolean;
}

export interface RoutePlannerShift {
  id: number;
  name: string;
  branchId: number;
}

export interface RoutePlannerBranch {
  id: number;
  name: string;
  isMain: boolean;
}

export interface RoutePlanner {
  sessionId: string;
  maxDate: string;
  minDate: string;
  cityCombinations: CityCombinations;
  routePolicy: RoutePolicy;
  shuttleCompanies: RoutePlannerShuttleCompany[];
  vehicleTypes: RoutePlannerVehicleType[];
  shifts: RoutePlannerShift[];
  routePlannerPassengersAmountRequestLimit: number;
  branches: RoutePlannerBranch[];
  isDemandsPlanningEnabled: boolean;
  isTemplatesPlanningEnabled: boolean;
}

export interface RoutePlannerVehicleType {
  id: number;
  name: string;
  seatsCount: number;
}

export interface RoutePlannerPassenger {
  name: string;
  mobile: string;
  address: string;
  amount: number;
}

export interface RoutePlannerNewBody {
  shuttleCompanyId: number;
  directions: RoutePlannerDirectionType[];
  customTime: string;
  calculationTimeType: BuilderTimeType;
  startDate: string;
  endDate: string;
  activeDays: DaysOfWeek;
}

export interface RoutePlannerDuplicateBody {
  routeId: number;
  name: string;
  isAutoGeneratedName: boolean;
  direction: RouteDirection;
  time: string;
  timeType: BuilderTimeType;
  startDate: string;
  endDate: string;
}

export interface RoutePlannerLoadMapBody {
  passengerIds: number[];
  routePolicy: RoutePolicy;
}

export interface RoutePlannerLoadMap {
  passengers: {
    latitude: number;
    longitude: number;
    fullAddress: string;
    placeId: string;
    addressId: number;
    cityId: number;
    street: string;
    houseNumber: string;
    id: number;
    name: string;
    targetId: number;
  }[];
  targets: {
    latitude: number;
    longitude: number;
    fullAddress: string;
    placeId: string;
    addressId: number;
    cityId: number;
    street: string;
    houseNumber: string;
    id: number;
    name: string;
  }[];
}

export interface RoutePlannerMovePassengersSourceRouteInitedData {
  id: number;
  number: number;
  name: string;
  direction: number;
}

export interface RoutePlannerMovePassengersBody {
  passengerIds: number[];
  originalRouteId: number;
  routeId: number;
  allowCapacityChange: boolean;
}

export interface RoutePlannerMovePassengersGetParams {
  routeId: number;
}

export class RoutePlannerMovePassengersRouteBase extends MovePassengersRouteBase {}

export class RoutePlannerMovePassengersRoute extends MovePassengersRoute {}

export interface RoutePlannerMovePassengersBase {
  passengers: RoutePlannerMovePassengersItemBase[];
  routes: RoutePlannerMovePassengersRouteBase[];
}

export interface RoutePlannerMovePassengersActionBase {
  updatedRoute: RoutePlannerMovePassengersRouteBase;
  updatedSummaries: RoutePlannerSummaryRoute[];
}

export class RoutePlannerMovePassengersItemBase {
  id: number;
  name: string;
  stationAddress: string;
  targetName: string;
  check: boolean;
  rideId: number;
}

export interface RoutePlannerGetRoutesMapViewRoute {
  routeId: number;
  rideId: number;
}

export interface RoutePlannerGetRoutesMapViewBody {
  routes: RoutePlannerGetRoutesMapViewRoute[];
}

export class RoutePlannerMovePassengersItem extends RoutePlannerMovePassengersItemBase {
  check: boolean = false;

  constructor(values: object) {
    super();
    Object.assign(this, values);
  }
}

export interface RoutePlannerGetRoutesMapViewStation {
  rideStationId: number;
  type: MovePassengersRouteMapViewStationType;
  name: string;
  arrivalTime: string;
  address: string;
  latitude: number;
  longitude: number;
}

export interface RoutePlannerGetRoutesMapView {
  encodedPolylineJson: string;
  distance: number;
  duration: string;
  rideId: number;
  routeId: number;
  stations: RoutePlannerGetRoutesMapViewStation[];
  waypoints: MovePassengersRouteMapViewWaypoint[];
}

export interface RoutePlannerStartBody {
  passengerIds: number[];
  routePolicy: RoutePolicy;
  shuttleCompanyId: number;
  directions: RoutePlannerDirectionType[];
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  shiftIds: number[];
  demandBranchIds?: number[];
  demandCities?: UInputCitiesCity[];
  branchIds?: number[];
  cities?: UInputCitiesCity[];
  activeDays: number[];
  customTime: string;
  calculationTimeType: BuilderTimeType;
  useDemands: boolean;
  useWeeklySchedule: boolean;
  cityCombinationRules: CityCombinationsRule[];
  mode: RoutePlannerRouteGenerationMode;
  vehicleTypeIds: number;
  fixedCost: number;
  routeTemplateIds: number[];
  allowMultipleTemplateUsage: boolean;
  minimizeVehicleType: boolean;
}

export class RoutePlannerSummaryRoute {
  routeId: number;
  rideId: number;
  startDate: string;
  endDate: string;
  activeDays: number[];
  number: number;
  name: string;
  isAutoGeneratedName: boolean;
  direction: number;
  timeType: BuilderTimeType;
  startTime: string;
  endTime: string;
  totalPassengers: number;
  allowEmptyStations: boolean;
  shuttleCompany: {
    value: number;
    name: string;
  };
  carType: {
    value: number;
    name: string;
    seatsCount: number;
    keyword: string;
  };
  branchNames: string[];
}

export interface RoutePlannerSummary {
  startDate: string;
  endDate: string;
  routes: RoutePlannerSummaryRoute[];
  skippedPassengers: RoutePlannerSkippedPassenger[];
  jobIds: string[];
  ridesWithoutShuttleCount: number;
}

export interface RoutePlannerErrorTemplate {
  templateId: number;
  number: string;
  name: string;
}

export interface RoutePlannerError {
  code: string;
  description: string;
}

export interface RoutePlannerCalculated {
  additionalData: RoutePlannerErrorTemplate[];
  sessionGuid: string;
  success: boolean;
  errors: RoutePlannerError[];
}

export interface RoutePlannerRouteStatus {
  status: number;
  sessionGuid: string;
  routeId: number;
  errors: RoutePlannerError[];
}

export interface RoutePlannerFinished {
  sessionGuid: string;
  success: boolean;
  errors: RoutePlannerError[];
}
