import { from } from 'rxjs';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { map, tap } from 'rxjs/operators';
import { pick } from 'lodash';

import { AuthUserInfo } from '@app/auth/models';
import { appConfig } from '@app/shared/configs';
import { authConfig } from '@app/auth/configs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor (private oAuthService: OAuthService) {}

  private clearLocalStorage(exceptions: string[]) {
    const localStorageKeys = Object.keys(localStorage);

    for (const localStorageKey of localStorageKeys) {
      if (!exceptions.includes(localStorageKey)) {
        localStorage.removeItem(localStorageKey);
      }
    }
  }

  clearUserTablesColumnsFiltersLocalStorage() {
    const usersTablesProps = localStorage.getItem(appConfig.storageKeys.usersTablesProps);
    const usersTablesPropsJson = usersTablesProps ? JSON.parse(usersTablesProps) : null;
    const userId = localStorage.getItem('userId');

    if (usersTablesPropsJson && usersTablesPropsJson[userId]) {
      const usersTablesPropsUpdate = {
        ...usersTablesPropsJson
      };

      for (const key in usersTablesPropsUpdate[userId]) {
        if (usersTablesPropsUpdate[userId].hasOwnProperty(key)) {
          usersTablesPropsUpdate[userId][key] = {
            ...usersTablesPropsUpdate[userId][key],
            columnsFiltersStore: []
          };
        }
      }

      localStorage.setItem(appConfig.storageKeys.usersTablesProps, JSON.stringify(usersTablesPropsUpdate));
    }
  }

  clearStorages() {
    this.clearLocalStorage([ appConfig.storageKeys.usersTablesProps ]);

    sessionStorage.clear();
  }

  setLocalStorageUserInfo(userInfo: AuthUserInfo) {
    localStorage.setItem('userId', userInfo ? JSON.stringify(userInfo.person.memberId) : null);
  }

  switchRole(customerId: number, role: number) {
    this.oAuthService.customQueryParams = {
      customer_id: customerId,
      role,
      grant_type: 'switch_role'
    };

    return from(this.oAuthService.refreshToken());
  }

  loadUserProfile() {
    return from(this.oAuthService.loadUserProfile())
      .pipe(
        map((data: any) => pick(data.info, authConfig.userProfileFields)),
        tap(data => this.setLocalStorageUserInfo(data))
      );
  }
}

