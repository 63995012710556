<div class="routes-change-accompany__container">
  <div class="routes-change-accompany__wrapper">
    <h4 class="routes-change-accompany__title">{{config.dictionary.title | translate}}</h4>
  </div>
  <u-group 
    class="u-group_app-routes-change u-group_app-routes-change-accompany u-group_flex"
    [name]="config.dictionary.accompany | translate"
    [valid]="form.controls['accompanyId'].valid"
    [message]="config.dictionary.required | translate" 
  >
    <u-select-s
      [classUInput]="'u-input_arrow-light u-input_light'"  
      [items]="activeRoute.isFullManualRide ? config.dictionary.accompaniesBaseOptions : accompaniesFullOptions"
      [formControl]="form.controls['accompanyId']"
      [messages]="config.dictionary.uSelectS | translate"
      [empty]="true"
      [emptyValue]="accompanyType.Remove"
      [emptyName]="config.dictionary.removeSelect | translate"
      [emptyRemove]="config.dictionary.removeSelect | translate"
      [placeholder]="config.dictionary.none | translate"
    ></u-select-s>
  </u-group>
  <u-group
    class="u-group_app-routes-change u-group_app-routes-change-accompany u-group_flex"
    [name]="config.dictionary.pickupDropOff | translate"
  >
    <u-select-s
      [classUInput]="'u-input_arrow-light u-input_light'"
      [items]="config.sourceTypeOptions"
      [formControl]="form.controls['sourceType']"
      [messages]="config.dictionary.uSelectS | translate"
      [empty]="false"
      [emptyValue]="accompanyType.Remove"
      [emptyName]="config.dictionary.removeSelect | translate"
      [emptyRemove]="config.dictionary.removeSelect | translate"
      [placeholder]="config.dictionary.none | translate"
    ></u-select-s>
  </u-group>
  <u-group
    class="u-group_app-routes-change u-group_app-routes-change-accompany u-group_flex"
    [name]="config.dictionary.return | translate"
  >
    <u-toggle
      [formControl]="form.controls['returnHome']"
      [messages]="config.dictionary.toggle | translate"
    ></u-toggle>
  </u-group>
  <u-group 
    class="u-group_app-routes-change u-group_app-routes-change-accompany u-group_flex"
    [name]="config.dictionary.type | translate"
  >
    <app-routes-period
      [typeFormControl]="form.controls['type']"
      [changes]="form.controls['datesChange'].value"
      [viewportElement]="viewportElement"
      (updatePeriod)="updatePeriod($event)"
      (updateDates)="updateDates($event)"
    ></app-routes-period>
  </u-group>
  <u-group 
    class="u-group_app-routes-change u-group_app-routes-change-accompany u-group_flex"
    [name]="config.dictionary.comments | translate"
  >
    <textarea
      uTextarea
      [formControl]="form.controls['comment']"
    ></textarea>
  </u-group>
  <div class="routes-change-accompany__buttons">
    <div class="routes-change-accompany__button">
      <button
        uButton
        class="u-font-weight-medium routes-change-accompany__btn"
        [size]="uButtonSize.Small"
        [disabled]="!form.dirty"
        (click)="submit()"
      >
        {{config.dictionary.actions.saveAndClose | translate}}
      </button>
    </div>
    <div class="routes-change-accompany__button">
      <button
        uButton
        class="u-font-weight-medium routes-change-accompany__btn"
        [size]="uButtonSize.Small"
        [disabled]="!form.dirty"
        (click)="submit(routesChangeEmailSendType.SendBackgroundEmail)"
      >
        {{config.dictionary.actions.saveAndSendSC | translate}}
      </button>
    </div>
    <div class="routes-change-accompany__button">
      <button
        uButton
        class="u-font-weight-medium routes-change-accompany__btn"
        [size]="uButtonSize.Small"
        [disabled]="!form.dirty"
        (click)="submit(routesChangeEmailSendType.GenerateEditableEmail)"
      >
        {{config.dictionary.actions.saveAndEditEmail | translate}}
      </button>
    </div>
  </div>
</div>
