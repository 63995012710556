import { inject, Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { USelectSItem } from '@shift/ulib';

import { AccompanyCostType, AccompanyPriceType } from '@app/accompanies/models';
import { AuthDataService } from '@app/auth/services';
import {
  BuilderCustomerData,
  BuilderCustomerDataAccompany,
  BuilderDataStoreOptions
} from '@app/builder/models';
import { PassengerGender } from '@app/passengers/models';
import { RoutesChangeType, RouteDirection } from '@app/routes/models';
import { builderConfig } from '@app/builder/configs';

@Injectable()
export class BuilderDataStoreService {
  private readonly authDataService = inject(AuthDataService);

  private customerDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  customerDataLoaded$: Observable<boolean> = this.customerDataLoaded.asObservable();
  customerData: BuilderCustomerData = cloneDeep(builderConfig.customerData);

  options: BuilderDataStoreOptions = {
    assignedPassengers: [
      { value: 1, name: 'builder.options.assignedPassengers.stations' },
      { value: 2, name: 'builder.options.assignedPassengers.stationsWithPassengers' }
    ],
    assigns: [
      { value: 0, name: 'builder.options.assigns.both' },
      { value: 1, name: 'builder.options.assigns.assign' },
      { value: 2, name: 'builder.options.assigns.unAssigned' }
    ],
    eligibleShuttles: [
      { value: true, name: 'builder.options.eligibleShuttles.yes' },
      { value: false, name: 'builder.options.eligibleShuttles.no' }
    ],
    eligibleAccs: [
      { value: true, name: 'builder.options.eligibleAccs.yes' },
      { value: false, name: 'builder.options.eligibleAccs.no' }
    ],
    genders: [
      { value: PassengerGender.No, name: 'builder.options.genders.all' },
      { value: PassengerGender.Male, name: 'builder.options.genders.male' },
      { value: PassengerGender.Female, name: 'builder.options.genders.female' }
    ],
    directions: [
      { value: RouteDirection.Forward, name: 'builder.options.directions.forward' },
      { value: RouteDirection.Backward, name: 'builder.options.directions.backward' }
    ],
    changeTypes: [
      { value: RoutesChangeType.Planned, name: 'builder.options.changeTypes.planned' }
    ],
    changeTypesEdit: [
      { value: RoutesChangeType.Planned, name: 'builder.options.changeTypes.planned' },
      { value: RoutesChangeType.Unplanned, name: 'builder.options.changeTypes.notPlanned' }
    ],
    changeTypesShuttleCompany: [
      { value: RoutesChangeType.Unplanned, name: 'builder.options.changeTypes.notPlanned' }
    ],
    changeDays: [
      { value: 0, name: 'builder.options.changeDays.allDays' }
    ],
    yit: [
      { value: false, name: 'builder.options.yit.notActive' },
      { value: true, name: 'builder.options.yit.active' }
    ],
    yitTypes: [
      { value: 0, name: 'builder.options.yitTypes.yitCode' },
      { value: 1, name: 'builder.options.yitTypes.yitDepartment' }
    ],
    costAccompany: [
      { value: AccompanyCostType.Fixed, name: 'builder.options.costAccompany.fix' },
      { value: AccompanyCostType.ByManualHour, name: 'builder.options.costAccompany.hour' }
    ],
    costAccompanyFull: [
      { value: AccompanyCostType.Fixed, name: 'builder.options.costAccompanyFull.fix' },
      { value: AccompanyCostType.ByManualHour, name: 'builder.options.costAccompanyFull.hourlyManual' },
      { value: AccompanyCostType.ByAutoHour, name: 'builder.options.costAccompanyFull.hourlyAutomatic' }
    ],
    priceAccompany: [
      { value: AccompanyPriceType.Manual, name: 'builder.options.priceAccompany.manual' },
      { value: AccompanyPriceType.Auto, name: 'builder.options.priceAccompany.auto' }
    ],
    returnAccompany: [
      { value: false, name: 'builder.options.returnAccompany.no' },
      { value: true, name: 'builder.options.returnAccompany.yes' }
    ],
    routeTypes: [],
    schools: [],
    regions: [],
    memberRegions: [],
    departments: [],
    branches: [],
    shifts: [],
    classes: [],
    subCustomers: [],
    subCustomersSC: [],
    shuttleCompanies: [],
    carTypes: [],
    accompanies: [],
    accompaniesWithoutRequired: [],
    budgetTypes: [],
    tags: [],
    orderPurposes: [],
    masterSubCustomers: [],
    supervisors: [],
    purchaseOrders: [],
    purchaseOrderRows: [],
    contracts: []
  };

  departmentsByRegionCodesOptions: { [key: string]: USelectSItem[]; };
  departmentsByMemberRegionCodesOptions: { [key: string]: USelectSItem[]; };
  orderSubPurposesByOrderPurposeIdOptions: { [key: string]: USelectSItem[]; };

  loadCustomerData(data: BuilderCustomerData): void {
    const customerData = cloneDeep(this.customerData);
    const options = cloneDeep(this.options);

    const parseItem = (name: string, item: { id: number | string | boolean, name: string }[]) => {
      customerData[name] = item;
      options[name] = item.map((ob: any) => ({ value: ob.id, name: ob.name }));
    };

    const items: string[] = [
      'schools',
      'departments',
      'branches',
      'shifts',
      'classes',
      'subCustomers',
      'shuttleCompanies',
      'carTypes',
      'tags',
      'orderPurposes',
      'masterSubCustomers',
      'routeTypes',
      'supervisors'
    ];

    items.forEach(el => {
      if (data[el]) {
        parseItem(el, data[el]);
      }
    });

    if (data.accompanies) {
      customerData.accompanies = [ ...customerData.accompanies, ...data.accompanies ]
        .filter(accompany => !accompany.permission || this.authDataService.checkPermission(accompany.permission));
      options.accompanies = customerData.accompanies.map((ob: any) => ({ value: ob.id, name: ob.name, hidden: false }));
      options.accompaniesWithoutRequired = options.accompanies.filter((ob: any) => ob.value !== 'required');
    }

    if (data.budgetTypes) {
      customerData.budgetTypes = data.budgetTypes;
      options.budgetTypes = data.budgetTypes.map((ob: any) => ({ value: ob.id, name: ob.name }));
    }

    if (data.cities) {
      customerData.cities = data.cities;
    }

    if (data.orderPurposes) {
      this.orderSubPurposesByOrderPurposeIdOptions = data.orderPurposes.reduce((acc, purpose) => ({
        ...acc,
        [purpose.id]: purpose.subPurposes.map(subPurpose => ({ value: subPurpose.id, name: subPurpose.name }))
      }), {});
    }

    if (data.subCustomers) {
      customerData.subCustomers = data.subCustomers;
      options.subCustomers = data.subCustomers.map(obj => ({ value: obj.id, name: obj.name }));
      options.subCustomersSC = data.subCustomers.filter(obj => obj.isOwnedBySc).map(obj => ({ value: obj.id, name: obj.name }));
    }

    if (data.regions) {
      customerData.regions = data.regions;
      options.regions = data.regions.map(obj => ({ value: obj.sapCode, name: obj.name }));
      this.departmentsByRegionCodesOptions = data.regions.reduce((acc, obj) =>
        ({
          ...acc,
          [obj.sapCode]: obj.departments.map(department => ({ value: department.id, name: department.name }))
        })
      , {});
    }

    if (data.memberRegions) {
      customerData.memberRegions = data.memberRegions;
      options.memberRegions = data.memberRegions.map(obj => ({ value: obj.sapCode, name: obj.name }));
      this.departmentsByMemberRegionCodesOptions = data.memberRegions.reduce((acc, obj) =>
        ({
          ...acc,
          [obj.sapCode]: obj.departments.map(department => ({ value: department.id, name: department.name }))
        })
      , {});
    }

    this.updateCustomerData(customerData);
    this.updateOptions(options);
    this.updateCustomerDataLoaded(true);
  }

  updateCustomerData(data: any): void {
    Object.assign(this.customerData, data);
  }

  updateOptions(data: any): void {
    Object.assign(this.options, data);
  }

  updateCustomerDataLoaded(value: boolean) {
    this.customerDataLoaded.next(value);
  }

  addAccompany(accompany: BuilderCustomerDataAccompany) {
    this.customerData = {
      ...this.customerData,
      accompanies: [ ...this.customerData.accompanies, accompany ]
    };

    this.options = {
      ...this.options,
      accompanies: [ ...this.options.accompanies, { value: accompany.id, name: accompany.name } ],
      accompaniesWithoutRequired: [ ...this.options.accompaniesWithoutRequired, { value: accompany.id, name: accompany.name } ]
    };
  }
}
