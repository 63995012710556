import { Component, OnInit, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { URangePreset } from '@shift/ulib';

import {
  TrackingService,
  HeaderDataService
} from '@app/shared/services';
import { GeneralCardButtonValue } from '@app/shared/models';
import { RouteShuttleCompaniesService } from '@app/route-shuttle-companies/services';
import { AuthCustomer, AuthCustomerType } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { AppConstants } from '@app/shared/constants';
import {
  RoutesChangeEmailSendType,
  RoutesViewTypeMode,
  RoutesChangeType,
  RoutesChangeRestoreRideParams,
  RoutesPeriodsDefaultData
} from '@app/routes/models';
import { RoutesTableService } from '@app/routes/services';
import { routesRestoreRideComponentConfig } from './routes-restore-ride.component.config';

@Component({
  selector: 'app-routes-restore-ride',
  templateUrl: './routes-restore-ride.component.html',
  styleUrls: [ './routes-restore-ride.component.scss', './routes-restore-ride.component.rtl.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesRestoreRideComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses: string = 'routes-restore-ride';

  private unsubscribe: Subject<void> = new Subject();

  config = cloneDeep(routesRestoreRideComponentConfig);
  form: UntypedFormGroup;
  activeRoute: any;
  viewTypeMode: RoutesViewTypeMode.DailyView | RoutesViewTypeMode.WeeklyView = RoutesViewTypeMode.DailyView;
  canceledRidesDates: string[];
  activeDays: string[];
  isSCCustomer: boolean;
  authCustomer: AuthCustomer;
  routesChangeEmailSendType = RoutesChangeEmailSendType;
  routesPeriodsDefaultData: RoutesPeriodsDefaultData;

  constructor(
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private routeShuttleCompaniesService: RouteShuttleCompaniesService,
    private routesTableService: RoutesTableService,
    private trackingService: TrackingService,
    private headerDataService: HeaderDataService,
    private authDataService: AuthDataService
  ) {}

  ngOnInit() {
    this.initAuthCustomer();
    this.getRouteCanceledDates();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getRouteShuttleCompaniesDates() {
    if (this.isSCCustomer) {
      this.routeShuttleCompaniesService.getRouteShuttleCompaniesDates('id' in this.activeRoute ? this.activeRoute.id : this.activeRoute.routeId)
        .pipe(
          take(1),
          takeUntil(this.unsubscribe)
        )
        .subscribe(data => this.activeDays = data.dates);
    }
  }

  private initAuthCustomer() {
    this.authDataService.customer$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(authCustomer => {
        this.authCustomer = authCustomer;
        this.isSCCustomer = authCustomer.type === AuthCustomerType.ShuttleCompany;

        this.getRouteShuttleCompaniesDates();
      });
  }

  private getRouteCanceledDates() {
    this.routesTableService.getRouteCanceledDates(this.activeRoute.routeId || this.activeRoute.id)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(dates => {
        this.canceledRidesDates = dates.map(date => moment(date).format(AppConstants.DATE_FORMAT_ISO));

        this.setRoutesPeriodsDefaultData();
        this.createForm();

        this.cdRef.markForCheck();
      });
  }

  setRoutesPeriodsDefaultData() {
    const { routeStartDate, routeEndDate, days, endDate, startDate } = this.activeRoute;
    const activeDate = this.headerDataService.getActiveDate();

    const isDisplayedDayCanceled = this.isDisplayedDayCanceled();

    this.routesPeriodsDefaultData = {
      dates: isDisplayedDayCanceled ? [ activeDate ] : null,
      dateFrom: this.viewTypeMode === RoutesViewTypeMode.DailyView ? routeStartDate : startDate,
      dateTo: this.viewTypeMode === RoutesViewTypeMode.DailyView ? routeEndDate : endDate,
      type: isDisplayedDayCanceled ? (this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay) : URangePreset.Manual,
      availablePresets: this.config.availablePresets,
      checkDaysActive: isDisplayedDayCanceled ? [ moment(activeDate).get('day') ] : [],
      checkDaysAvailable: days,
      comment: ''
    };
  }

  private createForm() {
    this.form = this.fb.group({
      routeId: [ this.activeRoute.routeId || this.activeRoute.id ],
      periods: [ [] ],
      type: [ RoutesChangeType.Unplanned, Validators.required ]
    });
  }

  private isDisplayedDayCanceled(): boolean {
    if (!this.canceledRidesDates) { return false; }

    const activeDate = moment(this.headerDataService.getActiveDate());

    for (let i = 0; i < this.canceledRidesDates.length; i++) {
      if (moment(this.canceledRidesDates[i]).isSame(activeDate, 'day')) {
        return true;
      }
    }

    return false;
  }

  saveChanges(emailSendType?: RoutesChangeEmailSendType) {
    const { routeId, type, periods } = this.form.value;
    const activeDate = moment(this.headerDataService.getActiveDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE);
    const body: RoutesChangeRestoreRideParams = {
      routeId,
      activeDate,
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        type,
        periods: periods.map(period => {
          const dates = period.dates;
          const comment = period.comment;
          const days = period.checkDaysActive;
          const dateFrom = moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);
          const dateTo = moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO);

          return { days, dateFrom, dateTo, comment };
        }),
        restoreEmpty: true
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    this.routesTableService.changeRestoreRide(body)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.trackingService.track('[Track daily view change] - route was Restored');

        this.close();
      });
  }

  track(message: string) {
    this.trackingService.track(`[${this.config.trackingId}] - ${message}`);
  }

  close() {
    this.bsModalRef.hide();
  }

  onButtonClick(value: GeneralCardButtonValue) {
    const params = {
      [GeneralCardButtonValue.SaveAndClose]: null,
      [GeneralCardButtonValue.SaveAndEditEmail]: RoutesChangeEmailSendType.GenerateEditableEmail,
      [GeneralCardButtonValue.SaveAndSendSC]: RoutesChangeEmailSendType.SendBackgroundEmail
    };

    this.saveChanges(params[value]);
  }
}
